import { useState, useRef } from "react";
import { get, set } from 'lodash';
import createBindFunction, { createSetFunction, Debounce, Draft } from "./createBindFunction";

export function useRefManager() {
    const ref = useRef();

    if (!ref.current) ref.current = {};
    ref.current.ref = (name) => {
        ref.current[name] = ref.current[name] || {};
        return ref.current[name];
    }

    return ref.current;
}


function useBindableState(initialState = {}, options = {}) {
    let [state, setState] = useState(initialState || {});
    const ref = useRef();
    state.set = createSetFunction({ onSet: options.onSet, data: state, setState });

    state.$ = ref.current || {};
    state.$.ref = (name) => {
        ref.current = ref.current ?? {};
        ref.current[name] = ref.current[name] ?? {};
        ref.current[name].current = ref.current[name].current ?? null;
        return ref.current[name];
    }

    state.update = async (data) => {
        const d = await state.get();
        await state.set({ ...d, ...data });
        return await state.get();
    }

    state.bind = createBindFunction(state, {
        data: state,
        getData: () => {
            return new Promise((resolve) => {
                setState(s => {
                    resolve(s);
                    return s;
                });
            });
        },
        update: state.set
    });

    state.get = (pathName, defaultValue = null) => {
        return new Promise((resolve) => {
            setState(s => {
                if (pathName) {
                    resolve(get(s, pathName, defaultValue));
                } else {
                    resolve(s);
                }
                return s;
            });
        });
    };
    if (options.initState) return options.initState(state);
    return state;
}

export { Debounce, Draft };

export default useBindableState;

/*
const state = useBindableState(null);
<Debounce {...state.bind('', { emptyValue: {} })} wait={2000} onUpdate={() => console.log("updated")}>
    {data => (
        <div>
            <InputGroupe {...data.bind('infos.user', { emptyValue: {} })} />
            <pre>{JSON.stringify(state, null, 2)}</pre>
        </div>
    )}
</Debounce>
<Draft {...state.bind('', { emptyValue: {} })} onUpdate={() => console.log("updated")}>
    {data => (
        <div>
            <InputGroupe {...data.bind('infos.user', { emptyValue: {} })} />
            <button onClick={data.accept}>Save</button>
        </div>
    )}
</Draft>
*/