import React from 'react'
import { Paper } from '@mui/material';
import Atom, { defineAtomElement } from '../Atom';

function PaperElm({ item }) {
    return (
        <Paper style={{ padding: 15 }}>
            <Atom {...item.bind('list')} editMode={item.editMode} />
        </Paper>
    );
}

defineAtomElement({
    name: 'Paper',
    title: 'Paper',
    Component: PaperElm
});


export default PaperElm
