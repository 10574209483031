import DefaultLayout from "./Layout/Layout";
import AtomPages from './AtomPages';
import Loading from "../Loading";
import useConfig from "../utils/useConfig";
import React from "react";

const Atomapp = () => {
    const config = useConfig({ name: 'config', version: 'config' });
    if (config.loading) return <Loading loading fullScreen />;
    return (
        <DefaultLayout config={config} >
            <AtomPages name={config.page?.flow} />
        </DefaultLayout>
    );
};


export default Atomapp;
