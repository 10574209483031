import React from 'react';
import Atom, { defineAtomElement } from './../Atom';

function AtomCom({ item, config2 }) {
    return <Atom value={config2.list || item.list} />
}

export function AtomComConfig({ item }) {
    return <Atom {...item.bind('list', { emptyValue: [] })} editMode />
}

export function AtomComConfig2({ item, config2 }) {
    return <Atom {...config2.bind('list', { emptyValue: [], initialValue: [...item.list] })} editMode />;
}

defineAtomElement({
    name: 'AtomCom',
    title: 'AtomCom',
    default: {},
    Component: AtomCom,
    Config: AtomComConfig,
    Config2: AtomComConfig2,
});

export default AtomCom;
