import { useCallback, useState } from "react";

const API_ENDPINT = process.env.REACT_APP_API_ENDPINT || '/api';
const LIST_DEF_API = {};
function api(method, path, data = undefined) {
    const options = {};
    options.method = `${method || 'get'}`.trim().toUpperCase();
    options.headers = new Headers();
    if (data !== undefined) {
        if (`${method}`.toLowerCase() === 'get') {
            path = getPathWithQueryParams(path, data);
        } else {
            options.body = data instanceof FormData ? data : JSON.stringify(data);
        }
    }
    if(data instanceof FormData) {

    } else {
        options.headers.append('Content-Type', 'application/json');
    }
    const token = localStorage.getItem('token');
    if (token) {
        options.headers.append('Authorization', 'Bearer ' + token);
    }
    if (API_ENDPINT.endsWith('/') && path.startsWith('/')) {
        path = path.substr(1);
    } else if (!API_ENDPINT.endsWith('/') && !path.startsWith('/')) {
        path = '/' + path;
    }
    return fetch(API_ENDPINT + path, options)
        .then(responce => responce.json())
        .catch(console.error);
}

function getPathWithQueryParams (path, data = {}){
    let list = Object.entries(data || {});
    if (list.length) {
        list = list.map(([key, value]) => key + '=' + encodeURIComponent(value)).join('&');
        if (!path) path = '/';
        if (!path.match(/\?/)) {
            path += '?' + list;
        } else {
            path += '&' + list;
        }
    }
    return path;
}

api.get = (path, data = {}) => {

    return api('get', path);
};

api.post = (path, data = {}) => {
    return api('post', path, data);
};

api.delete = (path, data = {}) => {
    return api('delete', path, data);
};

function useApi() {
    const [loading, setLoading] = useState(false);
    const rep = {};

    const getResponce = useCallback((data) => {
        setLoading(false);
        if (data && 'headers' in data && Array.isArray(data.headers)) {
            data.headers.forEach(async item => {
                if (LIST_DEF_API[item.name]) {
                    try {
                        await LIST_DEF_API[item.name].call(null, ...item.data);
                    } catch (error) {
                        console.error(error);
                    }
                }
            });
        }
        if (data && 'body' in data) return data.body;
        return data;
    }, []);

    const sendRequest = useCallback((method, path, data = undefined) => {
        setLoading(true);
        return api(method, path, data).then(getResponce);
    }, []);

    rep.get = useCallback((path, data) => sendRequest('get', path, data), []);
    rep.post = useCallback((path, data) => sendRequest('post', path, data), []);
    rep.delete = useCallback((path, data) => sendRequest('delete', path, data), []);

    rep.define = (name, callback) => {
        LIST_DEF_API[name] = callback;
    }

    return { ...rep, loading };
}

export { api };
export default useApi;