const firebaseConfig = {
  apiKey: "AIzaSyDjyfF5N1O8RoByOQiEbP7Npb8nJFQ0PjY",
  authDomain: "wassim-dev.firebaseapp.com",
  databaseURL: "https://wassim-dev.firebaseio.com",
  projectId: "wassim-dev",
  storageBucket: "wassim-dev.appspot.com",
  messagingSenderId: "1013805999234",
  appId: "1:1013805999234:web:da2166922e6b2d0dad409b",
  measurementId: "G-0DN3Q8VK1P"
};

export default firebaseConfig;
