import Input from "com/Imput/Input";
import { Debounce } from "com/utils/useBindableState";
import { Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useCrud, { CRUDLoadAllData, TableCRUDDialog, TableCRUDeleteDialog, TableCRUDFilter, TableCRUDLoading, TableCRUDTable, TableCRUPrintDialog } from "com/utils/useCrud";
import { defineAtomElement } from "com/Atom/Atom";

export function SAAS() {
  const { list, selected, loading, filter, loadData, nbrPages, createNewItem, total, page, checked } = useCrud('saas', { length: 10, id: 'id' });

  const colmuns = [
    { key: 'hostname', title: 'Hostname' },
    {
      key: 'active', title: 'Active',
      titleArgs: { style: { width: 1 } },
      args: { style: { padding: 2 } },
      render: (row) => <Switch {...row.bind('active', { switch: true, onChange: row.save, true: 'On', false: 'Off', emptyValue: 'Off' })} />
    }
  ];

  const actions = [
    { icon: 'print' },
    { icon: 'edit', label: 'Edit' },
    { icon: 'delete', label: 'Delete' },
    {
      icon: 'more_vert',
      menu: [
        { icon: 'edit', label: 'Edit' },
        { icon: 'delete', label: 'Delete' },
      ]
    },
  ];

  return (
    <TableCRUDLoading loading={loading}>
      <TableCRUDFilter onSearch={() => loadData()} onAdd={() => createNewItem()}>
        <Debounce {...filter.bind('', { emptyValue: {} })} wait={500} onUpdate={() => loadData()} >
          {(filterDraft) => (
            <>
              <Input.Text label="HostName" {...filterDraft.bind('hostname')} />
            </>
          )}
        </Debounce>
      </TableCRUDFilter>
      <TableCRUDTable
        rows={list}
        colmuns={colmuns}
        actions={actions}
        checked={checked}
        loadData={loadData}
        page={page}
        total={total}
        nbrPages={nbrPages}
        hideOptions
        checkedActions={
          <button className="btn btn-sm btn-outline-primary" type="button">
            Créer facture
          </button>
        }
      />
      <TableCRUDDialog item={selected.edit} header="Modification" actions={(item) => [
        { label: 'Annuler', onClick: () => { item.reset(); item.close('edit'); } },
        {
          label: 'Enregistrer', variant: 'contained', onClick: () => {
            item.accept();
            selected.edit.save().then(ok => ok ? selected.edit.close() : null);
          }
        }
      ]}>
        {item => (
          <div className='row' style={{ minWidth: 700 }}>
            <div className='col-6'>
              <Input.Text label="Subdomain" {...item.bind('subdomain')} />
            </div>
            <div className='col-6'>
              <Input.Select2 label="Domain" {...item.bind('domain')} options={[{ value: 'app.sim-software.tn', label: 'app.sim-software.tn' }, { value: 'app.sim-cloud.org', label: 'app.sim-cloud.org' }]} />
            </div>
          </div>
        )}
      </TableCRUDDialog>
      <TableCRUDeleteDialog item={selected.delete} />
      <TableCRUPrintDialog item={!!selected.print} fullWidth onClose={() => selected.print.close('print')}>
        {item => (
          <CRUDLoadAllData loadData={loadData} open>
            {rows => (
              <div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Host</TableCell>
                        <TableCell align="right">DB Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {row.hostname}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </CRUDLoadAllData>
        )}
      </TableCRUPrintDialog>
    </TableCRUDLoading >
  );
}


defineAtomElement({
  name: 'SAAS',
  title: 'SAAS',
  Component: SAAS
});

