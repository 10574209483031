import { defineAtomElement } from 'com/Atom/Atom';
import Input from 'com/Imput/Input';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

function Calendar({ item }) {
    return (
        <FullCalendar
            plugins={[dayGridPlugin]}
            locale="fr"
            firstDay={1}
            events={[
                {
                    daysOfWeek: [0],
                    display: "background",
                    backgroundColor: "#dfdfdf",
                    overLap: true,
                    allDay: true
                },
                {
                    title: "Conference",
                    start: "2022-12-08",
                    end: "2022-12-10"
                },
            ]}
            initialView="dayGridMonth"
        />
    )
}

function CalendarConfig({ item }) {
    return (
        <div className='row'>
            <div className='col-12'>
                <Input.Text lable="Title" {...item.bind('title', { emptyValue: '' })} />
            </div>
        </div>
    )
}

defineAtomElement({
    name: 'Calendar',
    title: 'Calendar',
    default: {},
    Component: Calendar,
    Config: CalendarConfig
});

export default Calendar;
