import React, { useState } from 'react';

const TextEditor = ({ ...props }) => {
    const [rows, setRows] = useState(1);
    const onChange = (e) => {
        const text = e.target.value;
        if (typeof text === 'string') {
            const r = text.split('\n');
            if (r.length !== rows) {
                setRows(r.length);
            }
        } else if (rows !== 1) {
            setRows(1);
        }
        if (props.onChange && typeof props.onChange === 'function') {
            props.onChange(e);
        }
    }
    return (
        <div className='w-TextEditor-container'>
            <textarea className='w-TextEditor bg-input' rows={rows} {...props} onChange={onChange} />
        </div>
    );
}

export default TextEditor;
