import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';


function InputBS({ value, onChange, type, placeholder, emptyOption, readOnly, children, rows, options = [], loadOptions, autoComplete, onKeyUp, valueCol = 'value', labelCol = 'label', ...props }) {
    const id = "datalistOptions-id" + Math.random().toString(16).slice(2);
    let InputField = (
        <input type={type || "text"} value={value || ''} readOnly={!!readOnly} list={Array.isArray(autoComplete) ? id : null} onChange={onChange} onKeyUp={onKeyUp} className={"form-control " + (props.validation ? (props.validation.valid ? 'is-valid' : 'is-invalid') : null)} placeholder={placeholder} />
    );

    switch (`${type}`.trim().toLowerCase()) {
        case 'select2':
            if (loadOptions) {
                const encode = (elm) => {
                    if (elm) {
                        elm = JSON.parse(JSON.stringify(elm));
                        const _value = elm[valueCol || 'value'];
                        const _label = elm[labelCol || 'label'];
                        delete elm[valueCol || 'value'];
                        delete elm[labelCol || 'label'];
                        elm.value = _value;
                        elm.label = _label;
                        if (elm.value) {
                            return elm;
                        }
                    }
                    return emptyOption ? { value: '', label: emptyOption } : elm;
                }
                const decode = (elm) => {
                    if (elm) {
                        elm = JSON.parse(JSON.stringify(elm));
                        const _value = elm.value;
                        const _label = elm.label;
                        delete elm.value;
                        delete elm.label;
                        elm[valueCol || 'value'] = _value;
                        elm[labelCol || 'label'] = _label;
                    }
                    return elm;
                }

                const _checkEmptyOption = (list) => {
                    if (Array.isArray(list)) {
                        if (emptyOption) {
                            list.unshift({ value: '', label: emptyOption });
                        }
                    }
                    return list;
                }

                const _loadOptions = async (q) => {
                    try {
                        const data = await loadOptions(q);
                        if (Array.isArray(data)) {
                            return _checkEmptyOption(data.map(encode));
                        }
                    } catch (e) { console.error(e) };
                    return _checkEmptyOption([]);
                }

                InputField = (
                    <AsyncSelect
                        isClearable={emptyOption || typeof emptyOption === 'string'}
                        placeholder={emptyOption}
                        className={"form-select2 " + (props.validation ? (props.validation.valid ? 'is-valid' : 'is-invalid') : null)}
                        defaultOptions
                        cacheOptions
                        value={encode(value)}
                        loadOptions={_loadOptions}
                        onChange={(item, { action }) => ['select-option', 'clear'].includes(action) ? onChange(decode(item) || {}, 'SELECT') : null}
                        {...props}
                    />
                );
            } else {
                options = options.filter(elm => elm).map(elm => (typeof elm !== 'object' ? { value: elm, label: elm } : elm));
                const optionsList = (options || []).filter(item => ![undefined, null].includes(item)).filter(item => (!emptyOption && emptyOption !== '') || (typeof item === 'object' ? item.value : item) !== '');
                InputField = (
                    <Select
                        isClearable={emptyOption || typeof emptyOption === 'string'}
                        placeholder={emptyOption}
                        className={"form-select2 " + (props.validation ? (props.validation.valid ? 'is-valid' : 'is-invalid') : null)}
                        options={optionsList}
                        defaultValue={optionsList.find(elm => elm.value === (value || '')) || null}
                        onChange={(item, { action }) => ['select-option', 'clear'].includes(action) ? onChange((item && item.value) || '', 'SELECT') : null}
                        {...props}
                    />
                );
            }
            break;
        case 'select':
            InputField = (
                <select className={"form-select " + (props.validation ? (props.validation.valid ? 'is-valid' : 'is-invalid') : null)} value={value || ''} onChange={onChange}>
                    {(emptyOption || typeof emptyOption === 'string') ? <option value="">{emptyOption}</option> : null}
                    {children}
                    {(options || []).filter(item => ![undefined, null].includes(item)).filter(item => (!emptyOption && emptyOption !== '') || (typeof item === 'object' ? item.value : item) !== '').map((item, i) => {
                        switch (typeof item) {
                            case 'object':
                                return <option key={'op' + i} value={item.value}>{item.label}</option>
                            default:
                                return <option key={'op' + i}>{item}</option>
                        }
                    })}
                </select>
            );
            break;
        case 'textarea':
            InputField = (
                <textarea value={value || ''} onChange={onChange} onKeyUp={onKeyUp} className={"form-control " + (props.validation ? (props.validation.valid ? 'is-valid' : 'is-invalid') : null)} placeholder={placeholder} rows={rows || 3} />
            );
            break;
        case 'color':
            InputField = (
                <div style={{ display: 'flex', gap: 10 }}>
                    <input type={"text"} value={value || ''} onKeyUp={onKeyUp} list={Array.isArray(autoComplete) ? id : null} onChange={onChange} className={"form-control " + (props.validation ? (props.validation.valid ? 'is-valid' : 'is-invalid') : null)} placeholder={placeholder} />
                    <input type={"color"} style={{ height: 38 }} value={value || ''} list={Array.isArray(autoComplete) ? id : null} onChange={onChange} className={"form-control " + (props.validation ? (props.validation.valid ? 'is-valid' : 'is-invalid') : null)} placeholder={placeholder} />
                </div>
            )
            break;
        case 'date':
            InputField = (
                <input type="date" value={(value || '').split('T')[0]} onKeyUp={onKeyUp} list={Array.isArray(autoComplete) ? id : null} onChange={onChange} className={"form-control " + (props.validation ? (props.validation.valid ? 'is-valid' : 'is-invalid') : null)} placeholder={placeholder} />
            )
            break;
        default:
            break;
    }




    return (
        <FormItemBS {...props}>
            {InputField}
            {Array.isArray(autoComplete) ? (
                <datalist id={id}>
                    {autoComplete.map(option => <option key={option} value={option} />)}
                </datalist>
            ) : null}
        </FormItemBS>
    )
}



function FormItemBS({ children, label, prefix, suffix, validation, append, action, style }) {
    const hasFix = !!(prefix || suffix || append || action);
    const InputContent = (
        <>
            {prefix && ['string', 'number'].includes(typeof prefix) ? <span className="input-group-text">{prefix}</span> : prefix}
            {children}
            {suffix && ['string', 'number'].includes(typeof suffix) ? <span className="input-group-text">{suffix}</span> : suffix}
            {append && Array.isArray(append.options) ? (
                <>
                    <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{append.value}</button>
                    <ul className="dropdown-menu dropdown-menu-end">
                        {append.options.map(elm => (<li key={elm}><span onClick={() => append.onChange && append.onChange(elm)} className="dropdown-item" >{elm}</span></li>))}
                    </ul>
                </>
            ) : null}
            {action && typeof action === 'object' ? (<button className="btn btn-outline-secondary btn-select2-action" type="button" {...action}>{action.label}</button>) : null}
            {validation && validation.message ? <div className={validation.valid ? "valid-feedback" : "invalid-feedback"}>{validation.message}</div> : null}
        </>
    )
    return (
        <div style={{ ...style }}>
            {label ? <label className="form-label">{label}</label> : null}
            {hasFix ? <div className={`input-group has-validation ${(validation && (validation.valid === false)) ? 'has-error' : ''}`}>{InputContent}</div> : InputContent}
        </div>
    )
}

export default InputBS;
