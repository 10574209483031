import { useRecoilState } from 'recoil';
import { userState } from '../../../states';
import { AtomItem, defineAtomElement } from './../Atom';

function UserInfo() {
    const [user] = useRecoilState(userState);
    return (
        <>
            <pre>{JSON.stringify(user, null, 2)}</pre>
            <AtomItem item={{ name: 'name aa ' }} />
        </>
    )
}


defineAtomElement({
    name: 'UserInfo',
    title: 'UserInfo',
    Component: UserInfo
});

export default UserInfo;
