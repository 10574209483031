import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { AuthRoute } from './com/Auth/Auth';
import { useRecoilState } from 'recoil';
import { userState } from './states';
import 'com/utils/prototype';
import Atomapp from "./com/Atom/AtomApp";
import Welcome from 'com/Auth/Welcome';
import useProfile from 'com/utils/useProfile';

function App() {
  const [user] = useRecoilState(userState);
  useProfile();
  if (!user) {
    return (
      <Switch key="offline">
        <Route path="/auth" component={AuthRoute} />
        <Redirect path="/user" to="/auth/login" />
        <Redirect path="/private" to="/auth/login" />
        <Redirect path="/config" to="/auth/login" />
        <Route path="/" component={Atomapp} />
      </Switch>
    );
  };

  if (!user.username) {
    return (
      <Switch key="welcome">
        <Route path="/welcome" component={Welcome} />
        <Redirect to="/welcome" />
      </Switch>
    );
  };

  return (
    <Switch key="online">
      <Redirect from="/auth" to="/" />
      <Redirect from="/welcome" to="/" />
      <Route path="/" component={Atomapp} />
      <Redirect to="/" />
    </Switch>
  );
}

export default App;
