import Atom, { defineAtomElement } from 'com/Atom/Atom';
import Input from 'com/Imput/Input';
import WTabs from 'com/WTabs/WTabs';
import { useState } from 'react';

function Tabs({ item }) {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!item) item = {};
  if (!Array.isArray(item.list)) item.list = [];

  return (
    <WTabs value={value} onChange={handleChange} tabs={item.list.map((elm, i) => ({
      label: elm.title,
      value: i,
      content: (
        <Atom {...item.$bindTo(elm, 'list')} editMode={item.editMode} />
      )
    }))} />
  )
}

function TabsConfig({ item }) {
  return (
    <div className='row'>
      <div className='col-12'>
        <table className='bordered'>
          <tr>
            <th style={{ width: 1 }}>N°</th>
            <th>Title</th>
          </tr>
          {
            (item.list || []).map((col, i) => {
              return (
                <tr>
                  <th className='nowrap'>{i + 1}</th>
                  <td><Input {...item.$bindTo(col, 'title')} /></td>
                </tr>
              )
            })
          }
        </table>
        <button onClick={() => {
          item.list.push({ title: '', list: [] });
          item.$set();
        }}>add</button>
      </div>
    </div>
  )
}

defineAtomElement({
  name: 'Tabs',
  title: 'Tabs',
  default: {},
  Component: Tabs,
  Config: TabsConfig
});

export default Tabs;
