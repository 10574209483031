import { Link } from 'react-router-dom';
import './LayoutStyle.css';
import { useRecoilState } from 'recoil';
import { userState } from './../../../states';
import { get } from 'lodash';
// import { AtomLine } from '../AtomPages';
import './BazJob.css';
import MobileMenu from '../../Menu/MobileMenu';
import { List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';

function BazJobLayout({ config, children }) {
  const [user] = useRecoilState(userState);
  const menuList = get(config, 'menuList', []) || [];
  const space = get(config, 'space', {}) || {};
  function shadeColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);
    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);
    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;
    var RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));
    return "#" + RR + GG + BB;
  }

  return (
    <div className="layout1 BazJobLayout">
      <style>{`
        body {
          --primary-color-hover: ${shadeColor(space.color || '#476396', 20)};
          --primary-color-outline: ${shadeColor(space.color || '#476396', 20)}30;
          --bs-primary: ${space.color || '#476396'};
        }
        .btn-primary { color: #fff; background-color: ${space.color || '#476396'}; border-color: ${space.color || '#476396'};}
      `}</style>
      <div className="layout1-main">
        <MobileMenu>
          <List
            sx={{ minWidth: 300, maxWidth: 360 }}
            subheader={
              <ListSubheader>
                Nested List Items
              </ListSubheader>
            }
          >
            <ListItemButton>
              <ListItemIcon>
                <span className="material-icons">send</span>
              </ListItemIcon>
              <ListItemText primary="Sent mail" />
            </ListItemButton>
          </List>
        </MobileMenu>
        {children}
      </div>
      <div className="container">
        <div className='footer-menu'>
          <FooterMenuItem to='/' text='Accueil' icon='home' active={window.location.pathname === '/'} />
          <FooterMenuItem to='/boutique' text="Boutique" icon='storefront' active={window.location.pathname.includes('/boutique')} />
          <FooterMenuItem to='/messages' text="Messages" icon='chat' active={window.location.pathname.includes('/messages')} />
          <FooterMenuItem to='/calendrier' text="Calendrier" icon='date_range' active={window.location.pathname.includes('/calendrier')} />
          <FooterMenuItem to='/profile' text="Profile" icon='perm_identity' active={window.location.pathname.includes('/profile')} />
        </div>
      </div>
    </div>
  )
}


function FooterMenuItem({ to, text, icon, active }) {
  return (
    <Link className={`FooterMenuItem ${active ? 'FooterMenuItem-active' : ''}`} to={to}>
      <span className="material-icons">{icon}</span>
      <span>{text}</span>
    </Link>
  )
}



export default BazJobLayout;

