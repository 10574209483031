import { useRef } from 'react';
import { useAtomDoc } from '../../Firebase/Firebase';
import Input, { ConfigInput } from '../../Imput/Input';
import Loading from '../../Loading';
import Modal from '../../Modal/Modal';
import { ATOMLAYOUTS, defineAtomElement } from './../Atom';

function Config({ item }) {
    if (!item.configname) item.configname = 'config';
    const modalRef = useRef();
    const { bind, data, apply, cancel, changed, loading } = useAtomDoc({ name: item.configname, version: item.configname, useApplyToSave: true, cache: true });
    return (
        <Loading loading={loading}>
            <h1>Configuration</h1>
            <ConfigInput.Text label="Titre d'application" description="Le titre d'application" {...bind('app.name', { emptyValue: '' })} />
            <ConfigInput.Select label="Inscription public" description="Inscription public" {...bind('app.signup', { emptyValue: '' })} options={[{ label: 'Autorisé', value: 'on' }, { label: 'Non autorisé', value: 'off' }]} />
            <ConfigInput.List label="Roles" description="La list des roles" {...bind('app.roles', { emptyValue: [] })} viewKey="label">
                {item => (
                    <div className='row' style={{ width: '100%' }}>
                        <div className='col-6'>
                            <Input.Text {...item.bind('name', { emptyValue: '' })} placeholder="name" />
                        </div>
                        <div className='col-6'>
                            <Input.Text {...item.bind('label', { emptyValue: '' })} placeholder="Role" />
                        </div>
                    </div>
                )}
            </ConfigInput.List>

            {[...((data && data.app && data.app.roles) || []), { name: 'public', role: 'Public' }].filter(role => role.name && role.name.match(/^[a-zA-Z]+[a-zA-Z0-9_]*$/i)).map((role, i) => (
                <ConfigInput.List
                    key={role.name}
                    label={`Espaces ${role.label}`}
                    description="La list des espaces du role"
                    {...bind('app.spaces.' + role.name, { emptyValue: [] })}
                    viewItem={elm => `${elm.layout} -> ${elm.path}`}
                >
                    {item => (
                        <div style={{ width: '100%', display: 'flex', gap: 15 }}>
                            <div style={{ flex: 1 }}>
                                <Input.Text {...item.bind('path', { emptyValue: '' })} placeholder="Path" />
                            </div>
                            <div style={{ flex: 1 }}>
                                <Input.Select2 {...item.bind('layout', { emptyValue: '' })} emptyOption="Layout" options={ATOMLAYOUTS.map(item => ({ label: item.title, value: item.name }))}>
                                </Input.Select2>
                            </div>
                            <div>
                                <button className='btn btn-secondary' onClick={() => modalRef.current.show(item)}>Config</button>
                            </div>
                        </div>
                    )}
                </ConfigInput.List>
            ))}



            <Modal ref={modalRef} hideCancel title="Configuration layout" maxWidth={820}>
                {item => {
                    const Layout = ATOMLAYOUTS.find(l => l.name === item.layout);
                    if (Layout) return <Layout.Config item={item} />;
                }}
            </Modal>
            <hr />
            <div style={{ display: 'flex', gap: 15 }}>
                <button disabled={!changed} className='btn btn-primary' onClick={apply}>Appliquer</button>
                <button disabled={!changed} className='btn btn-secondary' onClick={cancel}>Annuler</button>
            </div>
        </Loading>
    )
}

function ConfigConfig({ item }) {
    return (
        <div>
            <Input.Text label="Config name" {...item.$bind('configname')} style={{ width: '100%' }} />
        </div>
    )
}


defineAtomElement({
    name: 'config',
    title: 'Configuration',
    default: {
        configname: 'config'
    },
    Component: Config,
    Config: ConfigConfig
});

export default Config;
