import { useEffect } from 'react';
import useApi from '../../API/api';
import useBindableState, { Debounce, Draft } from '../../utils/useBindableState';
import { TableCRUD } from '../../utils/useCrud';
import { defineAtomElement } from './../Atom';


function ENVInfo() {
    const { get } = useApi();
    const state = useBindableState(null);
    useEffect(() => {
        // get('env').then(state.set);
    }, []);

    return (
        <>
            <TableCRUD/>
        </>
    )
}

function InputGroupe({ value, bind, set }) {

    useEffect(() => {
        set({ test: 123 })
    }, [])

    return (
        <div>
            <input {...bind('name.first_name', { native: true, emptyValue: '' })} />
            <input {...bind('name.last_name', { native: true, emptyValue: '' })} />
            <pre>{JSON.stringify(value, null, 2)}</pre>
        </div>
    )
}


defineAtomElement({
    name: 'ENVInfo',
    title: 'ENVInfo',
    Component: ENVInfo
});

export default ENVInfo;
