import { debounce, get, set } from "lodash";
import React from "react";

class JsTree extends React.Component {
    state = {
        value: [],
    };

    constructor(props) {
        super(props);
        if (this.props.actions) {
            this.props.actions.load = (data) => {
                this.jstree.settings.core.data = data || [];
                this.jstree.refresh();
            };
        }
        this.refreshDebounce = debounce(this.refresh, 1000);
    }

    componentDidUpdate(prevProps) {
        const data = Array.isArray(this.props.value) ? this.props.value : [];
        if (data.length && (!prevProps.value || (Array.isArray(prevProps.value) && !prevProps.value.length))) {
            this.jstree.settings.core.data = data;
            this.setState({ value: data });
            this.jstree.refresh();
        }
    }

    refresh = () => {
        this.jstree.refresh();
    }

    affectData = (event) => {
        let value = this.jstree.get_json("#");
        this.setState({ value });
        if (this.props.onChange && event !== "select_node" && event !== "deselect_all")
            this.props.onChange(value);
    };
    initCurrent = () => {
        if (this.current.data instanceof Array) this.current.data = {};
        this.current.data = this.current.data || {};
        this.current.bind = (name, { emptyValue = null } = {}) => {
            let value = get(this.current.data, name, null);
            if ([undefined, null].includes(value)) {
                value = emptyValue;
            }
            return {
                value,
                onChange: (val) => {
                    let v = null;
                    if (typeof val === 'object' && 'target' in val) {
                        if ('type' in val.target && ['checkbox'].includes(val.target.type.trim().toLowerCase())) {
                            v = val.target.checked;
                        } else {
                            v = val.target.value;
                        }
                    } else {
                        v = val;
                    }
                    set(this.current.data, name, v);
                    this.affectData("bind");
                    this.jstree.redraw(true);
                }
            }
        }
    };
    initDataList = (list) => {
        return (Array.isArray(list) ? list : []).map((item) => {
            if (this.props.initNode) {
                this.props.initNode(item);
                item.children = this.initDataList(item.children);
            }
            return item;
        });
    };
    componentDidMount() {
        this.$el = window.jQuery(this.el);
        this.$el
            .jstree({
                plugins: this.props.plugins || [
                    "dnd",
                    "contextmenu",
                    "wholerow",
                    "types",
                ],
                core: {
                    check_callback: this.props.checkCallBack || true,
                    data: this.initDataList(this.props.value || []),
                },
                contextmenu: this.props.contextMenu || true,
            })
            .on("changed.jstree", (e, data) => {
                this.affectData("changed:" + data.action);
            })
            .on("rename_node.jstree", (e, data) => {
                this.affectData("rename_node");
            })
            .on("move_node.jstree", (e, data) => {
                this.affectData("move_node");
            })
            .on("delete_node.jstree", (e, data) => {
                this.current = null;
                this.affectData("delete_node");
            })
            .on("create_node.jstree", (e, data) => {
                this.affectData("create_node");
            })
            .on("select_node.jstree", (e, data) => {
                this.current = data.node;
                this.initCurrent();
                this.affectData("select_node");
            });
        this.jstree = window.jQuery.jstree.reference(this.$el);
        if (this.props.model) {
            this.props.model.jstree = this.jstree;
        }
    }

    addNew = (e) => {
        var value = prompt("Texte", "");
        if (value != null) {
            if (this.props.onAdd) {
                this.props.onAdd(this.jstree, value);
            } else {
                this.jstree.create_node("#", { text: value }, "last");
            }
        }
    };
    delete = (e) => {
        if (!this.current) return;
        if (
            !window.confirm(
                "Voulez vous vraiment supprimer '" + this.current.text + "'?"
            )
        )
            return;
        this.jstree.delete_node(this.current);
    };
    render() {
        return (
            <div style={{ display: "flex" }}>
                <div
                    style={{
                        minWidth: 200,
                        width: this.props.width,
                        background: "#f5f5f5",
                        padding: 5,
                        minHeight: 250,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div style={{ flex: 1 }}>
                        <div ref={(el) => (this.el = el)} />
                    </div>
                    {this.props.buttons ? (
                        <div
                            style={{
                                borderTop: "1px solid #e6e6e6",
                                paddingTop: 4,
                            }}
                        >
                            {this.props.buttons.map((btn, i) => {
                                switch (btn) {
                                    case "add":
                                        return (
                                            <button
                                                className="btn btn-primary"
                                                key={i}
                                                onClick={this.addNew}
                                            >Add</button>
                                        );
                                    case "delete":
                                    case "remove":
                                        return (
                                            <button
                                                key={i}
                                                className="btn btn-secondary"
                                                disabled={!this.current}
                                                onClick={this.delete}
                                            >Delete</button>
                                        );
                                    default:
                                        break;
                                }
                                return null;
                            })}
                        </div>
                    ) : null}
                </div>
                <div
                    style={{
                        padding: 10,
                        background: "#fff",
                        flex: 1,
                        border: "2px solid #f5f5f5",
                    }}
                >
                    {this.current && this.props.children
                        ? this.props.children(this.current)
                        : null}
                </div>
            </div>
        );
    }
}

export default JsTree;
