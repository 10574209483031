import { Icon, IconButton } from '@mui/material';
import useApi from 'com/API/api';
import { defineAtomElement } from 'com/Atom/Atom';
import Input, { FormItem } from 'com/Imput/Input';
import Modal from 'com/Modal/Modal';
import { Debounce } from 'com/utils/createBindFunction';
import useCrud, { TableCRUDDialog, TableCRUDeleteDialog, TableCRUDFilter, TableCRUDLoading, TableCRUDTable } from 'com/utils/useCrud';
import React, { useState } from 'react';
import { useRef } from 'react';
import FamilleArticle from './famille-article.atom';

const colmuns = [
    {
        key: 'ref',
        title: 'Référence',
        type: 'text',
        formClass: 'col-6',
        inFilter: true,
        inTable: true,
        inForm: true
    },
    {
        key: 'titre',
        title: 'Titre',
        type: 'text',
        formClass: 'col-6',
        inFilter: true,
        inTable: true,
        inForm: true
    },
    {
        key: 'famille',
        title: 'Famille',
        formClass: 'col-6',
        inFilter: ({ item }) => <FamilleArticle.Select label="Famille" {...item.bind('famille')} emptyOption="Tout" />,
        inTable: ({ item }) => item.get('famille.label'),
        inForm: ({ item }) => <FamilleArticle.Select label="Famille" {...item.bind('famille')} />,
    },
    {
        key: 'date_debut',
        title: 'Date debut',
        formClass: 'col-3',
        type: 'date',
        inFilter: true,
        inTable: ({ item }) => item.get('date_debut_format'),
        inForm: true,
    },
    {
        key: 'date_fin',
        title: 'Date fin',
        formClass: 'col-3',
        type: 'date',
        inFilter: true,
        inTable: ({ item }) => item.get('date_fin_format'),
        inForm: true,
    },
    {
        key: 'image',
        title: 'Image',
        type: 'photo',
        formClass: 'col-6',
        indexTable: -1,
        inTable: false,
        inForm: true
    },
    {
        key: 'list',
        title: 'Liste',
        type: 'list',
        formClass: 'col-6',
        inForm: function InForm ({ item }) {
            const modal = useRef();
            return (
                <FormItem label="Liste des elements">
                    <div style={{ flex: 1 }}>
                        <table className='table table-bordered' style={{ borderRadius: 5 }}>
                            <thead>
                                <tr className='bg-input'>
                                    <th>Col1</th>
                                    <th>Col2</th>
                                    <th>Col3</th>
                                    <th style={{ width: 1 }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {item.getList('list').map((elm, i) => (
                                    <tr key={i}>
                                        <td>{elm.titre}</td>
                                        <td>Value 2</td>
                                        <td>Value 3</td>
                                        <td style={{ padding: '3px 0' }}><IconButton size="small" onClick={elm.delete}><Icon>close</Icon></IconButton></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button className='btn btn-primary btn-sm' onClick={() => modal.current.show({ titre: '123' }, { bindable: true })}>Add</button>
                        <Modal ref={modal} onOK={(elm) => {
                            item.pushTo('list', elm);
                            modal.current.hide();
                        }}>
                            {(elm) => <Input.Text label="Titre" {...elm.bind('titre')}/>}
                        </Modal>
                    </div>
                </FormItem>
            )
        }
    },
    {
        type: 'tabs',
        formClass: 'col-12',
        tabs: [{
            title: 'Tab 1',
            content: [
                {
                    key: 'ref',
                    title: 'Référence',
                    type: 'text',
                    formClass: 'col-6',
                    inForm: true
                }
            ]
        }, {
            title: 'Tab 2',
            content: [
                {
                    key: 'titre',
                    title: 'Titre',
                    type: 'text',
                    formClass: 'col-6',
                    inForm: true
                },
                {
                    key: 'famille',
                    title: 'Famille',
                    formClass: 'col-6',
                    inForm: ({ item }) => <FamilleArticle.Select label="Famille" {...item.bind('famille')} />,
                },
            ]
        }]
    }
];

const actions = [
    { icon: 'edit' },
    { icon: 'delete' },
];

function Projects({ item, ...props }) {
    const { list, selected, loading, filter, loadData, nbrPages, createNewItem, total, page } = useCrud('projects', { length: 10, id: 'id' }, props);

    return (
        <TableCRUDLoading loading={loading}>
            <TableCRUDFilter onSearch={() => loadData()} onAdd={() => createNewItem()}>
                <Debounce {...filter.bind('', { emptyValue: {} })} wait={500} onUpdate={() => loadData()} >
                    {(filterDraft) => colmuns.sortBy('indexFilter').map(col => <Input.Auto key={col.key} role='filter' item={filterDraft} config={col} />)}
                </Debounce>
            </TableCRUDFilter>
            <TableCRUDTable
                rows={list}
                role='table'
                colmuns={colmuns.sortBy('indexTable')}
                actions={actions}
                loadData={loadData}
                page={page}
                total={total}
                nbrPages={nbrPages}
                hideOptions
            />
            <TableCRUDDialog item={selected.edit} header="Modification" actions={(item) => [
                { label: 'Annuler', onClick: () => { item.reset(); item.close('edit'); } },
                {
                    label: 'Enregistrer', variant: 'contained', onClick: () => {
                        item.accept();
                        selected.edit.save().then(ok => ok ? selected.edit.close() : null);
                    }
                }
            ]}>
                {item => (
                    <div className='row' style={{ minWidth: 700 }}>
                        {colmuns.sortBy('indexForm').map(col => <div className={col.formClass || 'col-12'} key={col.key}><Input.Auto role='form' item={item} config={col} /></div>)}
                    </div>
                )}
            </TableCRUDDialog>
            <TableCRUDeleteDialog item={selected.delete} />
        </TableCRUDLoading >
    );
}

Projects.Select = React.memo(function Select(props) {
    const { post } = useApi();
    const [open, setOpen] = useState(false);
    const [key, setKey] = useState(1);

    return (
        <>
            <Input.Select2
                {...props}
                key={key}
                loadOptions={(q) => post('projects/select', { q })}
                valueCol='id'
                action={props.emptyOption ? null : {
                    label: <Icon>add</Icon>,
                    onClick: () => setOpen({})
                }}
            />
            <TableCRUDDialog fullWidth item={open} header="Projects" actions={(item) => [{ label: 'Fermer', onClick: () => { setOpen(false); } }]} onClose={() => setOpen(false)}>
                {() => <Projects onSave={() => setKey(key + 1)} />}
            </TableCRUDDialog>
        </>
    );
});

function ProjectsConfig({ item }) {
    return (
        <div className='row'>
            <div className='col-12'>
                <Projects.Select />
            </div>
        </div>
    )
}

defineAtomElement({
    name: 'Projects',
    title: 'Projects',
    default: {},
    Component: React.memo(Projects),
    Config: ProjectsConfig
});

export default Projects;
