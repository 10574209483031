import { Alert, AlertTitle } from '@mui/material';
import React from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import useConfig from './utils/useConfig';

function Loading({ loading, topRelative, fullScreen, children }) {
  const config = useConfig({ name: 'config', version: 'config' });
  const color = config?.space?.color || '#476396';
  if (fullScreen) {
    return (
      <div className='Loading-full-screen'>
        <div className='Loading-parent'>
          {loading ? <div className='Loading'><PulseLoader color={color} size={15} /></div> : null}
          {children}
        </div>
      </div>
    )
  }
  return (
    <div className={`Loading-parent ${loading && topRelative ? 'Loading-topRelative' : ''}`}>
      {loading ? <div className='Loading'><PulseLoader color={color} size={15} /></div> : null}
      {children}
    </div>
  )
}

export function AlertBox({ topRelative, type = "error", fullScreen, children, content, onClose }) {

  const alertContent = content ? (
    <div className='Loading' style={{ textAlign: 'left' }}>
      <Alert severity={type} onClose={onClose} style={{ maxWidth: '70%' }}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Alert>
    </div>
  ) : null;

  if (fullScreen) {
    return (
      <div className='Loading-full-screen'>
        <div className='Loading-parent'>
          {alertContent}
          {children}
        </div>
      </div>
    )
  }

  return (
    <div className={`Loading-parent ${content && topRelative ? 'Loading-topRelative' : ''}`}>
      {alertContent}
      {children}
    </div>
  )
}

export default Loading
