import { atom } from "recoil";

export const userState = atom({
    key: 'userState', 
    default: null,
});

export const userReady = atom({
    key: 'userReady', 
    default: false,
});

export const generatedModules = atom({
    key: 'generatedModules', 
    default: [],
});

export const activeProfile = atom({
    key: 'activeProfile', 
    default: null,
});
