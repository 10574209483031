import { Icon } from '@mui/material';
import useApi from 'com/API/api';
import { defineAtomElement } from 'com/Atom/Atom';
import Input from 'com/Imput/Input';
import { Debounce } from 'com/utils/createBindFunction';
import useCrud, { TableCRUDDialog, TableCRUDeleteDialog, TableCRUDFilter, TableCRUDLoading, TableCRUDTable } from 'com/utils/useCrud';
import { useState } from 'react';

export function Client() {
  const { list, selected, loading, filter, loadData, nbrPages, createNewItem, total, page } = useCrud('client', { length: 10, id: 'id' });

  const colmuns = [
    { key: 'nom', title: 'Nom' }
  ];

  const actions = [
    { icon: 'edit', label: 'Edit' },
    { icon: 'delete', label: 'Delete' },
  ];

  return (
    <TableCRUDLoading loading={loading}>
      <TableCRUDFilter onSearch={() => loadData()} onAdd={() => createNewItem()}>
        <Debounce {...filter.bind('', { emptyValue: {} })} wait={500} onUpdate={() => loadData()} >
          {(filterDraft) => (
            <>
              <Input.Text label="Nom" {...filterDraft.bind('nom')} />
            </>
          )}
        </Debounce>
      </TableCRUDFilter>
      <TableCRUDTable
        rows={list}
        colmuns={colmuns}
        actions={actions}
        loadData={loadData}
        page={page}
        total={total}
        nbrPages={nbrPages}
        hideOptions
      />
      <TableCRUDDialog item={selected.edit} header="Modification" actions={(item) => [
        { label: 'Annuler', onClick: () => { item.reset(); item.close('edit'); } },
        {
          label: 'Enregistrer', variant: 'contained', onClick: () => {
            item.accept();
            selected.edit.save().then(ok => ok ? selected.edit.close() : null);
          }
        }
      ]}>
        {item => (
          <div className='row' style={{ minWidth: 700 }}>
            <div className='col-12'>
              <Input.Text label="Nom" {...item.bind('nom')} />
            </div>
          </div>
        )}
      </TableCRUDDialog>
      <TableCRUDeleteDialog item={selected.delete} />
    </TableCRUDLoading >
  );
}

Client.Select = function Select(props) {
  const { post } = useApi();
  const [open, setOpen] = useState(false);
  const [keyInc, setKeyInc] = useState(0);

  return (
    <>
      <Input.Select2 {...props}
        key={`key-${keyInc}`}
        loadOptions={(q) => post('client/select', { q })}
        valueCol='id'
        action={props.emptyOption ? null : {
          label: <Icon>add</Icon>,
          onClick: () => {
            setOpen({});
          }
        }}
      />
      <TableCRUDDialog fullWidth item={open} header="Client" actions={(item) => [{ label: 'Fermer', onClick: () => { setOpen(false); setKeyInc(keyInc + 1); } }]}>
        {() => (
          <Client />
        )}
      </TableCRUDDialog>
    </>
  );
}

function ClientConfig({ item }) {
  return (
    <div className='row'>
      <div className='col-12'>
        <Input.Text lable="Title" {...item.bind('title', { emptyValue: '' })} />
      </div>
    </div>
  )
}

defineAtomElement({
  name: 'Client',
  title: 'Client',
  default: {},
  Component: Client,
  // Config: ClientConfig
});

export default Client;
