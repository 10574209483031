import Atom from "./Atom";
import './Com/Text.atom';
import './Com/Sep.atom';
import './Com/Cols.atom';
import './Com/Config.atom';
import './Com/UserInfo.atom';
import './Com/ENV.atom';
import './Com/CRUD.atom';
import './Com/FormBuilder.atom';
import './Com/ModulesManager.atom';
import './Com/Image.atom';
import './Com/AtomCom.atom';
import './Com/Paper.atom';
import './Com/commerce/Ticket.atom';
import './Com/SAAS.atom';
import { useAtomDoc } from './../Firebase/Firebase';
import Loading from './../Loading';
import { useRecoilState } from "recoil";
import { userState } from "../../states";
import "com/Atom/Com/commerce/famille-article.atom";
import "com/Atom/Com/commerce/articles.atom";
import "com/Atom/Com/commerce/unite.atom";
import "com/Atom/Com/commerce/client.atom";
import "com/Atom/Com/commerce/fournisseur.atom";
import "com/Atom/Com/commerce/docs.atom";
import "com/Atom/Com/Tabs.atom";
import "com/Atom/Com/social/push.atom";
import "com/Atom/Com/users/users.atom";
import "com/Atom/Com/calendar.atom";
import "com/Atom/Com/commerce/projects.atom";
import "com/Atom/Com/users/welcome.atom";
import "com/Atom/Com/commerce/taxes.atom";

function AtomPages({ name }) {
  return (
    <div style={{ padding: '30px 15px' }}>
      <AtomLine name={name} />
    </div>
  )
}

export function AtomLine({ name }) {
  const [user] = useRecoilState(userState);
  const { bind, loading } = useAtomDoc({ name: name || window.location.pathname, version: ((user && user.role && user.role.name) || 'public'), cache: true });
  return (
    <Loading loading={loading} >
      <Atom {...bind('list')} editMode={user && Array.isArray(user.roles) && user.roles.map(role => role.name).includes('admin')} />
    </Loading>
  )
};

export default AtomPages
