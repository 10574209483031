import { get, set } from 'lodash';
import React, { useState, useEffect } from 'react';
import './Atom.css';

export const ATOMELEMENTS = [];
export const ATOMLAYOUTS = [];

function getUniqId() {
  return ("K" + Math.random().toString(16).slice(2)).toUpperCase();
}

function utf8_to_b64(str) {
  return window.btoa(encodeURIComponent(str));
}

function b64_to_utf8(str) {
  return decodeURIComponent(window.atob(str));
}

function move(list, old_index, new_index) {
  if (new_index >= list.length) {
    var k = new_index - list.length;
    while (k-- + 1) {
      list.push(undefined);
    }
  }
  list.splice(new_index, 0, list.splice(old_index, 1)[0]);
  return list;
};

function move_up(list, index) {
  if (index > 0) {
    return move(list, index, index - 1);
  }
  return list;
};

function move_down(list, index) {
  if (index < list.length - 1) {
    return move(list, index, index + 1);
  }
  return list;
};



const Atom = React.forwardRef(({ value, onChange, editMode, atomOptions }, ref) => {
  const [addNewVisible, setAddNewVisible] = useState(false);
  let [list, setList] = useState([]);

  useEffect(() => {
    setList(JSON.parse(JSON.stringify(Array.isArray(value) ? value : [])));
  }, [value])

  const changed = (list) => {
    if (onChange) onChange(list);
  }

  const addItem = (item = {}, index) => {
    if (index >= 0) {
      list.splice(index, 0, initItem(item));
    } else {
      list.push(initItem(item));
    }
    setList([...list]);
    changed(list);
  }

  const initItem = (item = {}) => {
    item.key = item.key || getUniqId();
    item.i = list.indexOf(item);
    item.l = (atomOptions && atomOptions.l) || 0;
    item.editMode = editMode;
    item.$set = (args = {}) => {
      Object.assign(item, args);
      setList([...list]);
      changed(list);
    }

    item.onAdd = (elm = {}) => {
      addItem(elm, item.i);
    }

    item.$bind = (name, { emptyValue = null, initialValue = null } = {}) => {
      let value = get(item, name, null);
      if(!Object.keys(item).includes(name)) {
        value = initialValue;
      }
      if ([undefined, null].includes(value)) {
        value = emptyValue;
      }
      return {
        value,
        onChange: (val) => {
          let v = null;
          if (val && typeof val === 'object' && 'target' in val) {
            if ('type' in val.target && ['checkbox'].includes(val.target.type.trim().toLowerCase())) {
              v = val.target.checked;
            } else {
              v = val.target.value;
            }
          } else {
            v = val;
          }
          set(item, name, v);
          item.$set();
        }
      }
    }

    item.bind = item.$bind;

    item.$bindTo = (obj, name, options = {}) => {
      return {
        value: obj[name] || '',
        onChange: e => {
          if (obj) {
            if (e && e.target) {
              obj[name] = e.target.value || '';
            } else {
              obj[name] = e || '';
            }
            item.$set();
          }
        }
      }
    }

    item.paste = () => {
      navigator.clipboard.readText().then(text => {
        try {
          const data = JSON.parse(b64_to_utf8(text));
          if (data) {
            if (Array.isArray(data)) {
              list.splice(item.i, 0, { key: getUniqId(), name: 'atom', list: data });
            } else if (data.key) {
              list.splice(item.i, 0, data);
            }
            setList([...list]);
            changed(list);
          }
        } catch (e) { }
      });
    }

    item.edit = () => {
      item.$set({ $mode: 'edit' });
    }

    item.copy = () => {
      navigator.permissions.query({ name: "clipboard-write" }).then(result => {
        if (result.state === "granted" || result.state === "prompt") {
          navigator.clipboard.writeText(utf8_to_b64(JSON.stringify({ ...item, key: getUniqId() })))
        }
      });
    }

    item.close = () => {
      item.$set({ $mode: '' });
    }

    item.up = () => {
      list = move_up([...list], list.indexOf(item));
      setList(list);
      changed(list);
    }

    item.down = () => {
      list = move_down([...list], list.indexOf(item));
      setList(list);
      changed(list);
    }

    item.delete = () => {
      list = [...list.filter(elm => elm !== item)];
      setList(list);
      changed(list);
    }

    return item;
  }

  list = list.map(initItem);

  const copyList = () => {
    navigator.permissions.query({ name: "clipboard-write" }).then(result => {
      if (result.state === "granted" || result.state === "prompt") {
        navigator.clipboard.writeText(utf8_to_b64(JSON.stringify(list)))
      }
    });
  }

  const pasteList = () => {
    navigator.clipboard.readText().then(text => {
      try {
        const data = JSON.parse(b64_to_utf8(text));
        if (data) {
          if (Array.isArray(data)) {
            list.push(...data);
          }
          setList([...list]);
          changed(list);
        }
      } catch (e) { }
    });
  }

  return (
    <div className={`atom-list ${list.length ? '' : 'atom-list-empty'}`}>
      {editMode ? (
        <div className="atom-list-bottom">
          <button onClick={() => setAddNewVisible(true)}><span className="material-icons">add</span></button>
          <button onClick={() => copyList()}><span className="material-icons">content_copy</span></button>
          <button onClick={() => pasteList(true)}><span className="material-icons">content_paste_go</span></button>
          <AddModal visible={addNewVisible} onClose={() => setAddNewVisible(false)} onAdd={addItem} />
        </div>
      ) : null}
      <div className="atom-list-content">
        {list.map(atomitem => <AtomItem key={atomitem.key} item={atomitem} atomOptions={atomOptions} editMode={editMode} />)}
      </div>
    </div>
  )
});

function AddModal({ visible, onClose, onAdd }) {
  return (
    <Modal
      visible={visible}
      header="Add"
      footer={(
        <>
          <button className="atombtn" onClick={onClose}>Annuler</button>
        </>
      )}
    >
      <div className="AtomListComToAdd">
        {ATOMELEMENTS.map(elm => <button key={elm.name} onClick={() => {
          onAdd({ name: elm.name, $mode: 'edit', ...elm.default });
          onClose();
        }} >{elm.title}</button>)}
      </div>
    </Modal>
  )
}

export function AtomItem({ item, editMode, atomOptions }) {
  const [Com, setCom] = useState(null);
  const [addNewVisible, setAddNewVisible] = useState(false);
  useEffect(() => {
    setCom(getComByName(item.name));
  }, [item.name]);

  useEffect(() => {
    if (!item) return;
    item.editMode = editMode;
    item.addBefore = () => {
      setAddNewVisible(true);
    }
  }, [item, editMode])

  if (!Com) return editMode ? "No Com" : null;
  if (!editMode) return Com.Component ? <Com.Component item={item} editMode={editMode} atomOptions={atomOptions} /> : null;

  return (
    <div className="atom-render-item atom-render-item-build atom-item-container">
      <div className="atom-item-menu" style={{ left: (2 + (28 * (item.i === 0 ? (item.l || 0) : 0))) + 'px' }}>
        <div className="atom-item-menu-btn">
          <button onClick={e => item.edit()} ><span className="material-icons">settings</span></button>
        </div>
        <div className="atom-item-menu-list">
          {Com.Config ? <button onClick={e => item.edit()} ><span className="material-icons">edit</span></button> : null}
          <button onClick={e => item.copy()} ><span className="material-icons">content_copy</span></button>
          <button onClick={e => item.paste()} ><span className="material-icons">content_paste_go</span></button>
          <button onClick={e => item.addBefore()} ><span className="material-icons">add</span></button>
          <button onClick={e => item.up()} ><span className="material-icons">keyboard_arrow_up</span></button>
          <button onClick={e => item.down()} ><span className="material-icons">keyboard_arrow_down</span></button>
          <button onClick={e => item.delete()} ><span className="material-icons">delete</span></button>
        </div>
      </div>
      {Com.Component ? <Com.Component item={item} atomOptions={atomOptions} /> : null}
      {Com.Config ? <ConfigModal item={item} Com={Com} atomOptions={atomOptions} /> : null}
      <AddModal visible={addNewVisible} onClose={() => setAddNewVisible(false)} onAdd={item.onAdd} />
    </div>
  )
}

function ConfigModal({ item, Com, atomOptions }) {
  return (
    <Modal
      visible={item.$mode === 'edit'}
      header="Config"
      footer={(
        <>
          <button className="atombtn" onClick={e => item.close()}>Annuler</button>
          <button className="atombtn atomprimary" onClick={e => item.close()}>Valider</button>
        </>
      )}
    >
      <Com.Config item={item} atomOptions={atomOptions} />
    </Modal>
  )
}

function Modal({ visible, header, footer, children }) {
  return (
    <div className={`AtomModal ${visible ? 'AtomModal-visible' : ''}`}>
      <div className="AtomModalDialog">
        {header ? <div className="AtomModal-header">{visible ? header : null}</div> : null}
        <div className="AtomModal-body">{visible ? children : null}</div>
        {footer ? <div className="AtomModal-footer">{visible ? footer : null}</div> : null}
      </div>
    </div>
  )
}

export const getComByName = (name) => {
  return ATOMELEMENTS.find(elm => elm.name === name);
}

export function defineAtomElement(options) {
  if (ATOMELEMENTS.find(elm => elm.name === options.name)) {
    throw new Error('Element already exists');
  }
  ATOMELEMENTS.push(options);
}

export function defineAtomLayout(options) {
  if (ATOMLAYOUTS.find(elm => elm.name === options.name)) {
    throw new Error('Layout already exists');
  }
  ATOMLAYOUTS.push(options);
}

export const getSubDomain = (str) => {
  if (str.split('.').length <= 2) return 'www';
  const sdom = str.split('.').splice(0, str.split('.').length - 2).join('.');
  if (!sdom) return 'www';
  return str.split('.').splice(0, str.split('.').length - 2).join('.');
}

export const getDomain = (str) => {
  return str.split(':')[0].split('.').slice(-2).join('.');
}

export const getPath = (str) => {
  const result = (str + '/').replace('//', '/').slice(0, -1).split('/').join('¤');
  if (!result) return '¤';
  return result;
}

defineAtomElement({
  name: 'atom',
  title: 'Atom',
  Component: (args) => (<Atom value={args.item.list} onChange={list => args.item.$set({ list })} {...args} atomOptions={{ ...args.atomOptions, l: (args.atomOptions?.l || 0) + 1 }} />)
})


// function AtomCols ({ args }) {
//   return (
//     <div className="AtomCols">
//       {(atomList.list || []).map(item => <div key={item.key}><Atom value={args.item.list} onChange={list => args.item.$set({ list })} atomOptions={{ ...args, l: (args.atomOptions?.l || 0) + 1 }} {...args} /></div>)}
//       <button onClick={atomList.onClickAddItem({ name: 'atom' })} >Add atom</button>
//     </div>
//   )
// }

// defineAtomElement({
//   name: 'cols',
//   title: 'Cols',
//   Component: (args) => <Atom {...args} Component={AtomCols} />
// });


defineAtomElement({
  name: 'container',
  title: 'Container',
  default: {
    list: []
  },
  Component: (args) => (
    <div className="container">
      <Atom value={args.item.list} onChange={list => args.item.$set({ list })} {...args} atomOptions={{ ...args.atomOptions, l: (args.atomOptions?.l || 0) + 1 }} />
    </div>
  )
})

export default Atom;
