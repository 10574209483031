Number.prototype.val = function (v) {
    var b = this.toFixed(4);
    var v = b.substr(0, b.length - 1) * 1;
    return v;
};
String.prototype.val = function (v) {
    return 0;
};

Number.prototype.op = function (v, op) {
    v = parseFloat(v) || 0;
    switch (op) {
        case '+':
            return ((this.toFixed(3) * 1000) + (v.toFixed(3) * 1000)) / 1000;
            break;
        case '-':
            return ((this.toFixed(3) * 1000) - (v.toFixed(3) * 1000)) / 1000;
            break;
        case '*':
            return ((this.toFixed(3) * 1000) * (v.toFixed(3) * 1000)) / (1000 * 1000);
            break;
        case '/':
            return (this.toFixed(3) * 1000) / (v.toFixed(3) * 1000);
            break;
    }
    return this;
}

Number.prototype.s = function (v) {
    return this.op(v, '-');
}

Number.prototype.m = function (v) {
    return this.op(v, '*');
}

Number.prototype.a = function (v) {
    return this.op(v, '+');
}

Number.prototype.d = function (v) {
    return this.op(v, '/');
}



String.prototype.s = function (v) {
    return parseFloat(this).s(v);
}

String.prototype.m = function (v) {
    return parseFloat(this).m(v);
}

String.prototype.a = function (v) {
    return parseFloat(this).a(v);
}

String.prototype.d = function (v) {
    return parseFloat(this).d(v);
}

Array.prototype.total = function (col) {
    const tt = this.reduce(function (acc, item) {
        const v = (item[col] && item[col] * 1) || 0;
        return acc.a(v);
    }, 0);
    return tt;
}
