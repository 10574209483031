import { Button, Paper } from '@mui/material';
import { defineAtomElement } from 'com/Atom/Atom';
import Input from 'com/Imput/Input';
import { useRecoilState } from 'recoil';
import { userState } from 'states';
import TextEditor from './TextEditor';
import UserAvatar from './UserAvatar';

function Push({ item }) {
    const [user] = useRecoilState(userState);
    if (!user) return;

    return (
        <Paper className="w-box">
            <UserAvatar displayName>
                <TextEditor placeholder="Message" />

                <hr />
                <div className='w-flex-right'>
                    <Button variant='contained'>Submit</Button>
                </div>
            </UserAvatar>
        </Paper>
    )
}


function PushConfig({ item }) {
    return (
        <div className='row'>
            <div className='col-12'>
                <Input.Text lable="Title" {...item.bind('title', { emptyValue: '' })} />
            </div>
        </div>
    )
}

defineAtomElement({
    name: 'Push',
    title: 'Push',
    default: {},
    Component: Push,
    Config: PushConfig
});

export default Push;
