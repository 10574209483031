import { Button } from '@mui/material';
import useApi from 'com/API/api';
import { defineAtomElement } from 'com/Atom/Atom';
import Input from 'com/Imput/Input';
import useBindableState from 'com/utils/useBindableState';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from 'states';

function Welcome({ item }) {
    const [user] = useRecoilState(userState);
    const state = useBindableState({});
    const { post } = useApi();
    useEffect(() => {
        state.set({ username: (user.username || user.login || '').split('@')[0].replace(/[^a-z0-9]+/ig, '') });
    }, [user]);

    const save = async () => {
        try {
            state.set({ $loading: true });
            const data = await post('users/update-username', { username: state.username });
            if (data) {
                state.set({ ...data, $loading: false }, true);
            } else {
                state.set({ $loading: false });
            }
            return data && data.success;
        } catch (error) {
            state.set({ $loading: false });
            console.log(error);
        }
    }

    useEffect(() => {
        if (state.username !== (state.username || '').replace(/[^a-z0-9]+/ig, '')) {
            state.set({ username: (state.username || '').replace(/[^a-z0-9]+/ig, '') });
        }
    }, [state.username]);

    return (
        <div style={{ display: 'flex', gap: 15, alignItems: 'flex-end' }}>
            <Input.Text label="Username" prefix="@" {...state.bind('username')} style={{ flex: 1 }} />
            <Button variant='contained' style={{ height: 38 }} onClick={save}>Valider</Button>
        </div>
    )
}

defineAtomElement({
    name: 'Welcome',
    title: 'Welcome',
    default: {},
    Component: Welcome
});

export default Welcome;
