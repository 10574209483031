import React from 'react'
import Input from '../../Imput/Input';
import { defineAtomElement } from '../Atom';

function Sep({ item }) {
  switch (item.type) {
    case 'Empty':
      return <div style={{ margin: item.margin }} />
    default:
      return <hr style={{ margin: item.margin }} />
  }
}


function SepConfig({ item }) {
  return (
    <div className='row'>
      <div className='col-6'>
        <Input.Select lable="Type" options={["HR", "Empty"]} {...item.bind('type', { emptyValue: '' })} />
      </div>
      <div className='col-6'>
        <Input.Text lable="Margin" {...item.bind('margin', { emptyValue: '' })} />
      </div>
    </div>
  )
}

defineAtomElement({
  name: 'sep',
  title: 'Separateur',
  Component: Sep,
  Config: SepConfig
});


export default Sep
