import { defineAtomElement } from 'com/Atom/Atom';
import Input from 'com/Imput/Input';

function Fournisseur({ item }) {
    return (
        <h1>
            {item.title}
        </h1>
    )
}

function FournisseurConfig({ item }) {
    return (
        <div className='row'>
            <div className='col-12'>
                <Input.Text lable="Title" {...item.bind('title', { emptyValue: '' })} />
            </div>
        </div>
    )
}

defineAtomElement({
    name: 'Fournisseur',
    title: 'Fournisseur',
    default: {},
    Component: Fournisseur,
    Config: FournisseurConfig
});

export default Fournisseur;
