import React from 'react';
import FormRender, { FIELDTYPES, FormBuilder } from '../../FormBuilder/FormBuilder';
import Input from '../../Imput/Input';
import Loading from '../../Loading';
import Modal from '../../Modal/Modal';
import { defineAtomElement } from './../Atom';
import { Pagination, useModulesManager } from './ModulesManager.atom';

function CRUD({ item, config2 }) {
    const { activeItem, loading, filter, loadData, infos, list, createNewItem } = useModulesManager({ apiEndPoint: item.path || 'crud' });
    const cols = (item.list || [])
        .filter(({ name }) => name)
        .map(field => ({ ...field, Field: FIELDTYPES.find(f => f.name === field.type), listTable: (item.listTable || []).find(e => e.itemName === field.name) }));

    const showCols = (item.listTable || []).filter(elm => elm).map(elm => cols.find(e => e.name === elm.itemName && e.Field && e.Field.Td && e.Field.Th));

    return (
        <div>
            <Loading loading={loading}>
                <h1>{config2?.titre || item?.titre}</h1>
                <div className='d-flex gap-2 justify-content-between align-items-end mb-3'>
                    <FormRender.Filter form={filter} schema={item.listFilter} props={item.list} />
                    <span className='flex-1' />
                    <button className='btn btn-secondary btn-icon' onClick={() => loadData()}><span className="material-symbols-outlined">search</span></button>
                    <button className='btn btn-primary btn-icon' onClick={() => createNewItem({})}><span className="material-symbols-outlined">add</span> Ajouter</button>
                </div>
                <div className="card">
                    <div className="card-body" style={{ minHeight: 350, padding: 0 }}>
                        <table className='table table-striped table-hover'>
                            <thead className="table-light">
                                <tr>
                                    {showCols
                                        .map((filed) => {
                                            const { Field } = filed || {};
                                            if (!Field) return null;
                                            return (
                                                <th key={filed?.name}>
                                                    <Field.Th config={filed} />
                                                </th>
                                            )
                                        })}
                                    <th className='td-actions'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map(item => {
                                    if (item.$loading) {
                                        return (
                                            <tr key={item.id}>
                                                <td colSpan={showCols.length + 1}>
                                                    <div style={{ height: 24 }} className='d-flex justify-content-center align-items-center'>
                                                        <Loading loading />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    }

                                    return (
                                        <tr key={item.id}>
                                            {showCols
                                                .map((filed) => {
                                                    const { Field } = filed || {};
                                                    return (
                                                        <td key={filed.name}>
                                                            <Field.Td config={filed} item={item} value={item[filed.name]} />
                                                        </td>
                                                    )
                                                })}
                                            <td className='td-actions'>
                                                <div>
                                                    <button className='btn btn-action' onClick={item.edit}><span className="material-symbols-outlined">edit</span></button>
                                                    <button className='btn btn-action' onClick={() => item.delete()}><span className="material-symbols-outlined">delete</span></button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='my-3'>
                    <Pagination {...infos} />
                </div>
            </Loading>
            <Modal
                visible={activeItem}
                title="Configuration"
                loading={activeItem && activeItem.$loading}
                style={{ maxWidth: 800 }}
                onClose={() => activeItem.reset().close()}
                onOK={() => activeItem.save()}
                okText="Enregistrer"
            >
                {activeItem ? <FormRender form={activeItem} schema={item.list} /> : null}
            </Modal>
        </div>
    )
}

export function CRUDConfig({ item }) {
    return (
        <div className='row'>
            <div className='col-6'>
                <Input.Text label="Titre" {...item.bind('titre')} placeholder="Titre" />
            </div>
            <div className='col-12'>
                <FormBuilder {...item.bind('list', { emptyValue: [] })} panelTitle={item => ` - ${item.label}`} label="Liste des champs" />
            </div>
            <div className='col-12'>
                <FormBuilder.Filter {...item.bind('listFilter', { emptyValue: [] })} formItems={item.list || []} panelTitle={item => ` - ${item.typefilter} - ${item.itemName}`} label="Liste des filters" />
            </div>
            <div className='col-12'>
                <FormBuilder.Table {...item.bind('listTable', { emptyValue: [] })} formItems={item.list || []} panelTitle={item => ` - ${item.typetable} - ${item.itemName}`} label="Liste des items dans table" />
            </div>
        </div>
    )
}

export function CRUDConfig2({ config2 }) {
    return (
        <div className='row'>
            <div className='col-6'>
                <Input.Text label="Titre config 2" {...config2.bind('titre')} placeholder="Titre" />
            </div>
        </div>
    )
}

defineAtomElement({
    name: 'CRUD',
    title: 'CRUD',
    default: {},
    Component: CRUD,
    Config: CRUDConfig,
    Config2: CRUDConfig2,
});

export default CRUD;
