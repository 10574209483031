import React, { useMemo } from 'react';
import { Tabs, Box, Tab, styled, useTheme } from '@mui/material';



function WTabs({ tabs, defaultValue, value, onChange }) {
  if (!Array.isArray(tabs)) tabs = [];
  function getTabValue(tab, i) {
    return tab.id || tab.key || tab.value || i
  }
  const theme = useTheme();

  const AntTab = useMemo(() => styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  })), []);

  return (
    <Box className='w-tabs' style={{ borderColor: theme.palette.primary.main }}>
      <Box sx={{ backgroundColor: theme.palette.primary.main, width: '100%' }}>
        <Tabs
          value={value}
          onChange={onChange}
          variant="scrollable"
          scrollButtons="auto"
          className="wtabsui"
        >
          {tabs.map((tab, i) => <AntTab key={getTabValue(tab, i)} value={getTabValue(tab, i)} {...tab} />)}
        </Tabs>
      </Box>
      <div className='w-tabs-content'>
      {tabs.map((tab, i) => <div key={getTabValue(tab, i)} style={{ display: value === getTabValue(tab, i) ? 'block' : 'none' }}>{tab.content}</div>)}
      </div>
    </Box>
  );
}

export default WTabs;
