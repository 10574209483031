import { initializeApp } from 'firebase/app';
import { sendPasswordResetEmail, updateProfile, reload, signOut, getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import firebaseConfig from '../../config/firebaseConfig';
import { useRecoilState } from 'recoil';
import { userReady, userState } from './../../states';
import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc, setDoc, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useCallback } from 'react';
import history from '../history';
import { get, set } from 'lodash';
import PropagateLoader from "react-spinners/PropagateLoader";
import useApi, { api } from '../API/api';
import { useAuthDefinitions } from '../Auth/Auth.service';


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

function delay(time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

async function getUserObject(user, n) {
  const docRef = doc(db, "users", user.uid);
  const docSnap = await getDoc(docRef);
  const profile = { ...user, ...docSnap.data() };
  if (!Array.isArray(profile.roles) || !profile.roles.length) {
    await delay(1000);
    if (n < 50) {
      return await getUserObject(user, n + 1);
    }
    profile.roles = [];
  }
  let role = localStorage.getItem('role') || null;
  if (!role || !profile.roles.includes(role)) role = profile.roles[0];
  profile.role = role;
  profile.switch = (role) => {
    localStorage.setItem('role', role);
    debugger;
  }
  return profile;
}

export function FirebaseInitCom(props) {
  const [, setUserInfo] = useRecoilState(userState);
  const [ready, setReady] = useRecoilState(userReady);
  const { checking } = useAuthDefinitions();
  const api = useApi();
  useEffect(() => {
    window.auth = auth;
    console.log('useEffect:onAuthStateChanged', auth);
    onAuthStateChanged(auth, async (user) => {
      console.log('onAuthStateChanged', user);
      if (user) {
        setReady(false);
        const idToken = await user.getIdToken();
        await api.post('auth/fbtoken', { idToken });
        setReady(true);
      } else {
        setUserInfo(null);
      }
      setReady(true);
    });
  }, [setReady, setUserInfo]);
  if (checking || (!ready && !localStorage.getItem('token'))) return <div className='main-loader'><PropagateLoader color="#476396" size={15} /></div>;
  return props.children;
}

export async function signInWith(provider) {
  let providerObj = null;
  switch (`${provider}`.trim().toLowerCase()) {
    case 'facebook':
      providerObj = new FacebookAuthProvider();
      break;
    default:
      providerObj = new GoogleAuthProvider();
      break;
  }
  providerObj.addScope('profile');
  providerObj.addScope('email');
  try {
    const result = await signInWithPopup(auth, providerObj);
    console.log('signInWithPopup', result);
    return result.user;
  } catch (error) {
    console.error(error);
  }
  return null;
}

export function signIn(email, password) {
  return signInWithEmailAndPassword(auth, email, password)
    .catch(error => Promise.reject({ ...error, message: null }));
}

export async function signUp(displayName, email, password) {
  try {
    await createUserWithEmailAndPassword(auth, email, password);
    await updateProfile(auth.currentUser, { displayName });
    await reload(auth.currentUser);
    debugger;
  } catch (error) {
    return Promise.reject({ ...error, message: null });
  }
}

export async function logout() {
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('role');
  window.localStorage.clear();
  await signOut(auth);
  window.location = '/';
}

export function sendResetPasswordEmail(email) {
  return sendPasswordResetEmail(auth, email);
}

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export function useDoc(options) {
  const [loading, setLoading] = useState(true);
  const [docRef, setDocRef] = useState(null);
  const [data, _setData] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setDocDebounce = useCallback(debounce((obj) => setDoc(docRef, JSON.parse(JSON.stringify(obj)), { merge: true }), 1000), [docRef]);

  useEffect(() => {
    setDocRef(doc(db, options.name, options.id));
  }, [options.name, options.id]);

  useEffect(() => {
    if (!docRef) {
      _setData(null);
      setLoading(false);
      return;
    }
    setLoading(true);
    getDoc(docRef).then((docSnap) => {
      _setData(docSnap.data());
      setLoading(false);
    });
    const unsub = onSnapshot(docRef, (d) => {
      const dd = d.data();
      _setData((_d) => {
        if (!_d) return dd;
        return dd.$v > _d.$v ? dd : _d
      });
    });
    return () => {
      unsub();
      _setData(null);
    }
  }, [docRef]);

  const setData1 = (dd1 = {}) => {
    if (!docRef) return;
    if (dd1) {
      const obj = { ...JSON.parse(JSON.stringify(dd1)), $v: (new Date()).getTime() };
      setDocDebounce(obj);
      _setData({ ...data, ...obj });
    }
  }

  const bind = (name, { emptyValue = null } = {}) => {
    let value = get(data, name, null);
    if ([undefined, null].includes(value)) {
      value = emptyValue;
    }
    return {
      value,
      onChange: (val) => {
        let v = null;
        if (val && typeof val === 'object' && 'target' in val) {
          if ('type' in val.target && ['checkbox'].includes(val.target.type.trim().toLowerCase())) {
            v = val.target.checked;
          } else {
            v = val.target.value;
          }
        } else {
          v = val;
        }
        let obj = set({ ...data }, name, v);
        obj = JSON.parse(JSON.stringify(obj));
        setData1({ ...data, ...obj });
      }
    }
  }

  return { data, setData: setData1, bind, loading };
}


function getUniqId() {
  return ("K" + Math.random().toString(16).slice(2)).toUpperCase();
}

export const uploadFile = (file, path = 'upload', callback) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("path", path);
  callback({ progress: 0 });
  return api.post('upload', formData)
    .then(res => {
      callback({ progress: 100 });
      callback(res.body);
      return res;
    });
  const storageRef = ref(storage, path + '/' + getUniqId());
  const uploadTask = uploadBytesResumable(storageRef, file);
  uploadTask.on('state_changed',
    (snapshot) => {
      const progress = Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      callback({ progress });
    },
    (error) => {
      console.error(error);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        callback({ url });
      });
    }
  );
  return uploadTask;
}



const DocsCache = JSON.parse(localStorage.getItem('DocsCache')) || {};
export function useAtomDoc(options, cacheData = null) {
  const [loading, setLoading] = useState(true);
  const [changed, setChanged] = useState(false);
  const [data, _setData] = useState(cacheData);
  const api = useApi();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const save = obj => {
    setLoading(true);
    return api.post('atom/set-data', { name: options.name, version: options.version, data: obj })
      .then(result => {
        if (options && options.cache) {
          DocsCache[options.name + '@' + options.version] = result;
          localStorage.setItem('DocsCache',JSON.stringify(DocsCache));
        }
        if (options.useApplyToSave) {
          _setData(result);
        }
      })
      .finally(() => {
        setLoading(false);
        setChanged(false);
      })
  }
  const setDocDebounce = useCallback(debounce(save, 1000), [options.name, options.version]);


  const loadData = () => {
    if (cacheData) {
      setChanged(false);
      setLoading(false);
      _setData(cacheData);
      return;
    }

    if (options && options.cache && false) {
      if (Object.keys(DocsCache).includes(options.name + '@' + options.version)) {
        _setData(DocsCache[options.name + '@' + options.version]);
        setChanged(false);
        setLoading(false)
        return;
      }
    }

    setLoading(true);
    return api.get('atom/get-data', { name: options.name, version: options.version })
      .then((data) => {
        _setData(data);
        if (options && options.cache) {
          DocsCache[options.name + '@' + options.version] = data;
          localStorage.setItem('DocsCache',JSON.stringify(DocsCache));
        }
        setChanged(false);
        return data;
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    loadData();
  }, [options.name, options.version]);

  const setData1 = (dd1 = {}) => {
    if (dd1) {
      const obj = { ...JSON.parse(JSON.stringify(dd1)), $v: (new Date()).getTime() };
      if (!options.useApplyToSave) {
        setDocDebounce(obj);
      }
      _setData({ ...data, ...obj });
    }
  }

  const apply = () => save(data);
  const cancel = () => loadData();

  const bind = (name, { emptyValue = null } = {}) => {
    let value = get(data, name, null);
    if ([undefined, null].includes(value)) {
      value = emptyValue;
    }
    return {
      value,
      onChange: (val) => {
        let v = null;
        if (val && typeof val === 'object' && 'target' in val) {
          if ('type' in val.target && ['checkbox'].includes(val.target.type.trim().toLowerCase())) {
            v = val.target.checked;
          } else {
            v = val.target.value;
          }
        } else {
          v = val;
        }
        let obj = set({ ...data }, name, v);
        obj = JSON.parse(JSON.stringify(obj));
        setChanged(true);
        setData1({ ...data, ...obj });
      }
    }
  }

  return { data, setData: setData1, bind, loading, apply, cancel, changed };
}