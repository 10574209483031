import { get, set } from 'lodash';
import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './Input.css';
import MenuConfig from './MenuConfig';
import { uploadFile } from '../Firebase/Firebase';
import InputBS from './InputBS';
import WTabs from 'com/WTabs/WTabs';

function Input(props) {
  return <InputText {...props} />
}

function InputAuto (props) {
  let { item, role, config } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  role = `${role}`.trim().toLowerCase();
  if (`${config.type}`.trim().toLowerCase() === 'tabs') config.inForm = true;
  if ((role === 'filter' && !config.inFilter)) return null;
  if ((role === 'table' && !config.inTable)) return null;
  if ((role === 'form' && !config.inForm)) return null;
  let Com = null;
  if ((role === 'filter' && config.inFilter !== true)) Com = config.inFilter;
  if ((role === 'table' && config.inTable !== true)) Com = config.inTable;
  if ((role === 'form' && config.inForm !== true)) Com = config.inForm;
  if (Com) return <Com {...props} />;


  switch (`${config.type}`.trim().toLowerCase()) {
    case 'text':
      switch (role) {
        case 'filter':
          return <Input.Text label={config.title} {...item.bind(config.key)} />
        case 'table':
          return item[config.key] || '';
        default:
          return <Input.Text label={config.title} {...item.bind(config.key)} />
      }
    case 'date':
      switch (role) {
        case 'filter':
          return <Input.Date label={config.title} {...item.bind(config.key)} />
        case 'table':
          return item[config.key] || '';
        default:
          return <Input.Date label={config.title} {...item.bind(config.key)} />
      }
    case 'photo':
    case 'image':
      switch (role) {
        case 'filter':
          return null;
        case 'table':
          return item[config.key] ? <img src={item[config.key]} alt="" style={{ width: 40, height: 40 }} /> : null;
        default:
          return <Input.Image label={config.title} {...item.bind(config.key)} />
      }
    case 'tabs':
      if (role === 'form') {
        return (
          <WTabs value={tabIndex} onChange={handleTabChange} tabs={config.tabs.map((tab, i) => ({
            label: tab.title,
            value: i,
            content: <div className='row'>{tab.content.sortBy('indexForm').map(col => <div className={col.formClass || 'col-12'} key={col.key}><Input.Auto role='form' item={item} config={col} /></div>)}</div>
          }))}
          />
        )
      }
      return null;
    default:
      switch (role) {
        case 'filter':
          return <Input.Text label={config.title} {...item.bind(config.key)} />
        case 'table':
          return item[config.key] || '';
        default:
          return <Input.Text label={config.title} {...item.bind(config.key)} />
      }
  }
}

Input.Auto = InputAuto;
Input.Text = InputText;
Input.Prix = InputPrix;
Input.Date = InputDate;
Input.Textarea = InputTextarea;
Input.Password = InputPassword;
Input.Select = InputSelect;
Input.Select2 = InputSelect2;
Input.Color = InputColor;
Input.List = InputList;
Input.TextEditor = InputTextEditor;
Input.Image = InputImage;

export function InputPrix(props) {
  return <Input.Text type="number" suffix={props.unite || 'DT'} {...props} /> ;
}

export function InputText(props) {
  return (
    <InputBS {...props} />
    // <FormItem {...props} validation={validation}>
    //   <input className="form-control" type={type || 'text'} name={name} value={value || ''} onChange={onChange} placeholder={placeholder} />
    // </FormItem>
  );
}

function getUniqId() {
  return ("K" + Math.random().toString(16).slice(2)).toUpperCase();
}

export function InputImage({ name, height = 100, value, onChange, onStateChange, validation, style, ...props }) {
  const [state, setState] = useState({ uploading: false, progress: 0, url: value || null });
  const clickupload = () => {
    var input = document.createElement('input');
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      if (input.files.length) {
        setState({ uploading: true, progress: 0, url: null });
        uploadFile(input.files[0], name ? 'upload-' + name : 'upload', (result) => {
          const { progress, url } = result;
          setState({ uploading: !url, progress, url });
          if (url && onChange) {
            onChange(url);
          }
        });
      }
    }
    setTimeout(() => {
      input.click();
    }, 100);
  }
  return (
    <FormItem {...props} validation={validation}>
      {state.uploading ? (
        <div className='input-img bg-input' style={{ ...style, height }}>
          <span>{state.progress} %</span>
        </div>
      ) : (
        state.url ?
          (
            <div className='input-img bg-input input-img-ready' style={{ ...style, height }}>
              <span className="material-icons" onClick={() => {
                setState({ ...state, url: null });
                if (onChange) onChange(null);
              }}>close</span>
              <img src={state.url} alt="img" />
            </div>
          ) : (
            <div className='input-img bg-input' style={{ ...style, height }} onClick={clickupload}>
              <span className="material-icons">cloud_upload</span>
            </div>
          )
      )}
    </FormItem>
  );
}

export function InputTextEditor({ type, name, value, onChange, validation, ...props }) {
  return (
    <FormItem {...props} validation={validation}>
      <CKEditor
        editor={ClassicEditor}
        data={value || ''}
        onChange={(event, editor) => {
          const texte = editor.getData();
          if (onChange) onChange(texte);
        }}
      />
    </FormItem>
  );
}

export function InputList({ type, name, value, onChange, children, validation, panel, panelTitle, ...props }) {
  const fn = typeof children === 'function' ? children : (item) => <Input.Text style={{ width: '100%' }} {...item.bind('value')} />;
  let list = (Array.isArray(value) ? value : [])
  const initItem = (item) => {
    item.set = (obj) => {
      Object.assign(item, obj);
      onChange([...list]);
    };
    item.bind = (name, { emptyValue = null } = {}) => {
      let value = get(item, name, null);
      if ([undefined, null].includes(value)) {
        value = emptyValue;
      }
      return {
        value,
        onChange: (val) => {
          let v = null;
          if (typeof val === 'object' && 'target' in val) {
            if ('type' in val.target && ['checkbox'].includes(val.target.type.trim().toLowerCase())) {
              v = val.target.checked;
            } else {
              v = val.target.value;
            }
          } else {
            v = val;
          }
          set(item, name, v);
          onChange([...list]);
        }
      }
    }
    return item;
  };

  list = list.map(initItem);

  if (panel) {
    return (
      <FormItem {...props} validation={validation}>
        <div className='input-list-1'>
          <div className='input-list input-list-panel'>
            {list.map((item, i) => {
              return (
                <div key={i} className="card text-dark bg-light input-list-panel-item">
                  <div className="card-header">
                    <button className='btn btn-secondary btn-xs' onClick={() => item.set({ $open: !item.$open })}>
                      {item.$open ? <span className="material-icons">expand_less</span> : <span className="material-icons">expand_more</span>}
                    </button>
                    <span style={{ flex: 1 }}>{i + 1} {typeof panelTitle === 'function' ? panelTitle(item) : panelTitle}</span>
                    <button className='btn btn-secondary btn-xs' onClick={() => onChange([...list.filter(elm => elm !== item)])}><span className="material-icons">clear</span></button>
                  </div>
                  {item.$open ? (
                    <div className="card-body">
                      {fn(item)}
                    </div>
                  ) : null}
                </div>
              )
            })}
          </div>
          <button className='btn btn-xs' onClick={() => onChange([...list, {}])}><span className="material-icons">add</span> <span>Ajouter</span></button>
        </div>
      </FormItem>
    );
  }

  return (
    <FormItem {...props} validation={validation}>
      <div className='input-list-1'>
        <div className='input-list notpanel'>
          {list.map((item, i) => {
            return (
              <div key={i}>
                <span>{i + 1}</span>
                <div>
                  {fn(item)}
                </div>
                <button className='btn btn-sm btn-danger' onClick={() => onChange([...list.filter(elm => elm !== item)])} >
                  <span className="material-symbols-outlined">delete</span>
                </button>
              </div>
            )
          })}
        </div>
        <button className='btn btn-secondary' onClick={() => onChange([...list, {}])}> <span className="material-icons">add</span> </button>
      </div>
    </FormItem>
  );
}

export function InputColor(props) {
  return <InputBS {...props} type="color" />
  // return (
  //   <FormItem {...props} validation={validation}>
  //     <div className='form-h-flex'>
  //       <input className="form-control color-input" type="color" name={name} value={value} onChange={onChange} />
  //       <input className="form-control" type="text" name={name} value={value} onChange={onChange} />
  //     </div>
  //   </FormItem>
  // );
}


export function InputTextarea(props) {
  return <InputBS {...props} type="textarea" />
  // return (
  //   <FormItem {...props} validation={validation}>
  //     <textarea className="form-control" rows={rows} name={name} value={value} onChange={onChange} />
  //   </FormItem>
  // );
}


export function InputSelect(props) {
  return <InputBS {...props} type="select" />;
}

export function InputDate(props) {
  return <InputBS {...props} type="date" />;
}

export function InputSelect2(props) {
  return <InputBS {...props} type="select2" />;
}

export function InputPassword(props) {
  return <InputBS {...props} type="password" />;
  // return (
  //   <FormItem {...props} nextContent={props.children} validation={validation}>
  //     <input className="form-control" type="password" name={name} value={value} onChange={onChange} />
  //   </FormItem>
  // );
}

export function InputLink(props) {
  return <InputBS {...props} type="link" />;
}

export function FormItem({ children, label, nextContent, validation, style, width }) {
  return (
    <div className={`form-item ${validation ? validation.type : ''}`} style={{ width: width, ...style }}>
      {label ? <label>{label}</label> : null}
      <div className="form-item-control">
        {children}
      </div>
      {validation && validation.message ? <div className="form-item-feedback">{validation.message}</div> : null}
      {nextContent}
    </div>
  )
}

export function ConfigInput({ children, label, description, view }) {
  const [mode, setMode] = useState('view');
  return (
    <div className={`form-item-config`}>
      <div className='form-item-config-labels'>
        <span className="material-icons">arrow_forward_ios</span>
        <div>
          <label>{label}</label>
          <small>{description}</small>
        </div>
      </div>
      <div className="form-item-config-control">
        {mode === 'view' ? view : children}
      </div>
      <div>
        {
          mode === 'view' ? (
            <button className='btn btn-primary' onClick={() => setMode('edit')}>Modifier</button>
          ) : (
            <button className='btn btn-primary' onClick={() => setMode('view')}>Enregistrer</button>
          )
        }
      </div>
    </div>
  )
}

ConfigInput.Text = ({ value, label, description, style, ...args }) => {
  return (
    <ConfigInput label={label} description={description} view={<pre>{value}</pre>}>
      <Input.Text value={value} style={{ width: '100%', ...style }} {...args} />
    </ConfigInput>
  )
}

ConfigInput.Textarea = ({ value, label, description, style, ...args }) => {
  return (
    <ConfigInput label={label} description={description} view={<pre>{value}</pre>}>
      <Input.Textarea value={value} style={{ width: '100%', ...style }} {...args} />
    </ConfigInput>
  )
}

ConfigInput.Select = ({ value, label, description, style, ...args }) => {
  return (
    <ConfigInput label={label} description={description} view={<pre>{value}</pre>}>
      <Input.Select value={value} style={{ width: '100%', ...style }} {...args} />
    </ConfigInput>
  )
}

ConfigInput.Color = ({ value, label, description, style, ...args }) => {
  return (
    <ConfigInput label={label} description={description} view={<span className='input-color-view' style={{ backgroundColor: value }} ></span>}>
      <Input.Color value={value} style={{ width: '100%', ...style }} {...args} />
    </ConfigInput>
  )
}

ConfigInput.List = ({ value, label, description, view, viewKey, viewItem, style, ...args }) => {
  const generateView = () => {
    return (
      <ul>
        {(Array.isArray(value) ? value : []).map((elm, i) => (<li key={i}>{(viewItem && viewItem(elm)) || (elm && elm[viewKey || Object.keys(elm)[0]])}</li>))}
      </ul>
    )
  };
  return (
    <ConfigInput label={label} description={description} view={view || generateView()}>
      <Input.List value={value} style={{ width: '100%', ...style }} {...args} />
    </ConfigInput>
  )
}

ConfigInput.TextEditor = ({ value, label, description, style, ...args }) => {
  return (
    <ConfigInput label={label} description={description} view={<div dangerouslySetInnerHTML={{ __html: value }} />}>
      <Input.TextEditor value={value} style={{ width: '100%', ...style }} {...args} />
    </ConfigInput>
  )
}

ConfigInput.MenuConfig = ({ value, label, description, style, onChange, ...args }) => {
  return (
    <ConfigInput label={label} description={description}>
      <MenuConfig value={value} onChange={onChange} />
    </ConfigInput>
  )
}

ConfigInput.Image = ({ label, description, view, value, height = 100, ...args }) => {
  const showImages = () => {
    if (!value) return null;
    return (
      <div style={{ display: 'flex' }}>
        <div className='input-img bg-input input-img-ready' style={{ height }}>
          <img src={value} alt="img" />
        </div>
      </div>
    )
  }
  return (
    <ConfigInput label={label} description={description} view={view || showImages()}>
      <Input.Image value={value} height={height} {...args} />
    </ConfigInput>
  )
}

const ConfigInputImages = ({ label, description, view, value, height = 100, onChange, ...args }) => {
  let [list, setList] = useState([...value]);
  useEffect(() => {
    if (Array.isArray(value)) setList([...value]);
  }, [value]);
  const showImages = () => {
    if (!value) return null;
    return (
      <div style={{ display: 'flex', gap: 15, flexWrap: 'wrap' }}>
        {
          list.filter(item => item.url).map((item, i) => (
            <div key={i} className='input-img bg-input input-img-ready' style={{ height }}>
              <img src={item.url} alt="img" />
            </div>
          ))
        }
      </div>
    )
  }

  const initItem = (item) => {
    item.key = item.key || getUniqId();
    item.onChange = (url) => {
      if (url) {
        item.url = url;
        setList([...list.filter(elm => elm.url)]);
        if (onChange) onChange(list.filter(elm => elm.url));
      } else {
        setList([...list.filter(elm => elm !== item)]);
        if (onChange) onChange(list.filter(elm => elm !== item));
      }
    }
    return item;
  }

  list = list.map(initItem);
  const nbr = list.filter(elm => !elm.url).length;
  useEffect(() => {
    if (!nbr) {
      setList([...list, initItem({ url: null })])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nbr]);

  return (
    <>
      <ConfigInput label={label} description={description} view={view || showImages()}>
        <div style={{ display: 'flex', gap: 15, flexWrap: 'wrap' }}>
          {list.map((item, i) => (<Input.Image key={item.key} value={item.url} onChange={item.onChange} height={height} {...args} />))}
        </div>
      </ConfigInput>
    </>
  )
}

ConfigInput.Images = ConfigInputImages;

export function useForm() {
  const [values, _setValues] = useState({});
  let [errors, setErrors] = useState([]);

  const bind = (name, { emptyValue = null } = {}) => {
    let value = get(values, name, null);
    if ([undefined, null].includes(value)) {
      value = emptyValue;
    }
    return {
      validation: errors.find(err => err.name === name),
      value,
      onChange: (val) => {
        let v = null;
        if (val && typeof val === 'object' && 'target' in val) {
          if ('type' in val.target && ['checkbox'].includes(val.target.type.trim().toLowerCase())) {
            v = val.target.checked;
          } else {
            v = val.target.value;
          }
        } else {
          v = val;
        }
        set(values, name, v);
        _setValues({ ...values });
      }
    }
  }
  // const bind = (name) => {
  //   return {
  //     validation: errors.find(err => err.name === name),
  //     value: values[name] || '',
  //     onChange: (e) => {
  //       let value = e?.target?.value || '';
  //       _setValues(v => ({ ...v, [name]: value }));
  //     }
  //   };
  // }

  const setError = (name, message = '') => {
    errors = errors.filter(err => err.name !== name);
    errors.push({ name, message, valid: false });
    setErrors(errors);
  }

  const isValid = () => {
    return errors.length === 0;
  }

  const resetErrors = () => {
    errors = [];
    setErrors(errors);
  }

  const setValue = (name, value) => {
    values[name] = value;
    _setValues(v => ({ ...v, [name]: value }));
  }

  const extractErrors = (obj, list = []) => {
    if (obj && obj._validation_) {
      Object.entries(obj._validation_)
        .filter(([name, value]) => !['_isValid_', '_validation_'].includes(name))
        .forEach(([name, value]) => {
          list.push({ name, ...value });
        });
    }
    Object.entries(obj)
      .filter(([name, value]) => !['_isValid_', '_validation_'].includes(name) && typeof value === 'object')
      .forEach(([name, value]) => {
        list = extractErrors(value, list);
      });
    return list;
  }

  const setValues = (data) => {
    _setValues({ ...data });
    setErrors(extractErrors({ ...data }));
  }

  return { bind, values, setError, isValid, errors, resetErrors, setValue, setValues };
}

export default Input
