import React from 'react'
import Input from '../../Imput/Input';
import Atom, { defineAtomElement } from '../Atom';

function Cols({ item, atomOptions }) {
  return (
    <div className='AtomCols' style={{ gap: item.gap, flexWrap: item.wrap }}>
      {(item.list || []).map((col, i) => {
        return (
          <div style={{ flex: col.flex, minWidth: col.minWidth, maxWidth: col.maxWidth }} key={i}>
            <Atom {...item.$bindTo(col, 'list')} editMode={item.editMode} atomOptions={{ atomOptions, l: (atomOptions?.l || 0) + 1 }} />
          </div>
        )
      })}
    </div>
  )
}

function ColsConfig({ item }) {
  return (
    <div className='row'>
      <div className='col-6'>
        <Input label="GAP" {...item.$bind('gap')} />
      </div>
      <div className='col-6'>
        <Input.Select options={['wrap', 'nowrap']} emptyOption="" label="Wrap" {...item.$bind('wrap')} />
      </div>
      <div className='col-12'>
        <table className='bordered'>
          <tr>
            <th>N°</th>
            <th>Flex</th>
            <th>Min-Width</th>
            <th>Max-Width</th>
          </tr>
          {
            (item.list || []).map((col, i) => {
              return (
                <tr>
                  <th className='nowrap'>Col {i + 1}</th>
                  <td><Input {...item.$bindTo(col, 'flex')} /></td>
                  <td><Input {...item.$bindTo(col, 'minWidth')} /></td>
                  <td><Input {...item.$bindTo(col, 'maxWidth')} /></td>
                </tr>
              )
            })
          }
        </table>
        <button onClick={() => {
          item.list.push({ flex: 1, list: [] });
          item.$set();
        }}>add</button>
      </div>
    </div>
  )
}

defineAtomElement({
  name: 'cols',
  title: 'Columns',
  default: {
    list: [{ flex: 1, list: [] }]
  },
  Component: Cols,
  Config: ColsConfig
});



export default Cols
