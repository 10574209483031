import React from 'react';
import useApi from '../../API/api';
import FormRender, { FormBuilder } from '../../FormBuilder/FormBuilder';
import { useForm } from '../../Imput/Input';
import { defineAtomElement } from './../Atom';

function AtomFormBuilder({ item }) {
    const form = useForm();
    const { post } = useApi();
    const submit = () => {
        post('data', form.values)
            .then(result => {
                form.setValues(result);
            })
    }
    return (
        <div>
            <h1>FormBuilder</h1>
            <FormRender form={form} schema={item.list}/>
            <pre>{JSON.stringify(form.values, null, 2)}</pre>
            <pre>{JSON.stringify(form.errors, null, 2)}</pre>
            <button onClick={submit} >Envoyer</button>
        </div>
    )
}

function AtomFormBuilderConfig({ item }) {
    return (
        <div>
            <FormBuilder {...item.$bind('list', { emptyValue: [] })} />
        </div>
    )
}

defineAtomElement({
    name: 'FormBuilder',
    title: 'FormBuilder',
    default: {},
    Component: AtomFormBuilder,
    Config: AtomFormBuilderConfig
});

export default AtomFormBuilder;
