import { Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, LinearProgress, ListItemIcon, ListItemText, Menu, MenuItem, Pagination, Paper, styled, Switch, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import useApi from '../API/api';
import Input from '../Imput/Input';
import createBindFunction from './createBindFunction';
import useBindableState, { Debounce, Draft } from './useBindableState';
import { useReactToPrint } from 'react-to-print';
import { get, set } from 'lodash';


// interface useCrudOptions {
//   autoLoad: Boolean;
//   length: Number;
//   filter: Object;
// }


// eslint-disable-next-line no-extend-native
Array.prototype.sortBy = function (name) {
  let list = [...this];
  list = list.map((item, i) => {
    ['indexForm', 'indexFilter', 'indexTable', name].forEach((col) => {
      if (!(col in item)) {
        item[col] = i;
      }
    });
    return item;
  });
  list.sort((a, b) => {
    const v1 = a[name] || 0;
    const v2 = b[name] || 0;
    if (v1 > v2) return 1;
    if (v1 < v2) return -1;
    return 0;
  });
  return list;
};

const defaultOptions = {
  autoLoad: true,
  length: 10,
  id: 'id',
  filter: {},
};

export function useCrud(apiName, options, props) {
  options = { ...defaultOptions, ...options };
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [nbrPages, setNbrPage] = useState(0);
  const [page, setPage] = useState(1);
  const [infos, setInfos] = useState({});
  const selectObject = useBindableState({});
  const filter = useBindableState({});
  const [newItem, setNewItem] = useState(null);
  const checked = useBindableState({ list: [] });

  if (!Array.isArray(checked.list)) checked.list = [];

  const _initItem = (item, useNewItem = false) => {
    item.$checked = !!checked.list.find(el => el[options.id] === item[options.id]);
    item.set = (obj = {}, accept = false) => {
      Object.assign(item, obj || {});
      if (accept) {
        item.$savedData = { ...item };
        delete item.$savedData.$savedData;
      }
      if (useNewItem === true) {
        setNewItem({ ...newItem, ...item });
      } else {
        setList([...list]);
      }
    };

    item.check = (e) => {
      let value = !item.$checked;
      if (e && e.target && 'checked' in e.target) {
        value = e.target.checked;
      } else if ([true, false].includes(e)) {
        value = e;
      }

      if (value) {
        if (!checked.list.find(el => el[options.id] === item[options.id])) {
          checked.list.push(item);
          checked.set();
        }
      } else {
        if (checked.list.find(el => el[options.id] === item[options.id])) {
          checked.list = checked.list.filter(el => el[options.id] !== item[options.id]);
          checked.set();
        }
      }
    }

    item.callAction = async (action, obj) => {
      try {
        item.set({ ...obj, $loading: true });
        const data = await api.post(apiName + '/' + action, { item });
        if (data && data.item) {
          item.set({ ...data.item, $key: new Date(), $loading: false }, true);
        } else {
          item.set({ $loading: false });
        }
        return data && data.success;
      } catch (error) {
        item.set({ $loading: false });
        console.log(error);
      }
      return false;
    };

    item.save = async (obj) => {
      const result = await item.callAction('save', obj);
      loadData();
      if (props && props.onSave) await props.onSave();
      return result;
    };

    item.delete = async () => {
      const result = await item.callAction('delete');
      loadData();
      return result;
    };

    item.load = async () => {
      return await item.callAction('loaditem');
    };

    item.bind = createBindFunction(item, {
      data: item,
      update: item.set,
    });

    item.reset = () => {
      item.set({ ...item.$savedData });
      return item;
    };

    item.select = (name = 'edit') => {
      selectObject.set({ [name]: item });
      return item;
    };

    item.close = (name = 'edit') => {
      selectObject.set({ [name]: null });
      return item;
    };

    return item;
  };

  const loadData = async (_page, loadDataOptions) => {
    loadDataOptions = { ...loadDataOptions };
    if (_page === false) return;
    if (!_page) _page = page;
    if (!loadDataOptions.privateLoading) {
      setLoading(true);
    }
    try {
      const _filter = await filter.get();
      const result = await api.post(apiName + '/load', {
        page: _page,
        length: options.length,
        filter: { ..._filter, ...options.filter },
      });
      if (loadDataOptions.privateLoading) {
        return result;
      }
      if (result && Array.isArray(result.data)) {
        setList(
          result.data
            .map((item) => ({ ...item, $savedData: { ...item } }))
            .map(_initItem),
        );
        setTotal(parseInt(result.total));
        setNbrPage(parseInt(result.nbrPage));
        setPage(parseInt(_page));
      }
      if (result) {
        delete result.data;
        setInfos(result);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (options.autoLoad) {
      loadData(1);
    }
  }, []);

  const createNewItem = (data = {}) => {
    const item = _initItem({ ...data, $newItem: true }, true);
    item.select('edit');
    return item;
  };

  return {
    list: list.map(_initItem),
    loading,
    loadData,
    total,
    nbrPages,
    page,
    infos,
    filter,
    createNewItem,
    checked,
    selected: selectObject,
  };
}


export function CRUDLoadAllData({ open, children, loadData, onReady }) {
  const state = useBindableState({ list: [], page: 0, total: 0 });

  useEffect(() => {
    const _loadData = async (contine) => {
      contine = contine || 1;
      state.list = state.list || [];
      const result = await loadData(contine, { privateLoading: true });
      if (result && Array.isArray(result.data)) {
        state.list.push(...result.data);
        state.set({ complete: false, page: contine, total: result.nbrPage });
        if (result.nbrPage > contine) {
          setTimeout(() => _loadData(contine + 1), 1);
        } else {
          state.set({ complete: true, page: contine, total: result.nbrPage });
        }
      }
    }
    if (open) {
      _loadData();
    }
  }, [open]);

  useEffect(() => {
    if (state.complete) {
      if (onReady) onReady(state.list);
    }
  }, [state.complete]);

  if (state.complete) {
    return children(state.list);
  }

  return (
    <div>
      Page : {state.page} / {state.total}
      <LinearProgress variant="determinate" value={state.total > 0 ? (state.page * 100 / state.total) : 0} />
    </div>
  )
}



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  }
}));

const StyledMenuButtom = styled(Button)(({ theme }) => ({
  '&': {
    minWidth: 'auto',
    borderColor: '#9b9b9b1f',
    color: '#000000a6',
    padding: 2,
    borderRadius: 5,
    background: '#d7d7d72b',
    display: 'flex',
    gap: 2,
    alignItems: 'center'
  },
  '&:hover': {
    minWidth: 'auto',
    padding: 2
  },
  '& .menubuttonlabel': {
    fontSize: 9,
    paddingRight: 2
  }
}));

export function TableCRUDTable({ rows, role, colmuns, idColName, actions, checked, nbrPages, page, total, checkedActions, loadData, hideOptions, getTrProps }) {
  if (!Array.isArray(actions)) actions = [];
  if (!Array.isArray(colmuns)) colmuns = [];
  if (role) {
    colmuns = colmuns.filter(col => col.inTable);
  }
  colmuns = colmuns.map(col => {


    if (col.type) {
      switch (`${col.type}`.toLowerCase()) {
        case 'prix':
          col.align = 'right';
          col.minsize = true;
          col.render = (row) => <div style={{ whiteSpace: 'nowrap', color: typeof col.color === 'function' ? col.color(row) : col.color }}>{parseFloat(row[col.key]).toFixed(2)}0 <sup><small>DT</small></sup></div>
          break;
        default:
          break;
      }
    }
    if (col.align) {
      col.args = { ...col.args };
      col.args.style = { ...col.args.style, textAlign: col.align };
      col.titleArgs = { ...col.titleArgs };
      col.titleArgs.style = { ...col.titleArgs.style, textAlign: col.align };
    }

    if (col.minsize) {
      col.args = { ...col.args };
      col.args.style = { ...col.args.style, width: 1, whiteSpace: 'nowrap' };
      col.titleArgs = { ...col.titleArgs };
      col.titleArgs.style = { ...col.titleArgs.style, width: 1, whiteSpace: 'nowrap' };
    }
    return col;
  })
  return (
    <>
      <TableContainer component={Paper} sx={{ minHeight: 300 }} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {checked ? (<StyledTableCell component="th" style={{ width: 1 }}></StyledTableCell>) : null}
              {colmuns.map((col, i) => <StyledTableCell key={i} component="th" {...col.titleArgs} >{col.title}</StyledTableCell>)}
              {actions.length ? (<StyledTableCell component="th" style={{ width: 1 }}></StyledTableCell>) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow key={row[idColName || 'id']} {...(typeof getTrProps === 'function' ? getTrProps(row, index) : getTrProps)} >
                {checked ? (<StyledTableCell component="th" style={{ width: 1, padding: 0 }}><Checkbox checked={row.$checked} onChange={row.check} /></StyledTableCell>) : null}
                {colmuns.map((col, i) => <StyledTableCell key={i} {...col.args} >{col.render ? col.render(row, index) : (role ? <Input.Auto role={role} item={row} config={col} /> : row[col.key])}</StyledTableCell>)}
                {actions.length ? (<StyledTableCell component="th" align='right' style={{ padding: '0 5px' }}><div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>{actions.map((action, i) => {
                  return <TableCRUDButtonAction key={i} item={row} action={action} />;
                })}</div></StyledTableCell>) : null}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {
        page || checked ? (
          <div style={{ display: 'flex', alignItems: 'center', margin: '15px 0', gap: 15 }}>
            {checked && checked.list.length ? checkedActions : null}
            {page ? (<Pagination page={page} count={nbrPages} onChange={(e, p) => loadData(p)} color="primary" />) : null}
            <span style={{ flex: 1 }} />
            {page ? (
              <>
                <Chip label={`Page: ${page}/${nbrPages}`} />
                <Chip label={`Total: ${total}`} />
              </>
            ) : null}
            {hideOptions ? null : (
              <div>
                <button className="btn btn-sm btn-outline-secondary" style={{ borderRadius: 20 }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <Icon>more_vert</Icon>
                </button>
                <ul className="dropdown-menu dropdown-menu-end">
                  {checked && checked.list.length ? <li><span className="dropdown-item" >Télécharger Selectionnés (CSV)</span></li> : null}
                  {checked && checked.list.length ? <li><span className="dropdown-item" >Imprimer Selectionnés</span></li> : null}
                  {checked && checked.list.length ? <li><hr className="dropdown-divider" /></li> : null}
                  <li><span className="dropdown-item" >Télécharger tout (CSV)</span></li>
                  <li><span className="dropdown-item" >Imprimer tout</span></li>
                  {checked && checked.list.length ? <li><hr className="dropdown-divider" /></li> : null}
                  {checked && checked.list.length ? <li><span className="dropdown-item" >Supprimer Selectionnés</span></li> : null}
                </ul>
              </div>
            )}
          </div>
        ) : null
      }

    </>
  )
}


export function TableCRUDButtonAction({ item, action }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = fn => () => {
    setAnchorEl(null);
    if (fn) fn();
  };

  return (
    <>
      <StyledMenuButtom
        onClick={(e) => Array.isArray(action.menu) && action.menu.length ? handleClick(e) : (action.onClick ? action.onClick(item) : item.select(action.action || action.icon))}
        variant="outlined"
        size="small">
        <Icon sx={{ fontSize: 17 }}>{action.icon}</Icon>
        {action.label ? <span className='menubuttonlabel'>{action.label}</span> : null}
      </StyledMenuButtom>
      {Array.isArray(action.menu) && action.menu.length ? (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose()}
        >
          {action.menu.map((elm, i) => {
            return (
              <MenuItem key={i} onClick={handleClose(elm.onClick ? elm.onClick : () => item.select(elm.action || elm.icon))}>
                <ListItemIcon>
                  <Icon sx={{ fontSize: 17 }}>{elm.icon}</Icon>
                </ListItemIcon>
                <ListItemText>{elm.label}</ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
      ) : null}

    </>
  )
}

export function TableCRUDDialog({ item, header, fullWidth, children, onClose, action = 'edit', actions, style, ...props }) {
  const handleClose = fn => (e, reason) => {
    if (['backdropClick'].includes(reason)) {
      return;
    }
    if (typeof onClose === 'function') onClose();
    if (item && item.close && typeof item.close === 'function') item.close(action);
    if (fn) fn();
  }

  const content = elm => (
    <TableCRUDLoading loading={item && item.$loading}>
      {header ? (<DialogTitle className='TableCRUDLoading-DialogTitle'><span>{header}</span> {onClose || (item && item.close) ? <IconButton onClick={handleClose()}><Icon>close</Icon></IconButton> : null} </DialogTitle>) : null}
      <DialogContent style={{ overflowY: 'visible' }} className='TableCRUDLoading-DialogContent'>
        {typeof children === 'function' ? children(elm) : children}
      </DialogContent>
      {Array.isArray(actions) ? (
        <DialogActions className='TableCRUDLoading-actions'>
          {actions.map((action, i) => (<Button key={i} {...action} >{action.label}</Button>))}
        </DialogActions>
      ) : null}
      {typeof actions === 'function' ? (
        <DialogActions className='TableCRUDLoading-actions'>
          {actions(elm).map((action, i) => (<Button key={i} {...action} >{action.label}</Button>))}
        </DialogActions>
      ) : null}
    </TableCRUDLoading>
  )

  return (
    <Dialog
      open={!!item}
      onClose={handleClose()}
      maxWidth="md"
      fullWidth={fullWidth}
      disableEscapeKeyDown
      scroll='body'
      {...props}
      style={{ overflowY: 'visible', ...style }}
    >
      {item && item.bind && typeof item.bind === 'function' ? (
        <Draft  {...item.bind('', { emptyValue: {} })}>
          {content}
        </Draft>
      ) : (item ? content(item) : null)}
    </Dialog>
  )
}

export function TableCRUDLoading({ children, loading, style }) {
  return (
    <div style={{ position: 'relative', ...style }}>
      {loading ? (
        <div style={{
          position: 'absolute',
          zIndex: 1000,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(255,255,255,0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <CircularProgress />
        </div>
      ) : null}
      {children}
    </div>
  )
}

export function TableCRUDFilter({ children, onSearch, onAdd }) {
  return (
    <div style={{ display: 'flex', gap: 15, marginBottom: 15 }}>
      <div style={{ flex: 1, display: 'flex', gap: 15, alignItems: 'flex-end' }}>
        {children}
      </div>
      <div style={{ display: 'flex', gap: 15, alignItems: 'flex-end' }}>
        {onSearch ? <Button variant='outlined' onClick={onSearch}><Icon>search</Icon></Button> : null}
        {onAdd ? <Button variant='contained' onClick={onAdd}><Icon>add</Icon></Button> : null}
      </div>
    </div>
  )
}


export function TableCRUDeleteDialog({ item, children, ...props }) {
  return (
    <TableCRUDDialog
      header={<h2>Supprimer ?</h2>}
      item={item}
      action='delete'
      actions={(elm) => [
        { label: 'Annuler', onClick: () => { elm.reset(); item.close('delete'); } },
        { label: 'Supprimer', onClick: () => elm.delete().then(() => item.close('delete')), variant: 'contained', color: 'error' }
      ]} {...props}>
      {children || <p>Voulez-vous vraiment supprimer cet enregistrement ?</p>}
    </TableCRUDDialog>
  )
}


export function TableCRUPrintDialog({ item, children, onClose, ...props }) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    pageStyle: `
      @page {
        /* size: 80mm 50mm; */
        margin: 20px;
      }

      @media all {
        .pagebreak {
          display: none;
        }
      }

      @media print {
        .pagebreak {
          page-break-before: always;
        }
      }
    `,
    content: () => componentRef.current,
  });
  return (
    <TableCRUDDialog
      item={item}
      action='print'
      header={<h2>Imprimer</h2>}
      actions={() => [
        { label: 'Fermer', onClick: onClose },
        { label: 'Imprimer', onClick: handlePrint, variant: 'contained' }
      ]} {...props}>
      {elm => (
        <div ref={componentRef}>
          {typeof children === 'function' ? children(elm) : children}
        </div>
      )}
    </TableCRUDDialog>
  )
}


export function TableCRUD() {
  const { list, selected, loading, filter, loadData, nbrPages, createNewItem, total, page, checked } = useCrud('module1', { length: 10, id: 'id' });

  const colmuns = [
    { key: 'name', title: 'Name' },
    { key: 'titre', title: 'Titre' },
    { key: 'type', title: 'Type' },
    {
      key: 'active', title: 'Active',
      titleArgs: { style: { width: 1 } },
      args: { style: { padding: 2 } },
      render: (row) => <Switch {...row.bind('active', { switch: true, onChange: row.save, true: 'On', false: 'Off' })} />
    }
  ];

  const actions = [
    { icon: 'print' },
    { icon: 'edit', label: 'Edit' },
    { icon: 'delete', label: 'Delete' },
    {
      icon: 'more_vert',
      menu: [
        { icon: 'edit', label: 'Edit' },
        { icon: 'delete', label: 'Delete' },
      ]
    },
  ];

  return (
    <TableCRUDLoading loading={loading}>
      <h1>Table name list</h1>
      <TableCRUDFilter onSearch={() => loadData()} onAdd={() => createNewItem()}>
        <Debounce {...filter.bind('', { emptyValue: {} })} wait={500} onUpdate={() => loadData()} >
          {(filterDraft) => (
            <>
              <Input.Text label="Name" {...filterDraft.bind('name')} append={{ ...filterDraft.bind('unite', { emptyValue: 'DT' }), options: ['DT', '%', 'Auto'] }} />
              <Input.Text label="Titre" {...filterDraft.bind('titre')} action={{ label: 'Test', onClick: () => console.log("Test click") }} />
              <Input.Select2 label="Type" {...filterDraft.bind('type')} options={["Option 1", 'Option 2']} emptyOption="Tout" />
            </>
          )}
        </Debounce>
      </TableCRUDFilter>
      <TableCRUDTable
        rows={list}
        colmuns={colmuns}
        actions={actions}
        checked={checked}
        loadData={loadData}
        page={page}
        total={total}
        nbrPages={nbrPages}
        checkedActions={
          <button className="btn btn-sm btn-outline-primary" type="button">
            Créer facture
          </button>
        }
      />
      <TableCRUDDialog item={selected.edit} header="Modification" actions={(item) => [
        { label: 'Annuler', onClick: () => { item.reset(); item.close('edit'); } },
        {
          label: 'Enregistrer', variant: 'contained', onClick: () => {
            item.accept();
            selected.edit.save().then(ok => ok ? selected.edit.close() : null);
          }
        }
      ]}>
        {item => (
          <div className='row' style={{ minWidth: 700 }}>
            <div className='col-4'>
              <Input.Text label="Name" {...item.bind('name')} />
            </div>
            <div className='col-4'>
              <Input.Text label="Titre" {...item.bind('titre')} />
            </div>
            <div className='col-4'>
              <Input.Select2 label="Type" {...item.bind('type')} options={["Option 1", 'Option 2']} />
            </div>
          </div>
        )}
      </TableCRUDDialog>
      <TableCRUDeleteDialog item={selected.delete} />
      <TableCRUPrintDialog item={!!selected.print} fullWidth onClose={() => selected.print.close('print')}>
        {item => (
          <CRUDLoadAllData loadData={loadData} open>
            {rows => (
              <div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Titre</TableCell>
                        <TableCell align="right">Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.titre}</TableCell>
                          <TableCell align="right">{row.type}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}

          </CRUDLoadAllData>
        )}
      </TableCRUPrintDialog>
    </TableCRUDLoading >
  );
}

export default useCrud;



