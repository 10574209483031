import { pick, get, set, debounce } from 'lodash';
import { useCallback, useEffect } from 'react';
import useBindableState from './useBindableState';

// interface createBindFunctionOptions {
//   update: Function;
//   getData: Function;
//   data: Object;
// }

const defaultOptions = {
  data: {},
  update: () => {},
  getData: null,
};

// interface BindFunctionOptions {
//   data: Object;
//   native: boolean;
//   switch: boolean;
//   checkbox: boolean;
//   true: any;
//   false: any;
//   onChange: any;
//   emptyValue: any;
// }

const defaultBindOptions = {
  data: null,
  native: false,
  switch: false,
  checkbox: false,
  onChange: null,
  emptyValue: null,
};

function createBindFunction(self, _options) {
  _options = { ...defaultOptions, ..._options };

  const data1 = _options.data || {};
  self.getValue = (name, defaultValue = null) => {
    const v = get(data1, name, defaultValue);
    if(Array.isArray(defaultValue) && !Array.isArray(v)) {
      set(data1, name, defaultValue);
      self.set();
      return get(data1, name, defaultValue);
    }
    return v;
  };

  self.getList = (name, initListItem) => {
    let list = self.getValue(name, []);
    list = list.map((elm, index) => {
      elm.bind = createBindFunction(elm, {
        data: elm,
        update: self.set
      });
      elm.delete = () => {
        list.splice(index, 1);
        set(data1, name, list);
        _options.update(_options.data);
      }
      if (typeof initListItem === 'function') initListItem(elm);
      return elm;
    });
    return list;
  }

  self.get = self.getValue;
  self.pushTo = (name, value) => {
    let v = get(data1, name);
    if (!Array.isArray(v)) {
      set(data1, name, []);
    }
    v = get(data1, name);
    v.push(value);
    self.set();
    return v;
  }

  self.setError = (name, message) => {
    set(_options.data, `_validation_.${name}`, { valid: false, message });
    _options.update(_options.data);
  }

  self.deleteError = (name) => {
    set(_options.data, `_validation_.${name}`, null);
    _options.update(_options.data);
  }

  return (name, options) => {
    options = { ...defaultBindOptions, ...options };
    let args = {};
    let data = options.data || _options.data || {};
    args.value = name ? get(data, name, null) : data;
    if ([undefined, null, ''].includes(args.value)) {
      args.value = options.emptyValue;
    }

    if (options.switch || options.checkbox) {
      if (Object.keys(options).includes('true') && args.value === options.true) {
        args.value = true;
      } else if (Object.keys(options).includes('false') && args.value === options.false) {
        args.value = false;
      }
      args.checked = !!args.value;
    }

    if (data) {
      const nameStatus = name.split('.');
      const lastvarname = nameStatus.pop();
      nameStatus.push('_validation_');
      nameStatus.push(lastvarname);
      args.validation = get(data, nameStatus.join('.'), undefined);
      if (args.validation) {
        args.error = !args.validation.valid;
        if (args.validation.message) {
          args.helperText = args.validation.message;
        }
      }
    }

    if (name) {
      if(options.toInput && typeof options.toInput === 'function') {
        args.value = options.toInput(args.value);
      }
      args.onChange = (e) => {
        let propName = 'value';
        if (options.switch || options.checkbox) {
          propName = 'checked';
        }
        let value =  (e && typeof e === 'object' && 'target' in e) ? e.target[propName] : e;
        if (options.switch || options.checkbox) {
          if (Object.keys(options).includes('true') && value === true) {
            value = options.true;
          } else if (Object.keys(options).includes('false') && value === false) {
            value = options.false;
          }
        }
        if(options.toData && typeof options.toData === 'function') {
          value = options.toData(value);
        }
        set(data, name, value);
        set(_options.data, `_validation_.${name}`, null);
        _options.update(_options.data);
        if (options.onChange) {
          setTimeout(() => options.onChange(),10);
        }
      };
    }

    args.set = async (value) => {
      const d = _options.getData ? await _options.getData() : data;
      if (name) {
        set(d, name, value);
      } else {
        Object.assign(d, value);
      }
      _options.update(d);
    };

    args.get = (name, defaultValue = null) => get(data, name, defaultValue);

    args.bind = createBindFunction(args, {
      ..._options,
      update: args.set,
      data: args.value,
    });

    if (args.value && typeof args.value === 'object' && !Array.isArray(args.value)) {
      args.value.bind = args.bind;
      args.value.set = args.set;
    }

    if (options.native) {
      args = pick(args, ['value', 'onChange', 'error', 'helperText']);
    }

    return args;
  };
}

// interface createSetFunctionOptions {
//   setState: Function;
//   onSet: Function;
//   data: Object;
//   emitOnSetEvent: boolean
// }

const defaultSetOptions = {
  data: {},
  setState: () => {},
  onSet: () => {},
  emitOnSetEvent: true
};

export function createSetFunction(_options) {
  _options = { ...defaultSetOptions, ..._options };
  return (data, options) => {
    return new Promise((resolve) => {
      // Object.assign(_options.data, data);
      _options.setState((s) => {
        const ss = { ...s, ...data };
        setTimeout(() => {
          resolve(ss);
          if (_options.onSet && !(options && options.noOnSetEvent)) _options.onSet(ss);
        }, 10);
        return ss;
      });
    });
  };
}

export function createSetByPathFunction(_options) {
  _options = { ...defaultSetOptions, ..._options };
  return (path, data, options) => {
    return new Promise((resolve) => {
      set(_options.data, path, data);
      _options.setState((s) => {
        const ss = { ...s, ..._options.data };
        setTimeout(() => {
          resolve(ss);
          if (_options.onSet && !(options && options.noOnSetEvent)) _options.onSet(ss);
        }, 10);
        return ss;
      });
    });
  };
}

export function Debounce({ children, value, set, wait, onUpdate }) {
  const onSet = useCallback(debounce((data) => {
      const dd = JSON.parse(JSON.stringify(data));
      set(dd);
      if (onUpdate) onUpdate(dd);
  }, wait || 500), [])
  const state = useBindableState({ ...value }, { onSet });

  useEffect(() => {
      state.set(JSON.parse(JSON.stringify(value)), { noOnSetEvent: true });
  }, [value]);

  return children(state);
}

export function Draft({ children, value, set, onUpdate }) {
  const state = useBindableState({ ...value });

  useEffect(() => {
      state.set(JSON.parse(JSON.stringify(value)), { noOnSetEvent: true });
  }, [value && value.$key]);

  state.accept = () => {
      const dd = JSON.parse(JSON.stringify(state));
      set(dd);
      if (onUpdate) onUpdate(dd);
  }

  return children(state);
}

export default createBindFunction;
