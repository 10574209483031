import AtomPages from 'com/Atom/AtomPages';
import Loading from 'com/Loading';
import useConfig from 'com/utils/useConfig';
import DefaultLayout from 'com/Atom/Layout/Layout';
import React from 'react';

const Welcome = () => {
    const config = useConfig({ name: 'config', version: 'config' });
    if (config.loading) return <Loading loading fullScreen />;
    return (
        <DefaultLayout config={config} >
            <AtomPages name="/welcome" />
        </DefaultLayout>
    );
}

export default Welcome;
