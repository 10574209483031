import { Checkbox, FormControlLabel, Switch } from '@mui/material';
import { defineAtomElement } from 'com/Atom/Atom';
import Input from 'com/Imput/Input';
import { Debounce } from 'com/utils/createBindFunction';
import useConfig from 'com/utils/useConfig';
import useCrud, { TableCRUDDialog, TableCRUDeleteDialog, TableCRUDFilter, TableCRUDLoading, TableCRUDTable } from 'com/utils/useCrud';

function Users() {
    const { list, selected, loading, filter, loadData, nbrPages, createNewItem, total, page } = useCrud('users', { length: 10, id: 'id' });
    const { roles } = useConfig({ name: 'config', version: 'config' });

    const colmuns = [
        { key: 'id', title: 'ID', titleArgs: { style: { width: 1 } } },
        { key: 'login', title: 'Email' },
        { key: 'name', title: 'Nom' },
        {
            key: 'roles', title: 'Roles',
            render: (row) => row.roles.map(role => role.label).join(', ')
        },
        {
            key: 'status', title: 'Active',
            titleArgs: { style: { width: 1 } },
            args: { style: { padding: 2 } },
            render: (row) => <Switch {...row.bind('status', { switch: true, onChange: row.save, true: 'Active', false: 'Desactiver' })} />
        }
    ];

    const actions = [
        { icon: 'edit' },
        { icon: 'delete' },
    ];

    const onRoleCheckChange = (item, role) => (e) => {
        item.roles = Array.isArray(item.roles) ? item.roles : [];
        item.roles = item.roles.filter(r => r.name !== role.name);
        if (e.target.checked) {
            item.roles.push(role);
        }
        item.set();
    }

    return (
        <TableCRUDLoading loading={loading}>
            <TableCRUDFilter onSearch={() => loadData()} onAdd={() => createNewItem()}>
                <Debounce {...filter.bind('', { emptyValue: {} })} wait={500} onUpdate={() => loadData()} >
                    {(filterDraft) => (
                        <>
                            <Input.Text label="Login" {...filterDraft.bind('login')} />
                        </>
                    )}
                </Debounce>
            </TableCRUDFilter>
            <TableCRUDTable
                rows={list}
                colmuns={colmuns}
                actions={actions}
                loadData={loadData}
                page={page}
                total={total}
                nbrPages={nbrPages}
                hideOptions
            />
            <TableCRUDDialog item={selected.edit} header="Modification" actions={(item) => [
                { label: 'Annuler', onClick: () => { item.reset(); item.close('edit'); } },
                {
                    label: 'Enregistrer', variant: 'contained', onClick: () => {
                        item.accept();
                        selected.edit.save().then(ok => ok ? selected.edit.close() : null);
                    }
                }
            ]}>
                {item => (
                    <div className='row' style={{ minWidth: 700 }}>
                        <div className='col-6'>
                            <Input.Text label="Email" {...item.bind('login', { emptyValue: '' })} />
                        </div>
                        <div className='col-6'>
                            <Input.Text label="Nom" {...item.bind('name', { emptyValue: '' })} />
                        </div>
                        <div className='col-6'>
                            <Input.Text label="Mot de passe" {...item.bind('password', { emptyValue: '' })} />
                        </div>
                        <div className='col-6'>
                            <Input.Select label="Active" {...item.bind('status', { emptyValue: '' })} options={[{ label: 'Active', value: 'Active' }, { label: 'Desactiver', value: 'Desactiver' }]} />
                        </div>
                        <div className='col-6'>
                            {Array.isArray(roles) ? roles.map(role => <FormControlLabel key={role.name} label={role.label} onChange={onRoleCheckChange(item, role)} control={<Checkbox checked={Array.isArray(item.roles) && item.roles.map(r => r.name).includes(role.name)} />} />) : null}
                        </div>
                    </div>
                )}
            </TableCRUDDialog>
            <TableCRUDeleteDialog item={selected.delete} />
        </TableCRUDLoading >
    );
}

function UsersConfig({ item }) {
    return (
        <div className='row'>
            <div className='col-12'>
                <Input.Text lable="Title" {...item.bind('title', { emptyValue: '' })} />
            </div>
        </div>
    )
}

defineAtomElement({
    name: 'Users',
    title: 'Users',
    default: {},
    Component: Users,
    Config: UsersConfig
});

export default Users;
