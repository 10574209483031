import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userState } from "../../states";
import useApi from "../API/api";
import { sendResetPasswordEmail, signInWith, signIn, signUp } from './../Firebase/Firebase';

export function useAuthFunctions({ firebase = false }) {
    const { post } = useApi();
    if (firebase) {
        return { sendResetPasswordEmail, signInWith, signIn, signUp };
    }

    async function _signIn(login, password) {
        const body = await post('auth/signin', { login, password })
        if (body._isValid_) {

        } else if (body._validation_.login && !body._validation_.login.valid) {
            return Promise.reject({ code: 'auth/user-not-found', message: body._validation_.login.message });
        } else if (body._validation_.password && !body._validation_.password.valid) {
            return Promise.reject({ code: 'auth/wrong-password', message: body._validation_.password.message });
        }
    }

    async function _signUp(name, email, password) {
        const body = await post('auth/signup', { name, email, password })
        if (body._isValid_) {

        } else if (body._validation_.email && !body._validation_.email.valid) {
            return Promise.reject({ code: 'auth/user-not-found', message: body._validation_.email.message });
        } else if (body._validation_.login && !body._validation_.login.valid) {
            return Promise.reject({ code: 'auth/user-not-found', message: body._validation_.login.message });
        } else if (body._validation_.password && !body._validation_.password.valid) {
            return Promise.reject({ code: 'auth/wrong-password', message: body._validation_.password.message });
        }
    }

    async function _sendResetPasswordEmail(email) {
        const body = await post('auth/send-reset-password-email', { email })
        if (body._isValid_) {

        } else if (body._validation_.email && !body._validation_.email.valid) {
            return Promise.reject({ code: 'auth/user-not-found', message: body._validation_.email.message });
        }
    }

    async function _signInWith(provider) {
        signInWith(provider)
    }

    return { sendResetPasswordEmail: _sendResetPasswordEmail, signInWith: _signInWith, signIn: _signIn, signUp: _signUp };
}

export function useAuthDefinitions() {
    const { define, get, post } = useApi();
    const [, setUser] = useRecoilState(userState);
    const [checking, setChecking] = useState(!!localStorage.getItem('token'));

    const initUser = (u) => {
        const user = { ...u };
        user.switch = (role) => {
            try {
                user.role = role;
            } catch (e) { }
            if (role) {
                localStorage.setItem('role', role.name);
            } else {
                localStorage.removeItem('role');
            }
            setUser({ ...user, role });
        }

        user.roles ??= [];

        if (!user.role) {
            let r = localStorage.getItem('role');
            if (!r && user.roles.length) r = user.roles[0].name;
            if (r) {
                if (!user.roles || !user.roles.length) {
                    window.location.reload();
                } else {
                    user.switch(user.roles.find(role => role.name === r));
                }
            }
        }
        return user;
    }
    define('setToken', async (token) => {
        if (token) {
            localStorage.setItem('token', token);
            const user = await get('auth/get-account-infos');
            setUser(initUser(user));
        } else {
            localStorage.removeItem('token');
            setUser(null);
        }
    });
    define('setUser', async (user) => {
        if (user && user.token) {
            localStorage.setItem('token', user.token);
            setUser(initUser(user));
        } else {
            localStorage.removeItem('token');
            setUser(null);
            window.location.reload();
        }
    });
    define('log', async (...data) => {
        console.log(...data);
    });
    define('table', async (...data) => {
        console.table(...data);
    });
    useEffect(() => {
        if (localStorage.getItem('token')) {
            get('auth/get-account-infos').then(user => setUser(initUser(user))).then(() => setChecking(false));
        } else {
            setChecking(false);
        }
    }, []);
    window.api = (path, data) => post(path, data).then(console.log);
    return { checking };
}
