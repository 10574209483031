import React from "react";
import Input, { ConfigInput } from "./Input";
import JsTree from "./JsTree";

const MenuConfig = (props) => {
  var mdl = {};
  const checkCallBack = (operation, node, node_parent, node_position, more) => {
    // console.log({ operation, node, node_parent, node_position, more });
    switch (operation) {
      case "move_node":
        if (
          node.data.type === "Page" &&
          ["Page", "Menu"].includes(
            node_parent && node_parent.data && node_parent.data.type
          )
        ) {
          return true;
        } else if (
          node.data.type === "Role" &&
          ["#", ""].includes(node_parent && node_parent.id)
        ) {
          return true;
        }
        return false;
      default:
        break;
    }
    return true;
  };
  const onAdd = (jstree, value) => {
    let roleNode = jstree.create_node(
      "#",
      { text: value, data: { type: "Role" } },
      "last"
    );
    jstree.create_node(roleNode, {
      text: "Menu",
      data: { type: "Menu", list: [] },
    });
    jstree.create_node(roleNode, {
      text: "Rights",
      data: { type: "RightsFolder" },
    });
  };
  const contextMenu = {
    items: function (node) {
      switch (node.data.type) {
        case "Role":
          return {
            renameItem: {
              label: "Rename",
              action: function (obj) {
                mdl.jstree.edit(node);
              },
            },
            deleteItem: {
              label: "Remove",
              action: function (obj) {
                mdl.jstree.delete_node(node);
              },
            },
          };
        case "Menu":
          return {
            createItem: {
              label: "Add new page",
              action: function (obj) {
                let $node = mdl.jstree.create_node(node, {
                  text: "New Page",
                  data: { type: "Page" },
                });
                mdl.jstree.edit($node);
              },
              _class: "class",
            },
          };
        case "Page":
          return {
            createItem: {
              label: "Add new page",
              action: function (obj) {
                let $node = mdl.jstree.create_node(node, {
                  text: "New Page",
                  data: { type: "Page" },
                });
                mdl.jstree.edit($node);
              },
              _class: "class",
            },
            renameItem: {
              label: "Rename",
              action: function (obj) {
                mdl.jstree.edit(node);
              },
            },
            deleteItem: {
              label: "Remove",
              action: function (obj) {
                mdl.jstree.delete_node(node);
              },
            },
          };

        default:
          return {};
      }
    },
  };
  return (
    <div>
      <JsTree
        model={mdl}
        value={props.value}
        onChange={props.onChange}
        buttons={["add"]}
        checkCallBack={checkCallBack}
        contextMenu={contextMenu}
        onAdd={onAdd}
      >
        {(elm) => {
          switch (elm.data.type) {
            case 'Page':
              return (
                <div style={{ width: '100%' }} key={elm.id}>
                  <ConfigInput.Text label="Titre de page" {...elm.bind('titre', { emptyValue: '' })} />
                  <ConfigInput.Text label="Path" {...elm.bind('path', { emptyValue: '' })} />
                  <ConfigInput.Select label="Align" {...elm.bind('align', { emptyValue: '' })} options={['left', 'right']} />
                  <ConfigInput.Text label="Flow name" {...elm.bind('flow', { emptyValue: '' })} />
                  <ConfigInput.Image label="Icon menu de page" {...elm.bind('icon')} />
                </div>
              );
            case 'Role':
              return (
                <div style={{ width: '100%' }} key={elm.id}>
                  <ConfigInput.Text label="Titre de role" {...elm.bind('titre', { emptyValue: '' })} />
                  <ConfigInput.Image label="Icon role" {...elm.bind('icon')} />
                  <ConfigInput.Select label="Auth required"{...elm.bind('auth', { emptyValue: '' })} options={["Oui", "Non"]} />
                  <ConfigInput.List label="Liste des domains" {...elm.bind('domains', { emptyValue: [] })}>
                    {item => (
                      <>
                        <Input.Text {...item.bind('value', { emptyValue: '' })} placeholder="example.com" />
                      </>
                    )}
                  </ConfigInput.List>
                </div>
              );
            default:
              return null;
          }

        }}
      </JsTree>
    </div>
  );
};

export default MenuConfig;
