import useApi from "com/API/api";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useRecoilState } from "recoil";
import { activeProfile } from "states";

function useProfile() {
    const location = useLocation();
    const history = useHistory();
    const [userName, setUserName] = useState('');
    const [user, setUser] = useRecoilState(activeProfile);
    const { post } = useApi();

    const checkUserNameFromPath = () => {
        let un = '';
        if (location && location.pathname) {
            if (location.pathname.startsWith('/@')) {
                un = location.pathname.split('@')[1].split('/')[0];
            }
        }
        if (un !== userName) {
            setUserName(un);
        }
    }

    checkUserNameFromPath();

    useEffect(() => {
        const unlisten = history.listen(checkUserNameFromPath);
        return () => unlisten();
    }, [history]);

    useEffect(() => {
        if (userName) {
            if (user && user.userName === userName) return;
            post('active-user', { userName })
                .then(u => {
                    setUser(u)
                })
                .catch(error => console.error(error));
        } else {
            setUser(null);
        }
    }, [userName, user]);

    return user;
}

export default useProfile;