import { defineAtomElement } from '../../Atom';
import Ticket, { DOC_TYPES, SectionTitleSep } from '../../../Ticket/Ticket';
import Articles from './articles.atom';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { Paper } from '@mui/material';

function AtomTicket({ item }) {
  return <EditDoc type={DOC_TYPES.BL} />
}


export const EditDoc = React.forwardRef(function ({ type, ...props }, ref) {
  const ticketRef = useRef();
  return (
    <TicketContainer >
      <div style={{ display: 'flex', flexDirection: 'column', flex: 3 }} className="ticketarticles">
        <Paper style={{ padding: 10, flex: 1, display: 'flex', flexDirection: 'column' }}>
          <SectionTitleSep>Articles</SectionTitleSep>
          <Articles.Ticket ticketRef={ref ? ref : ticketRef} docType={type} />
        </Paper>
      </div>
      <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
        <Ticket ref={ref ? ref : ticketRef} type={type} {...props} />
      </div>
    </TicketContainer>
  )
});

const TicketContainer = styled.div`
  display: flex;
  gap: 15px;
  min-height: 71vh;
  padding: 15px;
  border-radius: 5px;
  background-color: #e9e9e975;
`;


defineAtomElement({
  name: 'Ticket',
  title: 'Ticket',
  Component: AtomTicket
});

export default AtomTicket;
