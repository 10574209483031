import createBindFunction from 'com/utils/createBindFunction';
import { get, set } from 'lodash';
import React, { useRef, useEffect, useMemo, useState } from 'react';
import { useCallback } from 'react';
import Loading from '../Loading';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import useBindableState from 'com/utils/useBindableState';


const Modal1 = React.forwardRef(({ visible, onClose, onOK, cancelText, okText, hideCancel, loading, hideOk, title, children, style, ...props }, ref) => {
    const modalRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [, setRender] = useState(0);
    const [modal, setModalObj] = useState(null);
    // const modal = useMemo(() => {
    //     console.log('-----------------------------', modalRef.current);
    //     if (modalRef.current) {
    //         modalRef.current.addEventListener('show.bs.modal', function (event) {
    //             setIsVisible(true);
    //         });
    //         modalRef.current.addEventListener('hidden.bs.modal', function (event) {
    //             setIsVisible(false);
    //         });
    //         return new window.bootstrap.Modal(modalRef.current, { keyboard: false });
    //     }
    //     return null;
    // }, [modalRef.current]);

    useEffect(() => {
        const divElement = modalRef.current;
        if (divElement) {
            divElement.addEventListener('show.bs.modal', function (event) {
                setIsVisible(true);
            });
            divElement.addEventListener('hidden.bs.modal', function (event) {
                setIsVisible(false);
            });
            setModalObj(new window.bootstrap.Modal(divElement, { keyboard: false }));
        }
    }, []);

    const handleClose = () => {
        if (onClose) {
            onClose();
        } else if (ref && ref.current && ref.current.onCancel) {
            ref.current.onCancel();
        } else if (modal) {
            modal.current.hide();
        }
    }


    const handleOk = () => {
        if (onOK) {
            onOK(get(ref, 'current.item'));
        } else if (ref && ref.current && ref.current.onOk) {
            ref.current.onOk(get(ref, 'current.item'));
        } else if (modal) {
            modal.current.hide();
        }
    }

    useEffect(() => {
        if (ref) {
            ref.current = ref.current || {};
            ref.current.show = (item, options = {}) => {
                if (options && options.bindable) {
                    item.bind = createBindFunction(item, {
                        data: item, update: (_data) => {
                            set(ref, 'current.item', _data);
                            setRender(r => r + 1);
                        }
                    });
                }
                ref.current.onOk = () => {
                    if (options && options.onOk) {
                        options.onOk(get(ref, 'current.item'));
                    } else {
                        modal.current.hide();
                    }
                };

                ref.current.onCancel = () => {
                    if (options && options.onCancel) {
                        options.onCancel();
                    } else {
                        modal.current.hide();
                    }
                };

                ref.current.item = item;
                modal.current.show();
            };
            ref.current.hide = () => {
                modal.current.hide();
            };
        }
    }, [ref && ref.current]);

    useEffect(() => {
        if (!modal) return;
        if (visible) {
            modal.current.show();
        } else {
            modal.current.hide();
        }
    }, [modal, visible])

    return (
        <div ref={modalRef} className="modal fade" data-bs-backdrop="static" tabIndex="-1">
            <div className="modal-dialog" style={{ ...style }}>
                <div className="modal-content">
                    <div className="modal-header bg-input">
                        <h5 className="modal-title">{title}</h5>
                        <button disabled={loading} type="button" className="btn-close" onClick={handleClose}></button>
                    </div>
                    <div className="modal-body">
                        <Loading loading={loading}>
                            {isVisible ? (typeof children === 'function' ? children(get(ref, 'current.item')) : children) : null}
                        </Loading>
                    </div>
                    <div className="modal-footer bg-input">
                        {hideCancel ? null : <button disabled={loading} type="button" className="btn btn-default" onClick={handleClose}>{cancelText || 'Fermer'}</button>}
                        {hideOk ? null : <button disabled={loading} type="button" className="btn btn-primary" onClick={handleOk}>{okText || 'Ok'}</button>}
                    </div>
                </div>
            </div>
        </div>
    );
});

const Modal = React.forwardRef(({ visible, onClose, onOK, cancelText, okText, hideCancel, loading, hideOk, title, children, style, ...props }, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const state = useBindableState({});
    const [r, setRender] = useState(false);
    if (!ref) ref = {};
    if (!ref.current) ref.current = {};
    ref.current.show = (item, options = {}) => {
        if (options && options.bindable) {
            item.bind = createBindFunction(item, {
                data: item, update: (_data) => {
                    set(ref, 'current.item', _data);
                    setRender(r => !r);
                }
            });
        }
        ref.current.onOk = () => {
            if (options && options.onOk) {
                options.onOk(get(ref, 'current.item'));
            } else {
                setIsVisible(false)
            }
        };

        ref.current.onCancel = () => {
            if (options && options.onCancel) {
                options.onCancel();
            } else {
                setIsVisible(false)
            }
        };

        ref.current.item = item;
        setIsVisible(true)
    };

    ref.current.hide = () => {
        setIsVisible(false)
    };

    useEffect(() => {
        if (visible) {
            ref.current.show();
        }
    }, [visible]);

    const handleClose = (cb) => () => {
        setIsVisible(false)
        if (cb) cb(ref?.current?.item);
    }
    return (
        <Dialog
            {...props}
            open={isVisible}
            onClose={handleClose()}
        >
            {title ? <DialogTitle>{title}</DialogTitle> : null}
            <DialogContent>
                <Loading loading={loading}>
                    {isVisible ? (typeof children === 'function' ? children(get(ref, 'current.item')) : children) : (typeof children === 'function' ? children(state) : children)}
                </Loading>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose(onClose)}>
                    {cancelText || 'Fermer'}
                </Button>
                {hideOk ? null : <Button onClick={handleClose(onOK)} variant="contained">{okText || 'OK'}</Button>}
            </DialogActions>
        </Dialog>
    );
});


export const ModalBottom = React.forwardRef(({ visible, onClose, onOK, cancelText, okText, hideCancel, loading, hideOk, title, children, style, ...props }, ref) => {
    const modalRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [modal, setModalBSElm] = useState(null);

    const setModalElm = useCallback((elm) => {
        if (elm) {
            modalRef.current = elm;
            modalRef.current.addEventListener('show.bs.modal', function (event) {
                setIsVisible(true);
            });
            modalRef.current.addEventListener('hidden.bs.modal', function (event) {
                setIsVisible(false);
            });
            setModalBSElm(new window.bootstrap.Modal(modalRef.current, { keyboard: false }));
        }
    }, []);

    const handleClose = () => {
        if (onClose) {
            onClose();
        } else if (ref && ref.current && ref.current.onCancel) {
            ref.current.onCancel();
        } else if (modal) {
            modal.current.hide();
        }
    }


    const handleOk = () => {
        if (onOK) {
            onOK();
        } else if (ref && ref.current && ref.current.onOk) {
            ref.current.onOk();
        } else if (modal) {
            modal.current.hide();
        }
    }

    useEffect(() => {
        if (ref) {
            ref.current = ref.current || {};
            ref.current.show = (item, options = {}) => {
                ref.current.onOk = () => {
                    if (options && options.onOk) {
                        options.onOk();
                    } else {
                        modal.current.hide();
                    }
                };

                ref.current.onCancel = () => {
                    if (options && options.onCancel) {
                        options.onCancel();
                    } else {
                        modal.current.hide();
                    }
                };

                ref.current.item = item;
                modal.current.show();
            };
            ref.current.hide = () => {
                modal.current.hide();
            };
        }
    }, [ref && ref.current, modal]);

    useEffect(() => {
        if (!modal) return;
        if (visible) {
            modal.current.show();
        } else {
            modal.current.hide();
        }
    }, [modal, visible])

    return (
        <div ref={setModalElm} className="modal modalbottom fade" data-bs-backdrop="static" tabIndex="-1">
            <div className="modal-dialog" style={{ ...style }}>
                <div className="modal-content">
                    <div className="modal-body">
                        <Loading loading={loading}>
                            <button disabled={loading} type="button" className="btn-close" onClick={handleClose}></button>
                            {isVisible ? (typeof children === 'function' ? children(get(ref, 'current.item')) : children) : null}
                        </Loading>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Modal;
