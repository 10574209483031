import { Icon } from '@mui/material';
import useApi from 'com/API/api';
import { defineAtomElement } from 'com/Atom/Atom';
import Input from 'com/Imput/Input';
import { Debounce } from 'com/utils/createBindFunction';
import useCrud, { TableCRUDDialog, TableCRUDeleteDialog, TableCRUDFilter, TableCRUDLoading, TableCRUDTable } from 'com/utils/useCrud';
import { useState } from 'react';

export function Unite({ item }) {
  const { list, selected, loading, filter, loadData, nbrPages, createNewItem, total, page } = useCrud('unites', { length: 10, id: 'id' });

  const colmuns = [
    { key: 'titre', title: 'Titre' }
  ];

  const actions = [
    { icon: 'edit', label: 'Edit' },
    { icon: 'delete', label: 'Delete' },
  ];

  return (
    <TableCRUDLoading loading={loading}>
      <TableCRUDFilter onSearch={() => loadData()} onAdd={() => createNewItem()}>
        <Debounce {...filter.bind('', { emptyValue: {} })} wait={500} onUpdate={() => loadData()} >
          {(filterDraft) => (
            <>
              <Input.Text label="Titre" {...filterDraft.bind('titre')} />
            </>
          )}
        </Debounce>
      </TableCRUDFilter>
      <TableCRUDTable
        rows={list}
        colmuns={colmuns}
        actions={actions}
        loadData={loadData}
        page={page}
        total={total}
        nbrPages={nbrPages}
        hideOptions
      />
      <TableCRUDDialog item={selected.edit} header="Modification" actions={(item) => [
        { label: 'Annuler', onClick: () => { item.reset(); item.close('edit'); } },
        {
          label: 'Enregistrer', variant: 'contained', onClick: () => {
            item.accept();
            selected.edit.save().then(ok => ok ? selected.edit.close() : null);
          }
        }
      ]}>
        {item => (
          <div className='row' style={{ minWidth: 700 }}>
            <div className='col-12'>
              <Input.Text label="Titre" {...item.bind('titre')} />
            </div>
          </div>
        )}
      </TableCRUDDialog>
      <TableCRUDeleteDialog item={selected.delete} />
    </TableCRUDLoading >
  );
}

Unite.Select = function Select(props) {
  const { post } = useApi();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Input.Select2 {...props}
        loadOptions={(q) => post('unites/select', { q })}
        valueCol='id'
        action={props.emptyOption ? null : {
          label: <Icon>add</Icon>,
          onClick: () => {
            setOpen({});
          }
        }}
      />
      <TableCRUDDialog fullWidth item={open} header="Famille" actions={(item) => [{ label: 'Fermer', onClick: () => { setOpen(false); } }]}>
        {() => (
          <Unite />
        )}
      </TableCRUDDialog>
    </>
  );
}

defineAtomElement({
  name: 'Unite',
  title: 'Unite',
  default: {},
  Component: Unite
});

export default Unite;
