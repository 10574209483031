import useConfig from "com/utils/useConfig";
import { get } from "lodash";
import React, { useCallback, useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import { InputPassword, InputText, useForm } from "../Imput/Input";
import './Auth.css';
import { useAuthFunctions } from "./Auth.service";

function Auth({ active = 'Login' }) {
  const config = useConfig({ name: 'config', version: 'config' });
  const signupEnabled = get(config, 'data.app.signup', 'off') !== 'off';
  const [emailSent, setEmailSent] = useState(false);
  const { bind, values, setError, isValid, resetErrors } = useForm();
  const { signIn, sendResetPasswordEmail, signInWith, signUp } = useAuthFunctions({ firebase: false });
  const onLogin = useCallback(() => {
    resetErrors();

    if (!values.email) {
      setError('email');
    }

    if (!values.password) {
      setError('password');
    }

    if (isValid()) {
      signIn(values.email, values.password).catch((error) => {
        switch (error && error.code) {
          case 'auth/wrong-password':
            setError('password', error.message || 'Wrong password');
            break;
          case 'auth/user-not-found':
            setError('email', error.message || 'User not found');
            break;
          default:
            setError('email', error.message);
            break;
        }
      });
    }
  }, [values, setError, resetErrors, isValid]);

  const onResetPassword = useCallback(() => {
    resetErrors();

    if (!values.email) {
      setError('email');
    }

    if (isValid()) {
      sendResetPasswordEmail(values.email).catch((error) => {
        if (error) {
          setError('email', error.message);
        } else {
          setEmailSent(true);
        }
      });
    }
  }, [values, setError, resetErrors, isValid]);

  const onLoginWith = useCallback(() => {
    signInWith('google')
  }, []);

  const onSigup = useCallback(() => {
    resetErrors();

    if (!values.name) {
      setError('name');
    }

    if (!values.email) {
      setError('email');
    }

    if (!values.password) {
      setError('password');
    }

    if (!values.confirmpassword) {
      setError('confirmpassword');
    }

    if (values.password && values.confirmpassword && values.password !== values.confirmpassword) {
      setError('password');
      setError('confirmpassword');
    }

    if (isValid()) {
      signUp(`${values.name}`.trim(), values.email, values.password).catch((error) => {
        switch (error && error.code) {
          case 'auth/wrong-password':
            setError('password', error.message);
            break;
          default:
            setError('email', error && error.message);
            break;
        }
      });
    }
  }, [values, setError, resetErrors, isValid]);

  let form = null;
  switch ((active || '').toLowerCase()) {
    case 'signup':
      form = (
        <>
          <SepText>Sign up</SepText>
          <InputText label="Name" name="firstName" {...bind('name')} />
          <InputText label="Email" type="email" name="email" {...bind('email')} />
          <InputPassword label="Password" {...bind('password')} />
          <InputPassword label="Confirm Password" {...bind('confirmpassword')} />
          <div className="auth-form-actions">
            <button className="auth-btn" onClick={onSigup}>Sign up</button>
            <small>You have an account? <Link to="/auth/login">Login</Link></small>
          </div>
        </>
      );
      break;
    case 'resetpassword':
      form = (
        <>
          <SepText>Reset Password</SepText>
          {
            emailSent ?
              (
                <div className="success-alert">
                  We have e-mailed your password reset link!
                </div>
              ) : (
                <>
                  <InputText label="Email" type="email" name="email" {...bind('email')} />
                  <div className="auth-form-actions">
                    <button className="auth-btn" onClick={onResetPassword}>Reset</button>
                    <small>You have an account? <Link to="/auth/login">Login</Link></small>
                  </div>
                </>
              )
          }

        </>
      );
      break;
    default:
      form = (
        <>
          <SepText>Login</SepText>
          <InputText label="Email" type="email" name="email" {...bind('email')} />
          <InputPassword label="Password" {...bind('password')}>
            <small><Link to="/auth/reset-password">Forgot password?</Link></small>
          </InputPassword>
          <div className="auth-form-actions">
            <button className="auth-btn" onClick={onLogin}>Login</button>
            {signupEnabled ? <small>Don't have an account? <Link to="/auth/signup">Sign up</Link></small> : null}
          </div>
        </>
      );
      break;
  }

  // if (profile.isLoaded && !profile.isEmpty) return <Redirect to="/" />

  return (
    <div className="auth-page">
      <div className="auth-form">
        <div className="auth-logo">
          <img className="logo" src="/images/logo.png" alt="logo" />
        </div>
        {form}
        <SepText>OR</SepText>
        <button className="auth-SSO-btn" onClick={onLoginWith}> <img src="/images/google.png" alt="google" /> <span>Continue with Google</span> </button>
      </div>
    </div>
  );
}

function SepText({ children }) {
  return (
    <div className="sep-text">
      <span className="sep-text-line" />
      <span className="sep-text-content" >{children}</span>
      <span className="sep-text-line" />
    </div>
  )
}

export function Login() {
  return <Auth active="Login" />;
}

export function SignUp() {
  return <Auth active="SignUp" />;
}

export function ResetPassword() {
  return <Auth active="ResetPassword" />;
}

export function AuthRoute() {
  const config = useConfig({ name: 'config', version: 'config' });
  const signupEnabled = get(config, 'data.app.signup', 'off') !== 'off';
  return (
    <Switch>
      <Route exact path="/auth/login" component={Login} />
      {signupEnabled ? <Route exact path="/auth/signup" component={SignUp} /> : null}
      <Route exact path="/auth/reset-password" component={ResetPassword} />
      <Redirect to="/auth/login" />
    </Switch>
  );
}

export default Auth;
