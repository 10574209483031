import React from 'react'
import Input from '../../Imput/Input';
import { defineAtomElement } from '../Atom';

function Image({ item, atomOptions }) {
    return (
        <div>
            <img src={item.image} alt={item.alt} style={{ ...item.style }}/>
        </div>
    )
}

function ImageConfig({ item }) {
    return (
        <div className='row'>
            <div className='col-12'>
                <Input.Image label="Image" {...item.bind('image')} />
            </div>
            <div className='col-4'>
                <Input label="ALT" {...item.bind('alt')} />
            </div>
            <div className='col-4'>
                <Input label="Width" {...item.bind('style.width')} />
            </div>
            <div className='col-4'>
                <Input label="Hieght" {...item.bind('style.height')} />
            </div>
        </div>
    )
}

defineAtomElement({
    name: 'image',
    title: 'Image',
    default: {},
    Component: Image,
    Config: ImageConfig
});



function Images({ item, atomOptions }) {
    return (
        <div>
            <img src={item.image} alt={item.alt} style={{ ...item.style }}/>
        </div>
    )
}

function ImagesConfig({ item }) {
    return (
        <div className='row'>
            <div className='col-12'>
                {/* <Input.Images label="Images" max={10} {...item.$bind('images')} /> */}
            </div>
            <div className='col-4'>
                <Input label="ALT" {...item.$bind('alt')} />
            </div>
            <div className='col-4'>
                <Input label="Width" {...item.$bind('style.width')} />
            </div>
            
            <div className='col-4'>
                <Input label="Hieght" {...item.$bind('style.height')} />
            </div>
        </div>
    )
}

defineAtomElement({
    name: 'images',
    title: 'Images',
    default: {},
    Component: Images,
    Config: ImagesConfig
});

export default Image
