import { get } from "lodash";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { userState, activeProfile } from "../../states";
import { useAtomDoc } from "../Firebase/Firebase";

let cacheData = null;
function useConfig(options) {
    const [user] = useRecoilState(userState);
    const [profile] = useRecoilState(activeProfile);

    const { data, loading } = useAtomDoc({ ...options, useApplyToSave: true, cache: true }, cacheData);
    useEffect(() => {
        if (data) {
            cacheData = data;
        }
    }, [data]);

    const checkActiveSpace = (space, pathname) => {
        if (space.path.match(/^\/@me\b/i)) {
            return pathname.startsWith(space.path.replace(/^\/@me/i, '/@' + user?.username));
        } else if (space.path.match(/^\/@user\b/i)) {
            return pathname.startsWith(space.path.replace(/^\/@user/i, '/@' + profile?.userName));
        } else {
            return pathname.startsWith(space.path);
        }
    };

    const checkActivePage = (pageData, pathname) => {
        if (pageData.path.match(/^\/@me\b/i)) {
            pageData.path = pageData.path.replace(/^\/@me/i, '/@' + user?.username);
        } else if (pageData.path.match(/^\/@user\b/i)) {
            pageData.path = pageData.path.replace(/^\/@user/i, '/@' + profile?.userName);
        }
        return pathname.startsWith(pageData.path);
    };

    const spaces = get(data, 'app.spaces.' + (user?.role?.name || 'public'), []) || [];
    const roles = get(data, 'app.roles', []) || [];
    const pathname = window.location.pathname;
    const spacesActive = spaces
        .filter(space => checkActiveSpace(space, pathname))
        .sort((a, b) => a.path.split('/').filter(s => s).length > b.path.split('/').filter(s => s).length ? -1 : 1);
    const space = spacesActive.length ? spacesActive[0] : null;
    const menu = (get(space, 'menu', []) || []).find(m => m.text === space?.activeconfig)
    const menuList = ((get(menu, 'children', []) || []).find(m => m.text === 'Menu') || {}).children || [];
    menuList.forEach(elm => checkActivePage(elm.data, pathname));
    const page = (menuList.find(elm => checkActivePage(elm.data, pathname)) || {}).data;
    return { data, spaces, roles, spacesActive, space, menu, page, menuList, loading };
}


export default useConfig;
