import { defineAtomElement } from 'com/Atom/Atom';
import Input from 'com/Imput/Input';
import { Debounce } from 'com/utils/useBindableState';
import useCrud, { TableCRUDDialog, TableCRUDeleteDialog, TableCRUDFilter, TableCRUDLoading, TableCRUDTable } from 'com/utils/useCrud';

function Taxes() {
    const { list, selected, loading, filter, loadData, nbrPages, createNewItem, total, page } = useCrud('taxes', { length: 10, id: 'id' });
  
    const colmuns = [
      { key: 'titre', title: 'Titre' }
    ];
  
    const actions = [
      { icon: 'edit', label: 'Edit' },
      { icon: 'delete', label: 'Delete' },
    ];
  
    return (
      <TableCRUDLoading loading={loading}>
        <TableCRUDFilter onSearch={() => loadData()} onAdd={() => createNewItem()}>
          <Debounce {...filter.bind('', { emptyValue: {} })} wait={500} onUpdate={() => loadData()} >
            {(filterDraft) => (
              <>
                <Input.Text label="Titre" {...filterDraft.bind('titre')} />
              </>
            )}
          </Debounce>
        </TableCRUDFilter>
        <TableCRUDTable
          rows={list}
          colmuns={colmuns}
          actions={actions}
          loadData={loadData}
          page={page}
          total={total}
          nbrPages={nbrPages}
          hideOptions
        />
        <TableCRUDDialog item={selected.edit} header="Modification" actions={(item) => [
          { label: 'Annuler', onClick: () => { item.reset(); item.close('edit'); } },
          {
            label: 'Enregistrer', variant: 'contained', onClick: () => {
              item.accept();
              selected.edit.save().then(ok => ok ? selected.edit.close() : null);
            }
          }
        ]}>
          {item => (
            <div className='row' style={{ minWidth: 700 }}>
              <div className='col-12'>
                <Input.Text label="Titre" {...item.bind('titre')} />
              </div>
            </div>
          )}
        </TableCRUDDialog>
        <TableCRUDeleteDialog item={selected.delete} />
      </TableCRUDLoading >
    );
  }


function TaxesConfig({ item }) {
    return (
        <div className='row'>
            <div className='col-12'>
                <Input.Text lable="Title" {...item.bind('title', { emptyValue: '' })} />
            </div>
        </div>
    )
}

defineAtomElement({
    name: 'Taxes',
    title: 'Taxes',
    default: {},
    Component: Taxes,
    Config: TaxesConfig
});

export default Taxes;
