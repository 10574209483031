import { defineAtomElement } from './../Atom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function Text ({ item }) {
  return (
    <div dangerouslySetInnerHTML={{ __html: item.texte}}/>
  )
}

function TextConfig ({ item }) {
  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={item.texte}
        onChange={(event, editor) => {
          const texte = editor.getData();
          if (item.texte !== texte) item.$set({ texte });
        }}
      />
    </div>
  )
}

defineAtomElement({
  name: 'text',
  title: 'Texte',
  default: {
    texte: '<p>Texte ...</p>'
  },
  Component: Text,
  Config: TextConfig
});

export default Text;
