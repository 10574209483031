import { defineAtomElement } from 'com/Atom/Atom';
import Input from 'com/Imput/Input';
import { Debounce } from 'com/utils/createBindFunction';
import useCrud, { TableCRUDDialog, TableCRUDeleteDialog, TableCRUDFilter, TableCRUDLoading, TableCRUDTable } from 'com/utils/useCrud';
import FamilleArticle from './famille-article.atom';
import Unite from './unite.atom';

export function Articles({ onAdd, hideEdit, hideDelete, colmuns }) {
  const { list, selected, loading, filter, loadData, nbrPages, createNewItem, total, page } = useCrud('Articles', { length: 10, id: 'id' });

  let colmunsList = [
    { key: 'ref', title: 'Référence' },
    { key: 'titre', title: 'Titre' },
    { key: 'famille_label', title: 'Famille' },
    { key: 'unite_label', title: 'Unité' },
    {
      key: 'marge_value', title: 'Marge',
      titleArgs: { align: 'right' },
      args: { align: 'right' },
      render: (row) => <span className='nowrap'>{parseFloat(row.marge_value || 0).toFixed(2)} <small><sup>{row.marge_unite || ''}</sup></small></span>
    },
    {
      key: 'prix_vente_ttc', title: 'Prix V TTC',
      titleArgs: { align: 'right', style: { whiteSpace: 'nowrap' } },
      args: { align: 'right' },
      render: (row) => <span className='nowrap'>{parseFloat(row.prix_vente_ttc || 0).toFixed(3)} <small><sup>DT</sup></small></span>
    },
  ];

  colmunsList = colmunsList.filter(col => {
    if (Array.isArray(colmuns)) {
      return colmuns.includes(col.key);
    } else {
      return true;
    }
  });

  const actions = [];

  if (!hideEdit) {
    actions.push({ icon: 'edit' });
  }


  if (!hideDelete) {
    actions.push({ icon: 'delete' });
  }

  if (onAdd) {
    actions.push({ icon: 'arrow_forward_ios', onClick: onAdd });
  }

  const calc = (item, col, value) => {
    switch (col) {
      case 'tva':
        item.prix_achat_ht = item.prix_achat_ht || 0;
        item.prix_achat_ttc = (parseFloat(item.prix_achat_ht) * (1 + parseFloat(value) / 100)).toFixed(2) * 1;
        if (`${item.marge_unite}`.trim().toLowerCase() === 'dt') {
          item.prix_vente_ht = (parseFloat(item.prix_achat_ht) + parseFloat(item.marge_value || 0)).toFixed(2) * 1;
        } else {
          item.prix_vente_ht = (parseFloat(item.prix_achat_ht) * (1 + parseFloat(item.marge_value || 0) / 100)).toFixed(2) * 1;
        }
        item.prix_vente_ttc = (parseFloat(item.prix_vente_ht) * (1 + parseFloat(value) / 100)).toFixed(2) * 1;
        break;

      case 'prix_achat_ht':
        item.prix_achat_ht = value || 0;
        item.prix_achat_ttc = (parseFloat(value) * (1 + parseFloat(item.tva || 0) / 100)).toFixed(2) * 1;
        if (`${item.marge_unite}`.trim().toLowerCase() === 'dt') {
          item.prix_vente_ht = (parseFloat(value) + parseFloat(item.marge_value || 0)).toFixed(2) * 1;
        } else {
          item.prix_vente_ht = (parseFloat(value) * (1 + parseFloat(item.marge_value || 0) / 100)).toFixed(2) * 1;
        }
        item.prix_vente_ttc = (parseFloat(item.prix_vente_ht) * (1 + parseFloat(item.tva || 0) / 100)).toFixed(2) * 1;
        break;

      case 'prix_achat_ttc':
        item.prix_achat_ht = (parseFloat(value) / (1 + parseFloat(item.tva || 0) / 100)).toFixed(2) * 1;
        if (`${item.marge_unite}`.trim().toLowerCase() === 'dt') {
          item.prix_vente_ht = (parseFloat(item.prix_achat_ht) + parseFloat(item.marge_value || 0)).toFixed(2) * 1;
        } else {
          item.prix_vente_ht = (parseFloat(item.prix_achat_ht) * (1 + parseFloat(item.marge_value || 0) / 100)).toFixed(2) * 1;
        }
        item.prix_vente_ttc = (parseFloat(item.prix_vente_ht) * (1 + parseFloat(item.tva || 0) / 100)).toFixed(2) * 1;
        break;

      case 'prix_vente_ht':
        item.prix_vente_ttc = (parseFloat(value) * (1 + parseFloat(item.tva || 0) / 100)).toFixed(2) * 1;
        if (`${item.marge_unite}`.trim().toLowerCase() === 'dt') {
          item.marge_value = (parseFloat(value) - parseFloat(item.prix_achat_ht)).toFixed(2) * 1;
        } else {
          item.marge_value = (((parseFloat(value) / parseFloat(item.prix_achat_ht)) - 1) * 100).toFixed(2) * 1;
        }
        break;

      case 'prix_vente_ttc':
        item.prix_vente_ht = (parseFloat(value) / (1 + parseFloat(item.tva || 0) / 100)).toFixed(2) * 1;
        if (`${item.marge_unite}`.trim().toLowerCase() === 'dt') {
          item.marge_value = (parseFloat(item.prix_vente_ht) - parseFloat(item.prix_achat_ht)).toFixed(2) * 1;
        } else {
          item.marge_value = (((parseFloat(item.prix_vente_ht) / parseFloat(item.prix_achat_ht)) - 1) * 100).toFixed(2) * 1;
        }
        break;

      case 'marge_value':
        if (`${item.marge_unite}`.trim().toLowerCase() === 'dt') {
          item.prix_vente_ht = (parseFloat(item.prix_achat_ht) + parseFloat(value || 0)).toFixed(2) * 1;
        } else {
          item.prix_vente_ht = (parseFloat(item.prix_achat_ht) * (1 + parseFloat(value || 0) / 100)).toFixed(2) * 1;
        }
        item.prix_vente_ttc = (parseFloat(item.prix_vente_ht) * (1 + parseFloat(item.tva || 0) / 100)).toFixed(2) * 1;
        break;

      case 'marge_unite':
        if (`${value}`.trim().toLowerCase() === 'dt') {
          item.prix_vente_ht = (parseFloat(item.prix_achat_ht) + parseFloat(item.marge_value || 0)).toFixed(2) * 1;
        } else {
          item.prix_vente_ht = (parseFloat(item.prix_achat_ht) * (1 + parseFloat(item.marge_value || 0) / 100)).toFixed(2) * 1;
        }
        item.prix_vente_ttc = (parseFloat(item.prix_vente_ht) * (1 + parseFloat(item.tva || 0) / 100)).toFixed(2) * 1;
        break;

      default:
        break;
    }
    item.set({ ...item });
  }


  return (
    <TableCRUDLoading loading={loading} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <TableCRUDFilter onSearch={() => loadData()} onAdd={() => createNewItem()}>
        <Debounce {...filter.bind('', { emptyValue: {} })} wait={500} onUpdate={() => loadData()} >
          {(filterDraft) => (
            <>
              <Input.Text label="Titre" {...filterDraft.bind('titre')} />
              <FamilleArticle.Select label="Famille" {...filterDraft.bind('famille')} emptyOption="Tout" />
            </>
          )}
        </Debounce>
      </TableCRUDFilter>
      <TableCRUDTable
        rows={list}
        colmuns={colmunsList}
        actions={actions}
        loadData={loadData}
        page={page}
        total={total}
        nbrPages={nbrPages}
        hideOptions
      />
      <TableCRUDDialog item={selected.edit} header="Modification" actions={(item) => [
        { label: 'Annuler', onClick: () => { item.reset(); item.close('edit'); } },
        {
          label: 'Enregistrer', variant: 'contained', onClick: () => {
            item.accept();
            selected.edit.save().then(ok => ok ? selected.edit.close() : null);
          }
        }
      ]}>
        {item => (
          <div className='row' style={{ minWidth: 700 }}>
            <div className='col-6'>
              <Input.Text label="Titre" {...item.bind('titre')} />
            </div>
            <div className='col-6'>
              <Input.Text label="Référence" {...item.bind('ref')} />
            </div>
            <div className='col-3'>
              <FamilleArticle.Select label="Famille" {...item.bind('famille')} />
            </div>
            <div className='col-3'>
              <Unite.Select label="Unite" {...item.bind('unite')} />
            </div>
            <div className='col-3'>
              <Input.Select type="number" label="TVA" {...item.bind('tva', { toData: (v) => { calc(item, 'tva', v); return v; } })} options={[0, 7, 13, 19]} suffix="%" />
            </div>
            <div className='col-3'>
              <Input.Text type="number" label="Marge" {...item.bind('marge_value', { toData: (v) => { calc(item, 'marge_value', v); return v; } })} append={{ ...item.bind('marge_unite', { toData: (v) => { calc(item, 'marge_unite', v); return v; } }), options: ['%', 'DT'] }} />
            </div>
            <div className='col-3'>
              <Input.Text type="number" label="Prix achat HT" {...item.bind('prix_achat_ht', { toData: (v) => { calc(item, 'prix_achat_ht', v); return v; } })} suffix="DT" />
            </div>
            <div className='col-3'>
              <Input.Text type="number" label="Prix achat TTC" {...item.bind('prix_achat_ttc', { toData: (v) => { calc(item, 'prix_achat_ttc', v); return v; } })} suffix="DT" />
            </div>
            <div className='col-3'>
              <Input.Text type="number" label="Prix vente HT" {...item.bind('prix_vente_ht', { toData: (v) => { calc(item, 'prix_vente_ht', v); return v; } })} suffix="DT" />
            </div>
            <div className='col-3'>
              <Input.Text type="number" label="Prix vente TTC" {...item.bind('prix_vente_ttc', { toData: (v) => { calc(item, 'prix_vente_ttc', v); return v; } })} suffix="DT" />
            </div>
            <div className='col-4'>
              <Input.Image label="Photo" {...item.bind('photo')} />
            </div>
          </div>
        )}
      </TableCRUDDialog>
      <TableCRUDeleteDialog item={selected.delete} />
    </TableCRUDLoading >
  );
}

Articles.Ticket = ({ ticketRef }) => {
  return <Articles onAdd={item => ticketRef.current.add(item, 'article')} colmuns={['ref', 'titre', 'prix_vente_ttc']} hideEdit hideDelete />;
}

function ArticlesConfig({ item }) {
  return (
    <div className='row'>
      <div className='col-12'>
        <Input.Text lable="Title" {...item.bind('title', { emptyValue: '' })} />
      </div>
    </div>
  )
}

defineAtomElement({
  name: 'Articles',
  title: 'Articles',
  default: {},
  Component: Articles,
  // Config: ArticlesConfig
});

export default Articles;
