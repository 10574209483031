import { Drawer, IconButton } from '@mui/material';
import React, { useState } from 'react';

const MobileMenu = ({ children, anchor = 'left', size = 30 }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton onClick={() => setOpen(!open)}>
        <span className="material-icons" style={{ fontSize: size }}>menu</span>
      </IconButton>
      <Drawer
        anchor={anchor}
        open={open}
        onClose={() => setOpen(false)}
      >
        {children}
      </Drawer>
    </>
  );
}

export default MobileMenu;
