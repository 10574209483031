import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FirebaseInitCom } from './com/Firebase/Firebase';
import { RecoilRoot } from 'recoil';
import { ModulesManagerIntegration } from './com/Atom/Com/ModulesManager.atom';
import { Router } from 'react-router-dom';
import history from 'com/history';

if (window?.screen?.orientation?.lock) {
  try {
    window.screen.orientation.lock('landscape').catch((err) => {
      console.log(err);
    });
  } catch (err) {
    console.error(err);
  }
}

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <ModulesManagerIntegration>
        <FirebaseInitCom>
          <Router history={history}>
            <App />
          </Router>
        </FirebaseInitCom>
      </ModulesManagerIntegration>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
