import React from 'react';
import { useRecoilState } from 'recoil';
import { generatedModules } from '../../states';
import useApi from '../API/api';
import { ATOMELEMENTS } from '../Atom/Atom';
import Input, { InputList, useForm } from '../Imput/Input';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
export const FIELDTYPES = [];

FIELDTYPES.push({
    titre: 'Input Text',
    name: 'input.text',
    Config: ({ config }) => {
        return (
            <>
                <div className='col-6'>
                    <Input.Text label="Placeholder" {...config.bind('placeholder')} placeholder="Placeholder" />
                </div>
                <div className='col-6'>
                    <Input.Text label="Prefix" {...config.bind('prefix')} placeholder="Prefix" />
                </div>
                <div className='col-6'>
                    <Input.Text label="Suffix" {...config.bind('suffix')} placeholder="Suffix" />
                </div>
            </>
        )
    },
    Render: ({ form, config, filter }) => {
        return <Input.Text label={config.label} prefix={config.prefix} suffix={config.suffix} {...form.bind(config.name, { autoComplete: !!filter })} placeholder={config.placeholder} />
    },
    Td: ({ value }) => value,
    Th: ({ config }) => config.label
});

FIELDTYPES.push({
    titre: 'Select',
    name: 'input.select',
    Config: ({ config }) => {
        return (
            <>
                <div className='col-12'>
                    <Input.List label="Options" {...config.bind('options')} />
                </div>
            </>
        )
    },
    Render: ({ form, config, filter }) => {
        return <Input.Select2
            label={config.label}
            {...form.bind(config.name)}
            emptyOption={filter ? 'Tout' : false}
            options={(config.options || []).map(op => op.value)}
            placeholder={config.placeholder}
        />
    },
    Td: ({ value }) => value,
    Th: ({ config }) => config.label
});


FIELDTYPES.push({
    titre: 'Module link',
    name: 'input.modulelink',
    Config: ({ config }) => {
        const selectedElem = ATOMELEMENTS.filter(elm => elm.autoGenereate).find(elm => elm.name === config.linkto);
        return (
            <>
                <div className='col-4'>
                    <Input.Select label="CRUD" {...config.bind('linkto')} options={ATOMELEMENTS.filter(elm => elm.autoGenereate).map(elm => ({ value: elm.name, label: elm.title }))} />
                </div>
                {selectedElem && selectedElem.data && Array.isArray(selectedElem.data.list) ? (
                    <>
                        <div className='col-4'>
                            <Input.List label="Search in" {...config.bind('listsearch')}>
                                {elm => (
                                    <Input.Select emptyOption='' {...elm.bind('name')} options={selectedElem.data.list} />
                                )}
                            </Input.List>
                        </div>
                        <div className='col-4'>
                            <Input.List label="Select" {...config.bind('listselect')}>
                                {elm => (
                                    <Input.Select emptyOption='' {...elm.bind('name')} options={selectedElem.data.list} />
                                )}
                            </Input.List>
                        </div>
                    </>
                ) : null}
            </>
        )
    },
    Render: ({ form, config, filter }) => {
        const [list] = useRecoilState(generatedModules);
        const { post } = useApi();
        const mdl = list.find(elm => (elm.name === config.linkto) && config.linkto);
        return <Input.Select2
            label={config.label}
            {...form.bindObj(config.name, { value: config.name + '_id', label: config.name + '_titre' })}
            // value={{
            //     value: form[config.name+'_id'],
            //     label: form[config.name+'_titre']
            // }}
            // onChange={elm => {
            //     form.set({ [config.name+'_id']: elm.value || null, [config.name+'_titre']: elm.label || null });
            //     if (filter && form.loadData) {
            //         form.loadData(1);
            //     }
            // }}
            emptyOption={filter ? 'Tout' : false}
            loadOptions={mdl ? (q) => post(mdl.path + '/select', { q, config }) : null}
            placeholder={config.placeholder}
        />
    },
    Td: ({ item, config }) => item[config.name + '_titre'],
    Th: ({ config }) => config.label
});

FIELDTYPES.push({
    titre: 'subForm',
    name: 'subForm',
    Config: ({ config }) => {
        return (
            <>
                <div className='col-12'>
                    <FormBuilder {...config.bind('list', { emptyValue: [] })} />
                </div>
            </>
        )
    },
    Render: ({ form, config }) => {
        return <FormRender form={form} schema={config.list} />
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}


FIELDTYPES.push({
    titre: 'Tabs',
    name: 'Tabs',
    Config: ({ config }) => {
        return (
            <>
                <div className='col-12'>
                    <Input.List label='Tabs' {...config.bind('tabs', { emptyValue: [] })}>
                        {(elm) => (
                            <div className='row'>
                                <div className='col-12'>
                                    <Input label="Label" {...elm.bind('label', { emptyValue: '' })} />
                                </div>
                                <div className='col-12'><FormBuilder {...elm.bind('list', { emptyValue: [] })} /></div>
                            </div>
                        )}
                    </Input.List>
                </div>
            </>
        )
    },
    Render: ({ form, config }) => {
        const [value, setValue] = React.useState(0);

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        return (
            <Box sx={{ width: '100%' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                >
                    {(config.tabs || []).map((tab) => <Tab label={tab.label} />)}
                </Tabs>
                {(config.tabs || []).map((tab, i) => (
                    <TabPanel value={value} index={i}>
                        <FormRender form={form} schema={tab.list} />
                    </TabPanel>
                ))}
            </Box>
        );
    }
});


FIELDTYPES.push({
    titre: 'Image',
    name: 'input.Image',
    Config: ({ config }) => {
        return (
            <>
                <div className='col-12'>
                    <Input.Text label="Height" {...config.bind('height')} />
                </div>
            </>
        )
    },
    Render: ({ form, config, filter }) => {
        return <Input.Image label={config.label} height={config.height} {...form.bind(config.name)} />
    },
    Td: ({ value }) => value,
    Th: ({ config }) => config.label
});

function FormRender({ value, onChange, form, schema }) {
    const form1 = useForm({ value, onChange });
    return (
        <div className='row'>
            {(schema || [])
                .filter(({ name }) => name)
                .map(field => ({ ...field, Field: FIELDTYPES.find(f => f.name === field.type) }))
                .filter(({ Field }) => Field)
                .map((filed) => {
                    const { Field } = filed;
                    return (
                        <div className={filed.col} key={filed.name}>
                            <Field.Render config={filed} form={form || form1} />
                        </div>
                    )
                })}
        </div>
    )
}

function FormBuilder(props) {
    return (
        <div>
            <InputList panel {...props} >
                {elm => (
                    <div className='row'>
                        <div className='col-3'>
                            <Input.Select2 label="Type" emptyOption="Type" {...elm.bind('type')} placeholder="Type" options={FIELDTYPES.map(option => ({ value: option.name, label: option.titre }))} />
                        </div>
                        <div className='col-3'>
                            <Input.Text label="Name" {...elm.bind('name')} placeholder="Name" />
                        </div>
                        <div className='col-3'>
                            <Input.Text label="Lable" {...elm.bind('label')} placeholder="Lable" />
                        </div>
                        <div className='col-3'>
                            <Input.Select2 label="Col" emptyOption="Col" {...elm.bind('col')} placeholder="Col" options={['col-1', 'col-2', 'col-3', 'col-4', 'col-5', 'col-6', 'col-7', 'col-8', 'col-9', 'col-10', 'col-11', 'col-12']} />
                        </div>
                        {FIELDTYPES.filter(({ name }) => name === elm.type).map(({ Config }) => <Config config={elm} />)}
                    </div>
                )}
            </InputList>
        </div>
    )
}

FormBuilder.Filter = function ({ formItems, ...props }) {
    return (
        <div>
            <InputList panel {...props} >
                {elm => (
                    <div className='row'>
                        <div className='col-3'>
                            <Input.Select label="Type filter" emptyOption="" {...elm.bind('typefilter')} options={['Propriété du CRUD', 'Personalisé']} />
                        </div>
                        {elm.typefilter === 'Personalisé' ? null : (
                            <div className='col-3'>
                                <Input.Select2
                                    label="Propriété"
                                    emptyOption="Propriété"
                                    {...elm.bind('itemName')}
                                    placeholder="Propriété"
                                    options={formItems.map(item => ({ value: item.name, label: item.label }))}
                                />
                            </div>
                        )}
                    </div>
                )}
            </InputList>
        </div>
    )
};

FormBuilder.Table = function ({ formItems, ...props }) {
    return (
        <div>
            <InputList panel {...props} >
                {elm => (
                    <div className='row'>
                        <div className='col-3'>
                            <Input.Select label="Type filter" emptyOption="" {...elm.bind('typetable')} options={['Propriété du CRUD', 'Personalisé']} />
                        </div>
                        {elm.typetable === 'Personalisé' ? null : (
                            <div className='col-3'>
                                <Input.Select2
                                    label="Propriété"
                                    emptyOption="Propriété"
                                    {...elm.bind('itemName')}
                                    placeholder="Propriété"
                                    options={formItems.map(item => ({ value: item.name, label: item.label }))}
                                />
                            </div>
                        )}
                    </div>
                )}
            </InputList>
        </div>
    )
};

function FormRenderFilter({ value, onChange, form, schema, props }) {
    const form1 = useForm({ value, onChange });

    let list = (schema || []).map(elm => {
        return {
            ...elm,
            prop: elm.typefilter === 'Propriété du CRUD' ? props.find(p => p.name === elm.itemName) : null
        };
    });

    list = list.map(elm => {
        if (elm.prop) {
            return {
                ...elm,
                Field: FIELDTYPES.find(f => f.name === elm.prop.type)
            }
        }
        return elm;
    });

    list = list.filter(elm => elm.Field);

    return (
        <>
            {list.map((filed) => {
                switch (filed.typefilter) {
                    case 'Propriété du CRUD':
                        const { Field } = filed;
                        return (
                            <div key={filed.prop.name}>
                                <Field.Render config={filed.prop} form={form || form1} filter={filed} />
                            </div>
                        )
                    default:
                        return null;
                }

            })}
        </>
    )
};

FormRender.Filter = FormRenderFilter;


function FormRenderTable({ value, onChange, form, schema, th, props }) {
    const form1 = useForm({ value, onChange });

    let list = (schema || []).map(elm => {
        return {
            ...elm,
            prop: elm.typefilter === 'Propriété du CRUD' ? props.find(p => p.name === elm.itemName) : null
        };
    });

    list = list.map(elm => {
        if (elm.prop) {
            return {
                ...elm,
                Field: FIELDTYPES.find(f => f.name === elm.prop.type)
            }
        }
        return elm;
    });

    list = list.filter(elm => elm.Field);

    return (
        <>
            {list.map((filed) => {
                switch (filed.typefilter) {
                    case 'Propriété du CRUD':
                        const { Field } = filed;
                        if (th) {
                            return (
                                <th key={filed.prop.name}>
                                    {filed.prop.label}
                                </th>
                            )
                        }
                        return (
                            <td key={filed.prop.name}>
                                <Field.Render config={filed.prop} form={form || form1} filter={filed} />
                            </td>
                        )
                    default:
                        return null;
                }

            })}
        </>
    )
};

FormRender.Table = FormRenderTable;

export { FormBuilder, FormRender };
export default FormRender;
