import React, { useState, useEffect, useCallback } from 'react';
import Input from '../../Imput/Input';
import Modal from '../../Modal/Modal';
import { defineAtomElement, getComByName } from './../Atom';
import { debounce, get, set } from 'lodash';
import useApi from '../../API/api';
import { CRUDConfig } from './CRUD.atom';
import Loading from '../../Loading';
import { useRecoilState } from 'recoil';
import { generatedModules } from '../../../states';
import { AtomComConfig } from './AtomCom.atom';

export const useModulesManager = ({ apiEndPoint, primaryKey = 'id', initItem, options = {}, ...extra }) => {
    const [list, setList] = useState([]);
    let [filter, setFilter] = useState({});
    const [autoCompleteObj, setAutoCompleteObj] = useState({});
    const [loading, setLoading] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [infos, setInfos] = useState({});
    const api = useApi()

    filter.set = (obj = {}) => {
        filter = Object.assign(filter, { ...obj });
        setFilter({ ...filter });
    }

    filter.bindObj = (name, obj, options = {}) => {
        const args = {};
        if (filter) {
            const nameStatus = name.split('.');
            nameStatus.pop();
            nameStatus.push('_validation_');
            nameStatus.push(name);
            args.validation = get(filter, nameStatus.join('.'), undefined);
        }

        let isnull = true;
        const value = {}
        Object.keys(obj)
            .forEach(key => {
                value[key] = get(filter, obj[key], null);
                if (![undefined, null].includes(value[key])) {
                    isnull = false;
                }
            });

        if (options && options.autoComplete) {
            args.autoComplete = autoComplete(typeof options.autoComplete === 'string' ? options.autoComplete : name);
        }

        return {
            ...args,
            value: isnull ? null : value,
            onChange: (e, targetname = '') => {
                const value = (typeof e === 'object' && 'target' in e) ? e.target.value : e;

                if (value) {
                    Object.keys(obj)
                        .forEach(key => {
                            set(filter, obj[key], value[key]);
                        });
                }

                filter.set();
                if (((e && e.target && e.target.tagName) || targetname) === 'SELECT') {
                    loadData(1);
                } else {
                    loadDataDebounce(1);
                }
                if (options && options.autoComplete) {
                    setAutoCompleteDebounce(typeof options.autoComplete === 'string' ? options.autoComplete : name, value);
                }
            }
        };
    };

    filter.bind = (name, options = {}) => {
        const args = {};
        if (filter) {
            const nameStatus = name.split('.');
            nameStatus.pop();
            nameStatus.push('_validation_');
            nameStatus.push(name);
            args.validation = get(filter, nameStatus.join('.'), undefined);
        }
        const value = get(filter, name, undefined);

        if (options && options.autoComplete) {
            args.autoComplete = autoComplete(typeof options.autoComplete === 'string' ? options.autoComplete : name);
        }

        return {
            ...args,
            value: value === undefined ? '' : value,
            onKeyUp: (e) => {
                if (e.key === "Enter") {
                    loadData(1);
                    loadDataDebounce(false);
                }
            },
            onChange: (e, targetname = '') => {
                console.log('onChange', e);
                const value = (typeof e === 'object' && 'target' in e) ? e.target.value : e;
                if (args.maxLength) {
                    set(filter, name, `${value}`.substr(0, args.maxLength));
                } else {
                    set(filter, name, value);
                }
                filter.set();
                if (((e && e.target && e.target.tagName) || targetname) === 'SELECT') {
                    loadData(1);
                } else {
                    loadDataDebounce(1);
                }
                if (options && options.autoComplete) {
                    setAutoCompleteDebounce(typeof options.autoComplete === 'string' ? options.autoComplete : name, value);
                }
            }
        };
    };

    const _initItem = (item) => {
        item.$saved = !!item.$saved;
        item.$loading = !!item.$loading;

        item.set = (obj = {}, accept = false) => {
            Object.assign(item, obj || {});
            if (accept) {
                item.$savedData = { ...item };
                delete item.$savedData.$savedData;
            }
            setList([...list]);
        };

        item.edit = () => {
            setActiveItem(item);
        };

        item.close = () => {
            setActiveItem(null);
        };

        item.bindSwitch = (name, trueValue, falseValue) => {
            return {
                checked: get(item, name, falseValue) === trueValue,
                onChange: (checked) => {
                    set(item, name, checked ? trueValue : falseValue);
                    item.set();
                    if (item.validate) item.validate();
                }
            };
        };

        item.bind = (name, options = {}) => {
            const args = {};
            if (item) {
                const nameStatus = name.split('.');
                nameStatus.pop();
                nameStatus.push('_validation_');
                nameStatus.push(name);
                args.validation = get(item, nameStatus.join('.'), undefined);
            }
            const value = get(item, name, undefined);
            if (options && options.autoComplete) {
                args.autoComplete = autoComplete(typeof options.autoComplete === 'string' ? options.autoComplete : name);
            }
            return {
                ...args,
                value: value === undefined ? '' : value,
                onChange: e => {
                    const value = (typeof e === 'object' && 'target' in e) ? e.target.value : e;
                    if (args.maxLength) {
                        set(item, name, `${value}`.substr(0, args.maxLength));
                    } else {
                        set(item, name, value);
                    }
                    item.set();
                    if (item.validate) item.validate();
                    if (options && options.autoComplete) {
                        setAutoCompleteDebounce(typeof options.autoComplete === 'string' ? options.autoComplete : name, value);
                    }
                }
            };
        };

        item.bindObj = (name, obj, options = {}) => {
            const args = {};
            if (item) {
                const nameStatus = name.split('.');
                nameStatus.pop();
                nameStatus.push('_validation_');
                nameStatus.push(name);
                args.validation = get(item, nameStatus.join('.'), undefined);
            }

            let isnull = true;
            const value = {}
            Object.keys(obj)
                .forEach(key => {
                    value[key] = get(item, obj[key], null);
                    if (![undefined, null].includes(value[key])) {
                        isnull = false;
                    }
                });

            if (options && options.autoComplete) {
                args.autoComplete = autoComplete(typeof options.autoComplete === 'string' ? options.autoComplete : name);
            }

            return {
                ...args,
                value: isnull ? null : value,
                onChange: (e, targetname = '') => {
                    const value = (typeof e === 'object' && 'target' in e) ? e.target.value : e;

                    if (value) {
                        Object.keys(obj)
                            .forEach(key => {
                                set(item, obj[key], value[key]);
                            });
                    }

                    item.set();
                    if (item.validate) item.validate();
                    if (options && options.autoComplete) {
                        setAutoCompleteDebounce(typeof options.autoComplete === 'string' ? options.autoComplete : name, value);
                    }
                }
            };
        };

        item.save = async () => {
            try {
                item.set({ $loading: true });
                const isNewItem = !item[primaryKey];
                const path = isNewItem ? apiEndPoint + '/add' : apiEndPoint + '/edit';
                const data = await api.post(path, { item });
                if (data && data.item) item.set({ ...data.item, $loading: false }, true);
                item.set({ $loading: false });
                if (isNewItem) {
                    loadData(1);
                }
                return data && data.saved;
            } catch (error) {
                item.set({ $loading: false });
                console.log(error);
            }
            return false;
        };

        item.delete = async (message = 'Êtes-vous sûr de vouloir supprimer?') => {
            if (message) {
                const s = window.confirm(message);
                if (!s) return;
            }
            try {
                item.set({ $loading: true });
                const path = apiEndPoint + '/delete';
                const data = await api.post(path, { item });
                if (data.deleted) {
                    item.close();
                    loadData(1);
                    return true;
                }
            } catch (error) {
                item.set({ $loading: false });
                console.log(error);
            }
            return false;
        };

        item.saveAndClose = async () => {
            const result = await item.save();
            if (result) {
                item.set({ $saved: true });
                setTimeout(() => {
                    item.set({ $saved: false });
                    item.close();
                    loadData(1);
                }, 1500);
            }
            return result;
        };

        item.reset = () => {
            item.set({ ...item.$savedData });
            return item;
        }

        if (initItem) initItem(item);
        return item;
    };

    const getDataState = (fn) => {
        return new Promise((resolve) => {
            fn(data => {
                resolve(data)
                return data;
            });
        });
    }

    const loadData = async (page) => {
        if (page === false) return;
        if (!page) page = 1;
        setPage(page);
        setLoading(true);
        try {
            const filter = await getDataState(setFilter);
            const result = await api.post(apiEndPoint + '/load', { page, length: extra.length, filter: { ...filter, ...(options && options.filter) } });
            if (result && Array.isArray(result.data)) {
                setList(result.data.map(item => ({ ...item, $savedData: { ...item } })).map(_initItem));
                setTotal(parseInt(result.total));
                setPage(parseInt(result.page));
            }
            if (result) {
                delete result.data;
                setInfos(result);
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    const loadDataDebounce = useCallback(debounce(loadData, 2000), []);
    filter.loadData = loadData;

    useEffect(() => {
        loadData(1);
    }, []);

    const createNewItem = (data = {}) => {
        const item = _initItem({ ...data });
        setList([...list, item]);
        item.edit();
    };

    const setAutoComplete = async (name, value) => {
        const result = await api.post(apiEndPoint + '/autocomplete', { name, value });
        setAutoCompleteObj((obj) => ({ ...obj, [name]: (result || []).map(item => item.value) }));
    }

    const setAutoCompleteDebounce = useCallback(debounce(setAutoComplete, 500), []);

    const autoComplete = (name) => {
        return autoCompleteObj[name] || [];
    };
    infos.goTo = loadData;
    return { list: list.map(_initItem).filter(item => item[primaryKey]), filter, infos, total, page, loadData, activeItem, loading, createNewItem };
};


function ModulesManager({ item }) {
    const { activeItem, loading, filter, loadData, list, infos, createNewItem } = useModulesManager({ apiEndPoint: 'modules' });
    return (
        <div>
            <Loading loading={loading}>
                <h1>Modules Manager</h1>
                <div className='d-flex gap-2 justify-content-between align-items-end mb-3'>
                    <Input.Text label="Filter text" {...filter.bind('titre', { autoComplete: true })} />
                    <Input.Select2 label="Filter text" {...filter.bind('type')} emptyOption="Tout" options={['CRUD']} />
                    <span className='flex-1' />
                    <button className='btn btn-secondary btn-icon' onClick={() => loadData()}><span className="material-symbols-outlined">search</span></button>
                    <button className='btn btn-primary btn-icon' onClick={() => createNewItem({})}><span className="material-symbols-outlined">add</span> Ajouter</button>
                </div>
                <div className="card">
                    <div className="card-body" style={{ minHeight: 350, padding: 0 }}>
                        <table className='table table-striped table-hover'>
                            <thead className="table-light">
                                <tr>
                                    <th>Title</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th className='td-actions'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map(item => (
                                    <tr key={item.id}>
                                        <td>{item.titre}</td>
                                        <td>{item.name}</td>
                                        <td>{item.type}</td>
                                        <td className='td-actions'>
                                            <div>
                                                <button className='btn btn-action' onClick={item.edit}><span className="material-symbols-outlined">edit</span></button>
                                                <button className='btn btn-action' onClick={() => item.delete()}><span className="material-symbols-outlined">delete</span></button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='my-3'>
                    <Pagination {...infos} />
                </div>
            </Loading>
            <Modal
                visible={activeItem}
                title="Configuration"
                loading={activeItem && activeItem.$loading}
                style={{ maxWidth: 800 }}
                onClose={() => activeItem.reset().close()}
                onOK={() => activeItem.save()}
                okText="Enregistrer"
            >
                {activeItem ? (
                    <>
                        <div className='row'>
                            <div className='col-4'>
                                <Input.Text label="Title" {...activeItem.bind('titre')} />
                            </div>
                            <div className='col-4'>
                                <Input.Text label="Name" {...activeItem.bind('name')} />
                            </div>
                            <div className='col-4'>
                                <Input.Select2 label="Type" {...activeItem.bind('type')} emptyOption="Type" options={['CRUD', 'AtomCom']} />
                            </div>
                        </div>
                        <hr />
                        {
                            {
                                'CRUD': <CRUDConfig item={activeItem} />,
                                'AtomCom': <AtomComConfig item={activeItem} />
                            }[activeItem.type]
                        }

                    </>
                ) : null}
            </Modal>
        </div>
    )
}

function ModulesManagerConfig({ item }) {
    return (
        <div className='row'>
            {/* <div className='col-6'>
                <Input.Text label="Titre" {...item.bind('titre')} placeholder="Titre" />
            </div>
            <div className='col-12'>
                <FormBuilder {...item.bind('list', { emptyValue: [] })} label="Liste des champs" />
            </div> */}
        </div>
    )
}


export function Pagination({ page = 1, total = 0, length = 10, goTo }) {
    page = Math.max(1, page || 1);
    total = Math.max(0, total || 0);
    length = Math.max(1, length || 10);
    const nbrPages = Math.ceil(total / length);
    const pages = [];
    for (let p = Math.max(1, page - 5); p < Math.min(nbrPages, page + 5); p++) {
        pages.push(p);
    }
    if (!pages.includes(1)) pages.push(1);
    if (nbrPages > 0 && !pages.includes(nbrPages)) pages.push(nbrPages);
    pages.sort();

    return (
        <nav>
            <ul className="pagination pagination-sm">
                {pages.map(p => (
                    <li className={"page-item " + (p === page ? 'active' : '')} onClick={() => (p !== page && goTo ? goTo(p) : null)}>
                        <span className="page-link">{p}</span>
                    </li>
                ))}
            </ul>
        </nav>
    )
}



defineAtomElement({
    name: 'ModulesManager',
    title: 'ModulesManager',
    default: {},
    Component: ModulesManager,
    Config: ModulesManagerConfig
});


export function ModulesManagerIntegration({ children }) {
    const [, setGeneratedModules] = useRecoilState(generatedModules);
    const [loading, setLoading] = useState(true);
    const { post } = useApi();

    useEffect(() => {
        // post('modules/initlist', {})
        //     .then(data => {
        //         if (Array.isArray(data)) {
        //             setGeneratedModules(data);
        //             data.map(item => {
        //                 const Comp = getComByName(item.type);
        //                 if (Comp) {
        //                     defineAtomElement({
        //                         autoGenereate: item.type,
        //                         data: item,
        //                         name: item.name,
        //                         title: item.titre,
        //                         default: {},
        //                         Component: (props) => {
        //                             return <Comp.Component {...props} item={item} config2={props.item} />;
        //                         },
        //                         Config: Comp.Config2 ? ((props) => <Comp.Config2 item={item} config2={props.item} />) : null
        //                     });
        //                 } else {
        //                     console.log('No Comp ', item);
        //                 }

        //             })

        //         }
        //     })
        //     .finally(() => setLoading(false));
        setLoading(false)
    }, []);

    if (loading) return <Loading fullScreen loading />

    return children;
}


export default ModulesManager;
