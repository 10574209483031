import { Button, Icon, IconButton, Paper, useTheme } from '@mui/material';
import useApi from 'com/API/api';
import Client from 'com/Atom/Com/commerce/client.atom';
import Input, { FormItem } from 'com/Imput/Input';
import Loading, { AlertBox } from 'com/Loading';
import Modal from 'com/Modal/Modal';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import useBindableState from '../utils/useBindableState';
import { TableCRUDDialog, TableCRUDTable } from '../utils/useCrud';
import './Ticket.css';

const Ticket = (props, _ref) => {
  const ref = {};
  const div = useRef();
  const paperref = useRef();
  const editModal = useRef();
  const [width, setWidth] = useState(null);
  const [positionFixed, setPositionFixed] = useState(false);
  const api = useApi();

  // useEffect(() => {
  //   const calc = () => {
  //     if (div.current) {
  //       setWidth(div.current.offsetWidth);
  //     }
  //   }
  //   const scroll = () => {
  //     if (div.current) {
  //       if (paperref.current && !positionFixed) {
  //         div.current.style.minHeight = paperref.current.offsetHeight + 'px';
  //       }
  //       setPositionFixed(div.current.getBoundingClientRect().top < 0);
  //     }
  //   }
  //   window.addEventListener('resize', calc);
  //   window.addEventListener('scroll', scroll);
  //   calc();
  //   scroll();
  //   return () => {
  //     window.removeEventListener('resize', calc);
  //     window.removeEventListener('scroll', scroll);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [div.current, paperref.current]);

  const initDoc = (doc) => {
    const { type } = props;
    doc = doc || {};
    doc.type = type.key;
    const initDocLine = (line) => {
      line.qte = parseFloat(line.qte).toFixed(3) * 1 || 0;
      line.tva = parseFloat(line.tva).toFixed(3) * 1 || 0;
      line.remise_ht = parseFloat(line.remise_ht || 0).toFixed(3) * 1 || 0;
      line.prix_u_ht = parseFloat(line.prix_u_ht).toFixed(3) * 1 || 0;
      line.prix_u_ttc = parseFloat(line.prix_u_ttc).toFixed(3) * 1 || 0;
      line.prix_t_ht = line.prix_u_ht.m(line.qte);
      line.prix_t_ttc = line.prix_t_ht.m(1 + line.tva.d(100)) || 0;
      return line;
    }

    const initDocPay = (pay) => {
      pay.valeur = parseFloat(pay.valeur).toFixed(3) * 1;
      return pay;
    }

    const initDocRemise = (remise) => {
      remise.valeur = parseFloat(remise.valeur).toFixed(3) * 1;
      return remise;
    }

    doc.list = (Array.isArray(doc.list) ? doc.list : []).map(initDocLine);
    doc.paiements = (Array.isArray(doc.paiements) ? doc.paiements : []).map(initDocPay);
    doc.remise = (Array.isArray(doc.remise) ? doc.remise : []).map(initDocRemise);
    doc.total_remise = doc.remise.total('valeur');
    doc.total_paye = doc.paiements.total('valeur');


    doc.tva = [];
    doc.list.forEach(line => {
      const tva = doc.tva.find(t => t.value === line.tva);
      if (tva) {
        tva.base = tva.base.a(line.prix_t_ht);
        tva.remise = tva.remise.a(parseFloat(line.remise_ht) || 0);
        tva.total = tva.base.s(tva.remise).m((tva.value || 0).d(100));

      } else {
        doc.tva.push({
          value: line.tva || 0,
          base: line.prix_t_ht,
          remise: parseFloat(line.remise_ht) || 0,
          total: line.prix_t_ht.m((line.tva || 0).d(100))
        });
      }
    });

    let totalPaye = doc.total_paye;
    doc.tva.sort((a, b) => a.value - b.value);
    doc.tva.forEach(tva => {
      tva.total_paye = Math.min(totalPaye, tva.base.m(1 + (tva.value || 0).d(100)));
      totalPaye = totalPaye.s(tva.total_paye);
      tva.max_remise = tva.base.s((tva.total_paye.d(1 + (tva.value || 0).d(100))));
    });


    let totalRemise = doc.total_remise;
    doc.tva.sort((a, b) => b.value - a.value);
    doc.tva.forEach(tva => {
      const valRemise = Math.min(tva.base.s(tva.remise), totalRemise);
      tva.remise = tva.remise.a(valRemise);
      tva.total = tva.base.s(tva.remise).m((tva.value || 0).d(100));
      tva.totalfinal = tva.base.s(tva.remise).a(tva.total);
      totalRemise = totalRemise.s(valRemise);
    });
    doc.max_remise = doc.tva.total('max_remise').s(doc.total_remise);

    doc.total_remise = doc.tva.total('remise');
    doc.client = doc.client ? doc.client : null;
    doc.total_ht = doc.tva.total('base');
    doc.total_tva = doc.tva.total('total');
    doc.total_ttc = doc.tva.total('totalfinal');
    doc.total_reste = Math.max(0, parseFloat(doc.total_ttc).s(parseFloat(doc.total_paye)));
    return doc;
  }

  function onUpdateDoc(doc) {
    doc = initDoc(doc);
    if (props.onChange) props.onChange(doc);
    return doc;
  }

  const state = useBindableState({ tab: 'list', loading: false });
  const ticket = useBindableState(initDoc(props.doc || {}), { onSet: onUpdateDoc, initState: initDoc });
  const theme = useTheme();

  const onLineClick = (row) => () => {
    editModal.current.show(row, { bindable: true });
  }

  const colmuns = [
    {
      key: 'ref',
      title: 'Article',
      render: (row, i) => (
        <div className="btn-info-line">
          <IconButton size="small" onClick={onLineClick(row)}><Icon>preview</Icon></IconButton>
          <div className='ticket-line-title'>
            {row.ref ? <small><span>#{i + 1}</span> | <span>Ref: {row.ref}</span></small> : null}
            <div>{row.titre}</div>
          </div>
        </div>
      )
    },
    {
      title: 'Prix U',
      titleArgs: { style: { width: 1, whiteSpace: 'nowrap', textAlign: 'right' } },
      args: { style: { background: '#b8ffa44d', padding: '0 2px', textAlign: 'right' } },
      render: (row) => <PrixInput {...ticket.bind('prix_u_ht', { data: row })} format />
    },
    {
      title: 'Qte',
      titleArgs: { style: { width: 1, whiteSpace: 'nowrap', textAlign: 'right' } },
      args: { style: { background: '#3abdff29', padding: '0 2px', textAlign: 'right' } },
      render: (row) => <PrixInput {...ticket.bind('qte', { data: row })} />
    },
    {
      title: 'Total',
      titleArgs: { style: { width: 1, whiteSpace: 'nowrap', textAlign: 'right' }, align: 'right' },
      args: { style: { textAlign: 'right' } },
      render: (row) => (
        <div>
          <Prix value={row.prix_t_ht} unite={false} />
          {row.remise_ht ? <small style={{ whiteSpace: 'nowrap' }}>- <Prix value={row.remise_ht} unite={false} /></small> : null}
        </div>
      )
    },
  ];

  ref.add = (item, itemType = 'article') => {
    const elm = ticket.list.find(elm => elm.id === item.id && elm.itemType === itemType);
    if (elm) {
      elm.qte = (elm.qte || 0) + 1;
      elm.anim = true;
      if (elm.timer) clearTimeout(elm.timer);
      elm.timer = setTimeout(() => {
        elm.anim = false;
        ticket.set();
      }, 1000);
      ticket.set();

    } else {
      const _item = JSON.parse(JSON.stringify(item));
      _item.prix_u_ht = parseFloat(item[props.type.col_prix_u_ht]) || 0;
      _item.tva = parseFloat(item[props.type.col_tva]) || 0;
      _item.prix_u_ttc = parseFloat(item[props.type.col_prix_u_ttc]) || 0;
      _item.unite = item.unite_label;
      const newElm = { ..._item, itemType, qte: 1, anim: true };
      newElm.timer = setTimeout(() => {
        newElm.anim = false;
        ticket.set();
      }, 1000);
      ticket.list.push(newElm);
      ticket.set();
    }
    setTimeout(() => {
      const a = document.querySelector('.tr-animation');
      if (a) {
        a.scrollIntoView();
      }
    }, 100);
  }

  ref.remove = (item) => {
    ticket.list = ticket.list.filter(elm => elm !== item);
    ticket.set();
  }

  ref.save = async () => {
    try {
      state.set({ loading: true, errorMessage: false });
      const { success, message, item } = await api.post('docs/save', { doc: await ticket.get() });
      if (success) {
        ticket.update(item);
        state.update({ loading: false, message: 'Enregistré avec succès', alertType: 'success' });
        setTimeout(() => state.update({ message: false }), 1000);
      } else {
        state.update({ loading: false, message, alertType: 'error' });
      }
      return true;
    } catch (error) {
      console.error(error);
      state.update({ loading: false, message: error.message, alertType: 'error' });
    }
    return false;
  }



  const actions = [{ icon: 'close', onClick: (item) => ref.remove(item) }];

  const styleColor = {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  };

  if (_ref) _ref.current = ref;
  return (
    <div ref={div} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <style>{`.tickettablelist::-webkit-scrollbar-thumb { background: ${theme.palette.primary.main}; }`}</style>
      <Paper ref={paperref} style={{ padding: 10, top: 0, maxHeight: '80vh', width, position: positionFixed ? 'fixed' : 'relative', display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Loading loading={state.loading} topRelative />
        <AlertBox content={state.message} type={state.alertType || 'error'} topRelative onClose={() => state.update({ message: false })} />
        <div style={{ display: 'flex', gap: 10, flexDirection: 'column', flex: 1, minHeight: 0 }}>
          <div className='ticketheader' style={styleColor}>
            <div className='ticketheader-doctype'><span style={styleColor}>{props.type ? props.type.label : ''}</span></div>
            <div className='ticketheader-doctotal'>
              <small>Total TTC</small>
              <Prix value={ticket.total_ttc} unite='' />
            </div>
          </div>
          <div className='tickettabs'>
            <div className={state.tab === 'list' ? 'active' : ''} onClick={() => state.set({ tab: 'list' })}><Icon>list</Icon> Liste</div>
            <div
              className={(state.tab === 'paiements' ? 'active' : '') + `
                ${(ticket.total_ttc * 1 || 0).s(ticket.total_paye * 1 || 0) > 0 ? 'tickettabs-nonpaye' : ''} 
                ${(ticket.total_ttc * 1 || 0).s(ticket.total_paye * 1 || 0) <= 0 && (ticket.total_ttc * 1) > 0 ? 'tickettabs-paye' : ''}
              `}
              onClick={() => state.set({ tab: 'paiements', addPayement: false })}><Icon>payments</Icon> Paiements</div>
            <div className={state.tab === 'config' ? 'active' : ''} onClick={() => state.set({ tab: 'config' })}><Icon>settings</Icon></div>
            <div className={state.tab === 'message' ? 'active' : ''} onClick={() => state.set({ tab: 'message' })}><Icon>chat</Icon></div>
          </div>
          {
            {
              list: (
                <div style={{ display: 'flex', gap: 10, flexDirection: 'column', flex: 1, minHeight: 0, overflowY: 'auto' }}>
                  <div style={{ display: 'flex', gap: 10, zoom: 0.8 }}>
                    <Client.Select {...ticket.bind('client')} label="Client" style={{ flex: 1 }} />
                    <Client.Select {...ticket.bind('client')} label="Client" style={{ flex: 1 }} />
                  </div>
                  <div className='tickettablelist' style={{ zoom: 0.85, flex: 1, overflowY: 'auto', minHeight: 300 }}>
                    <TableCRUDTable
                      rows={ticket.list}
                      colmuns={colmuns}
                      actions={actions}
                      getTrProps={row => ({ className: row.anim ? 'tr-animation' : '' })}
                      hideOptions
                    />
                  </div>
                  <RecapPrix item={ticket} type={props.type} />
                </div>
              ),
              paiements: (
                <div style={{ display: 'flex', gap: 10, flexDirection: 'column', flex: 1, overflowY: 'auto', margin: -10, padding: 10 }}>
                  <div>
                    <SectionTitleSep>Paiements</SectionTitleSep>
                    <PaiementsList key="PaiementsList" item={ticket} type={props.type} addPayement={state.addPayement} />
                    <SectionTitleSep>Remise</SectionTitleSep>
                    <RemiseList key="RemiseList" item={ticket} type={props.type} addRemise={state.addRemise} />
                    <SectionTitleSep>Total</SectionTitleSep>
                    <RecapPrix item={ticket} type={props.type} />
                  </div>
                </div>
              ),
              config: (
                <div style={{ display: 'flex', gap: 10, flexDirection: 'column', flex: 1, overflowY: 'auto', margin: -10, padding: 10 }}>
                  <div>
                    <SectionTitleSep>Facture</SectionTitleSep>
                    <SectionTitleSep>Les retours</SectionTitleSep>
                    <SectionTitleSep>Historique</SectionTitleSep>
                    <SectionTitleSep>Date de création</SectionTitleSep>
                  </div>
                </div>
              ),
              message: (
                <div style={{ display: 'flex', gap: 10, flexDirection: 'column', flex: 1, overflowY: 'auto', margin: -10, padding: 10 }}>
                  <div>
                    <SectionTitleSep>Messages</SectionTitleSep>
                    <pre>{JSON.stringify(state, null, 2)}</pre>
                  </div>
                </div>
              )
            }[state.tab]
          }
          {props.hideActions ? null : (
            <div className='ticket-actions'>
              <Button variant='outlined' color='primary'
                onClick={() => {
                  state.set({ tab: 'paiements', addPayement: true });
                  setTimeout(() => state.set({ tab: 'paiements', addPayement: false }), 300)
                }}
              >Paiement</Button>
              <Button variant='outlined'
                disabled={ticket.total_reste <= 0}
                onClick={() => {
                  state.set({ tab: 'paiements', addRemise: true });
                  setTimeout(() => state.set({ tab: 'paiements', addRemise: false }), 300)
                }}
              >Remise</Button>
              <span style={{ flex: 1 }} />
              <Button variant='contained'><Icon>print</Icon></Button>
              <Button variant='contained' onClick={ref?.save}>Save</Button>
            </div>
          )}
        </div>
        <Modal ref={editModal} style={{ maxWidth: 700 }} title="Modifier" cancelText="Annuler" okText="Valider" onOK={(elm) => {
          let error = false;
          editModal.current.hide();
          ticket.set();
        }}>
          {(elm) => (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <div className='row'>
                <div className='col-6'><Input.Text readOnly label="Ref" {...elm.bind('ref')} /></div>
                <div className='col-6'><Input.Text label="Titre" {...elm.bind('titre')} /></div>
                <div className='col-3'><Input.Text type="number" label="Qte" {...elm.bind('qte')} suffix={elm.unite} /></div>
                <div className='col-3'><Input.Prix label="Prix U HT" {...elm.bind('prix_u_ht')} /></div>
                <div className='col-3'><Input.Prix label="Remise" {...elm.bind('remise_ht')} /></div>
                <div className='col-3'><Input.Prix readOnly label="Total HT" value={(elm.prix_u_ht || 0).m(elm.qte || 0).s(elm.remise_ht)} /></div>
                <div className='col-12'><Input.TextEditor label="Texte d'impression" {...elm.bind('print_text', { emptyValue: elm.titre })} /></div>
              </div>
            </div>
          )}
        </Modal>
      </Paper>

    </div>
  );
}


function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export const SectionTitleSep = React.memo(({ children }) => {
  return <div className='SectionTitleSep'><h4>{children}</h4> <span className='SectionTitleSep-line' /> </div>;
})


const RecapPrix = React.memo(({ item, type }) => {
  const style = {
    textAlign: 'center'
  };
  const payPlus = item.total_paye.s(item.total_ttc);
  return (
    <table className='table table-bordered' style={{ borderRadius: 5, zoom: 0.8 }}>
      <thead>
        <tr>
          <th style={style}>HT</th>
          <th style={style}>Remise</th>
          <th style={style}><EyeModal title="TVA" content={<RecapTVAPrix item={item} type={type} />}>TVA</EyeModal></th>
          <th style={style}>TTC</th>
          <th style={style}>Payé</th>
          <th style={style}>Reste</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th style={style}><Prix unite='' value={item.total_ht} /></th>
          <th style={style}><Prix unite='' value={item.total_remise} /></th>
          <th style={style}><Prix unite='' value={item.total_tva} /></th>
          <th style={style}><Prix unite='' value={item.total_ttc} /></th>
          <th style={style}>
            <div className='ticket-total-pay'>
              <Prix unite='' value={Math.min(item.total_paye, item.total_ttc)} />
              {payPlus > 0 ? (<small style={{ whiteSpace: 'nowrap' }}>+ <Prix unite='' value={payPlus} /></small>) : null}
            </div>
          </th>
          <th style={style}><Prix unite='' value={item.total_reste} /></th>
        </tr>
      </tbody>
    </table>
  )

})

const RecapTVAPrix = React.memo(({ item, type }) => {
  const style = {
    textAlign: 'center'
  };

  return (
    <table className='table table-bordered' style={{ borderRadius: 5 }}>
      <thead>
        <tr className='bg-input'>
          <th style={style}>TVA</th>
          <th style={style}>Base</th>
          <th style={style}>Remise</th>
          <th style={style}>Total TVA</th>
        </tr>
      </thead>
      <tbody>
        {
          item.tva.map(tva => (
            <tr key={tva.value}>
              <th style={style}>{tva.value} %</th>
              <th style={style}><Prix unite='' value={tva.base} /></th>
              <th style={style}><Prix unite='' value={tva.remise} /></th>
              <th style={style}><Prix unite='' value={tva.total} /></th>
            </tr>
          ))
        }
        {
          item.tva.length ? null : (
            <tr>
              <td colSpan={4} style={{ padding: '30px 0', textAlign: 'center' }}>Aucun TVA</td>
            </tr>
          )
        }
      </tbody>
    </table>
  )

});

const PaiementsList = React.memo(({ item, addPayement, type }) => {
  const modal = useRef();
  const initPayItem = (elm) => {
    elm.uid = elm.uid || uuidv4();
    elm.edit = () => {
      modal.current.show(JSON.parse(JSON.stringify(elm)), { bindable: true });
    }
  }

  const addNewPayement = () => {
    if (modal) {
      modal.current.show({
        date: (new Date()).toJSON().split('T')[0],
        type: 'Espèce',
        valeur: item.total_reste || 0
      }, { bindable: true });
    }
  }


  const list = item.getList('paiements', initPayItem);
  const totalPaye = list.total('valeur');
  const totalReste = Math.max(0, (item.total_ttc * 1 || 0).s(totalPaye));

  useEffect(() => {
    item.set({ total_paye: totalPaye, total_reste: totalReste });
  }, [totalPaye, totalReste]);

  useLayoutEffect(() => {
    if (addPayement) {
      setTimeout(addNewPayement, 100);
    }
  }, [addPayement]);

  return (
    <div style={{ flex: 1 }}>
      <table className='table table-bordered' style={{ borderRadius: 5, zoom: 0.8 }}>
        <thead>
          <tr className='bg-input'>
            <th style={{ width: 1 }}></th>
            <th>Date</th>
            <th>Type</th>
            <th style={{ textAlign: 'right' }}>Valeur</th>
            <th style={{ width: 1 }}></th>
          </tr>
        </thead>
        <tbody>
          {list.map((elm, i) => (
            <tr key={i}>
              <td style={{ padding: '3px 0' }}><IconButton size="small" onClick={elm.edit}><Icon>edit</Icon></IconButton></td>
              <td>{(new Date(elm.date)).toLocaleDateString()}</td>
              <td>{elm.type}</td>
              <td style={{ textAlign: 'right', fontWeight: 'bolder' }}><Prix value={elm.valeur} /></td>
              <td style={{ padding: '3px 0' }}><IconButton size="small" onClick={elm.delete}><Icon>close</Icon></IconButton></td>
            </tr>
          ))}
          {
            list.length ? null : (
              <tr key={0}>
                <td colSpan={5} style={{ padding: '30px 0', textAlign: 'center' }} >Aucun paiement n'a été effectué</td>
              </tr>
            )
          }
        </tbody>
      </table>
      <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
        <button
          className='btn btn-primary btn-sm'
          onClick={addNewPayement}
        > <Icon>add</Icon> Ajouter un paiement</button>
        <table className='table table-bordered' style={{ zoom: 0.8, display: 'contents' }}>
          <tbody>
            <tr className='bg-input'>
              <th>Total payé</th>
              <th style={{ textAlign: 'right' }}><Prix value={totalPaye} /></th>
            </tr>
          </tbody>
        </table>
      </div>
      <Modal ref={modal} style={{ maxWidth: 700 }} title="Paiement" cancelText="Annuler" okText="Valider" onOK={(elm) => {
        let error = false;
        if (!elm.date) {
          elm.setError('date');
          error = true;
        }
        if (!elm.type) {
          elm.setError('type');
          error = true;
        }
        if (!elm.valeur || elm.valeur < 0) {
          elm.setError('valeur');
          error = true;
        }
        if (error) return;
        if (elm.uid) {
          const elm1 = (item.paiements || []).find(e => e.uid === elm.uid);
          if (elm1) {
            item.paiements = (item.paiements || []).map(e => {
              if (e.uid === elm.uid) {
                return elm;
              }
              return e;
            });
            item.set();
            modal.current.hide();
            return;
          }
        }
        item.pushTo('paiements', elm);
        modal.current.hide();
      }}>
        {(elm) => (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div className='row'>
              <div className='col-4'><Input.Date label="Date" {...elm.bind('date')} /></div>
              <div className='col-4'><Input.Select2 label="Type" {...elm.bind('type')} options={['Espèce', 'Chèque', 'Carte credit']} /></div>
              <div className='col-4'><Input.Prix label="Valeur" {...elm.bind('valeur')} /></div>
              {
                {
                  'Chèque': (
                    <>
                      <div className='col-4'><Input.Text label="Num de chèque" {...elm.bind('num')} /></div>
                      <div className='col-4'><Input.Date label="Date d'échéance" {...elm.bind('date_ech')} /></div>
                      <div className='col-4'><Input.Text label="Titulaire du compte" {...elm.bind('titulaire')} /></div>
                    </>
                  ),
                  'Carte credit': (
                    <>
                      <div className='col-4'><Input.Text label="Num du transaction" {...elm.bind('num')} /></div>
                    </>
                  )
                }[elm.type]
              }
            </div>
            <div className='row'>
              <div className='col-4'>
                <Input.Image label="Image" {...elm.bind('image')} style={{ minWidth: '100%' }} height={110} />
              </div>
              <div className='col-8'><Input.Textarea rows={4} label="Note" {...elm.bind('note')} /></div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
});


const RemiseList = React.memo(({ item, addRemise, type }) => {
  const modal = useRef();
  const initRemiseItem = (elm) => {
    elm.uid = elm.uid || uuidv4();
    elm.edit = () => {
      elm._valeur = parseFloat(elm.valeur) || 0;
      modal.current.show(JSON.parse(JSON.stringify(elm)), { bindable: true });
    }
  }

  const addNewRemise = () => {
    if (modal && modal.current) {
      modal.current.show({
        date: (new Date()).toJSON().split('T')[0],
        valeur: 0
      }, { bindable: true });
    }
  }


  const list = item.getList('remise', initRemiseItem);
  const totalRemise = list.total('valeur');
  const maxRemise = item.max_remise;

  useLayoutEffect(() => {
    if (addRemise) {
      setTimeout(addNewRemise, 100);
    }
  }, [addRemise]);
  return (
    <div style={{ flex: 1 }}>
      <table className='table table-bordered' style={{ borderRadius: 5, zoom: 0.8 }}>
        <thead>
          <tr className='bg-input'>
            <th style={{ width: 1 }}></th>
            <th>Date</th>
            <th style={{ textAlign: 'right' }}>Valeur</th>
            <th style={{ width: 1 }}></th>
          </tr>
        </thead>
        <tbody>
          {list.map((elm, i) => (
            <tr key={i}>
              <td style={{ padding: '3px 0' }}><IconButton size="small" onClick={elm.edit}><Icon>edit</Icon></IconButton></td>
              <td>{(new Date(elm.date)).toLocaleDateString()}</td>
              <td style={{ textAlign: 'right', fontWeight: 'bolder' }}><Prix value={elm.valeur} /></td>
              <td style={{ padding: '3px 0' }}><IconButton size="small" onClick={elm.delete}><Icon>close</Icon></IconButton></td>
            </tr>
          ))}
          {
            list.length ? null : (
              <tr key={0}>
                <td colSpan={4} style={{ padding: '30px 0', textAlign: 'center' }} >Aucun remise n'a été effectué</td>
              </tr>
            )
          }
        </tbody>
      </table>
      <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
        {maxRemise > 0 ? (
          <button
            className='btn btn-default btn-sm'
            onClick={addNewRemise}
          > <Icon>add</Icon> Ajouter un remise</button>
        ) : <span />}

        <table className='table table-bordered' style={{ zoom: 0.8, display: 'contents' }}>
          <tbody>
            <tr className='bg-input'>
              <th>Total remise</th>
              <th style={{ textAlign: 'right' }}><Prix value={totalRemise} /></th>
            </tr>
          </tbody>
        </table>
      </div>
      <Modal ref={modal} style={{ maxWidth: 700 }} title="Remise" cancelText="Annuler" okText="Valider" onOK={(elm) => {
        let error = false;
        if (!elm.date) {
          elm.setError('date');
          error = true;
        }
        if (!elm.valeur || elm.valeur < 0) {
          elm.setError('valeur');
          error = true;
        } else if (elm.valeur > maxRemise.a(elm._valeur || 0)) {
          elm.valeur = maxRemise.a(elm._valeur || 0);
        }
        if (error) return;
        if (elm.uid) {
          const elm1 = (item.remise || []).find(e => e.uid === elm.uid);
          if (elm1) {
            item.remise = (item.remise || []).map(e => {
              if (e.uid === elm.uid) {
                return elm;
              }
              return e;
            });
            item.set();
            modal.current.hide();
            return;
          }
        }
        item.pushTo('remise', elm);
        modal.current.hide();
      }}>
        {(elm) => (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div className='row'>
              <div className='col-4'><Input.Date label="Date" {...elm.bind('date')} /></div>
              <div className='col-4'><Input.Prix label="Remise" {...elm.bind('valeur')} /></div>
              <div className='col-12'><Input.Textarea rows={4} label="Note" {...elm.bind('note')} /></div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
});
function Prix({ value, n = 3, unite = 'DT' }) {
  if (!value) value = 0;
  value = parseFloat(`${value || 0}`).toFixed(n || 3);
  return <span className='ticket-prix'>{value} {unite ? <small>{unite}</small> : null}</span>;
}


function PrixInput({ style, value, onChange, format }) {
  const [isActive, setIsActive] = useState(false);

  return <div style={{ ...style }}><input className='PrixInput' onBlur={() => setIsActive(false)} onFocusCapture={e => {
    setIsActive(true);
    setTimeout(() => {
      e.target.select();
    }, 100);
  }} value={isActive || !format ? value : parseFloat(value).toFixed(2) + '0'} onChange={onChange} /></div>
}

export const EyeModal = React.memo(({ children, content, ...props }) => {
  const modal = useRef();
  return (
    <div className='eye-modal'>
      <IconButton size="small" onClick={() => modal.current.show()}><Icon>preview</Icon></IconButton>
      <span>{children}</span>
      <Modal ref={modal} {...props} onOK={(elm) => modal.current.hide()}>
        {content}
      </Modal>
    </div>
  )
});

export const DOC_TYPES = {
  BL: {
    key: 'BL',
    label: 'Bon de livraison',
    col_prix_u_ht: 'prix_vente_ht',
    col_tva: 'tva',
    col_prix_u_ttc: 'prix_vente_ttc'
  }
};

export default React.forwardRef(Ticket);
