import React from 'react';
import { defineAtomElement } from 'com/Atom/Atom';
import Input from 'com/Imput/Input';
import { DOC_TYPES, EyeModal } from 'com/Ticket/Ticket';
import { Debounce } from 'com/utils/createBindFunction';
import useCrud, { TableCRUDDialog, TableCRUDeleteDialog, TableCRUDFilter, TableCRUDLoading, TableCRUDTable } from 'com/utils/useCrud';
import Client from './client.atom';
import { EditDoc } from './Ticket.atom';
import { useMemo } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { useRefManager } from 'com/utils/useBindableState';
import Modal from 'com/Modal/Modal';


const EyeClient = React.memo(({ row }) => {
  const filter = useMemo(() => ({ client: row.client }), [row.client]);
  return <EyeModal fullWidth maxWidth='xl' title={row.client?.label} content={<Docs filter={filter} />}>{row.client?.label}</EyeModal>
})

const colmuns = [
  { key: 'id', title: 'ID', minsize: true },
  { key: 'ref', title: 'Ref', minsize: true },
  { key: 'date_creation_format', title: 'Date', minsize: true },
  { key: 'type', title: 'Type', render: (row) => DOC_TYPES[row.type]?.label },
  { key: 'client', title: 'Client', render: (row) => <EyeClient row={row} /> },
  { key: 'total_ttc', title: 'Total TTC', type: "prix" },
  { key: 'total_paye', title: 'Total Paye', type: "prix", color: (row) => row.total_paye > 0 ? 'green' : 'red' },
  { key: 'total_reste', title: 'Total Reste', type: "prix", color: (row) => row.total_reste > 0 ? 'red' : 'green' },
];

const actions = [
  { icon: 'edit', label: 'Edit' },
  { icon: 'delete', label: 'Delete' },
];


const StyledH2 = styled.h2`
  margin: 0;
  border-right: 1px dashed #ccc;
  padding: 0 15px;
  height: 66px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  border-left: 5px solid ${props => props.theme?.palette?.primary?.main};
  border-radius: 5px;
  small {
    font-size: 14px;
    padding-left: 5px;
    padding-bottom: 5px;
  }
`;

export function Docs({ filter, item }) {
  if (!filter) filter = {};
  filter.type = filter.type || item?.type;
  const { list, selected, loading, loadData, nbrPages, createNewItem, total, page, checked, ...args } = useCrud('docs', { length: 10, id: 'id', filter });
  const refs = useRefManager();

  return (
    <TableCRUDLoading loading={loading}>
      <TableCRUDFilter onSearch={() => loadData()} onAdd={filter?.type ? () => createNewItem() : null}>
        <Debounce {...args.filter.bind('', { emptyValue: {} })} wait={500} onUpdate={() => loadData()} >
          {(filterDraft) => (
            <>
              {filter?.type ? <StyledH2>
                <span>{DOC_TYPES[filter.type].label}</span>
                <small>Liste de tout les {DOC_TYPES[filter.type].label}</small>
              </StyledH2> : null}
              <Input.Text label="Ref" {...filterDraft.bind('ref')} />
              {filter?.client ? null : <Client.Select label="Client" {...filterDraft.bind('client')} emptyOption="Tout" />}
            </>
          )}
        </Debounce>
      </TableCRUDFilter>
      <TableCRUDTable
        rows={list}
        checked={checked}
        colmuns={colmuns}
        actions={actions}
        loadData={loadData}
        page={page}
        total={total}
        nbrPages={nbrPages}
        // hideOptions
        checkedActions={
          <Button variant="contained" onClick={() => refs.ref('invoiceBuilder').current.show()}>Créer facture</Button>
        }
      />
      <Modal ref={refs.ref('invoiceBuilder')}>
        <pre>{JSON.stringify(checked, null, 2)}</pre>
      </Modal>
      <TableCRUDDialog item={selected.edit} header="Modification" actions={(item) => [
        { label: 'Annuler', onClick: () => { item.reset(); item.close('edit'); } },
        {
          label: 'Enregistrer', variant: 'contained', onClick: () => {
            item.$.ticket.save();
          }
        }
      ]}
        maxWidth="xl"
        fullWidth
      >
        {item => (
          <div className='row'>
            <div className='col-12'>
              <EditDoc ref={item.$.ref('ticket')} type={DOC_TYPES[item.type || filter?.type]} doc={item} onChange={item.set} hideActions />
            </div>
          </div>
        )}
      </TableCRUDDialog>
      <TableCRUDeleteDialog item={selected.delete} />
    </TableCRUDLoading >
  );
}

Docs.BL = (props) => <Docs type={DOC_TYPES.BL.key} {...props} />;

function DocsConfig({ item }) {
  return (
    <div className='row'>
      <div className='col-12'>
        <Input.Select lable="Type" {...item.bind('type', { emptyValue: '' })} options={['', ...Object.values(DOC_TYPES).map(type => ({ value: type.key, label: type.label }))]} />
      </div>
    </div>
  )
}

defineAtomElement({
  name: 'Docs',
  title: 'Docs',
  default: {},
  Component: Docs,
  Config: DocsConfig
});

export default Docs;
