import { defineAtomLayout } from '../Atom';
import { Link } from 'react-router-dom';
import './LayoutStyle.css';
import { useRecoilState } from 'recoil';
import { userState } from './../../../states';
import { logout } from '../../Firebase/Firebase';
import Input from '../../Imput/Input';
import MenuConfig from '../../Imput/MenuConfig';
import { get } from 'lodash';
import BazJobLayout from './bazjobLayout';
import { createTheme, Divider, ListItemIcon, Menu, MenuItem, Popover, ThemeProvider } from '@mui/material';
import { useMemo } from 'react';
import { useState } from 'react';
import { useRefManager } from 'com/utils/useBindableState';
import React from 'react';


function DefaultLayout({ type, ...props }) {
  const activeConfigSpace = get(props.config, 'space.activeconfig', '') || '';
  switch (`${activeConfigSpace}`.trim().toLowerCase()) {
    case 'bazjob':
      return <BazJobLayout {...props} />
    default:
      return <Layout1 {...props} />
  }
}



function Layout1({ config, children }) {
  const refs = useRefManager();
  const [user] = useRecoilState(userState);
  const menuList = get(config, 'menuList', []) || [];
  const space = get(config, 'space', {}) || {};
  function shadeColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);
    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);
    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;
    var RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));
    return "#" + RR + GG + BB;
  }

  const theme = useMemo(() => createTheme({
    palette: {
      primary: {
        main: space.color || '#476396',
      },
    },
  }), [space.color]);

  return (
    <ThemeProvider theme={theme}>
      <div className="layout1">
        <style>{`
        body {
          --primary-color-hover: ${shadeColor(space.color || '#476396', 20)};
          --primary-color-outline: ${shadeColor(space.color || '#476396', 20)}30;
          --bs-primary: ${space.color || '#476396'};
        }
        .btn-primary { color: #fff; background-color: ${space.color || '#476396'}; border-color: ${space.color || '#476396'};}
      `}</style>
        <header>
          <div className="layout1-top-nav">
            <div className="container">
              <Link to="/" className="logo">
                <img src="/images/logo.png" alt="Sim-Software" style={{ height: 60, verticalAlign: 'middle' }} />
              </Link>
              <span style={{ flex: 1 }} />
              <div className="layout1-avatar">
                {
                  user ? (
                    <div>
                      <span>{user.name}</span>
                      <div><small>{user.login}</small> -
                        <PopoverMenu ref={refs.ref('menu1')} button={<small style={{ cursor: 'pointer' }}> {user.role?.label}</small>}>
                          {user.roles.map(role => <MenuItem key={role.id} onClick={() => { user.switch(role); refs.menu1.close(); }}>{role.label}</MenuItem>)}
                        </PopoverMenu>
                      </div>
                    </div>
                  ) : null
                }
                {user && user.photo ? (
                  <img src={user.photo} key={user.photo} referrerPolicy="no-referrer" alt="user" />
                ) : (
                  user ? (
                    <svg style={{ width: 40 }} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 53 53" ><path style={{ fill: '#E7ECED' }} d="M18.613,41.552l-7.907,4.313c-0.464,0.253-0.881,0.564-1.269,0.903C14.047,50.655,19.998,53,26.5,53c6.454,0,12.367-2.31,16.964-6.144c-0.424-0.358-0.884-0.68-1.394-0.934l-8.467-4.233c-1.094-0.547-1.785-1.665-1.785-2.888v-3.322c0.238-0.271,0.51-0.619,0.801-1.03c1.154-1.63,2.027-3.423,2.632-5.304c1.086-0.335,1.886-1.338,1.886-2.53v-3.546c0-0.78-0.347-1.477-0.886-1.965v-5.126c0,0,1.053-7.977-9.75-7.977s-9.75,7.977-9.75,7.977v5.126c-0.54,0.488-0.886,1.185-0.886,1.965v3.546c0,0.934,0.491,1.756,1.226,2.231c0.886,3.857,3.206,6.633,3.206,6.633v3.24C20.296,39.899,19.65,40.986,18.613,41.552z"></path><g><path style={{ fill: space.color || '#476396' }} d="M26.953,0.004C12.32-0.246,0.254,11.414,0.004,26.047C-0.138,34.344,3.56,41.801,9.448,46.76c0.385-0.336,0.798-0.644,1.257-0.894l7.907-4.313c1.037-0.566,1.683-1.653,1.683-2.835v-3.24c0,0-2.321-2.776-3.206-6.633c-0.734-0.475-1.226-1.296-1.226-2.231v-3.546c0-0.78,0.347-1.477,0.886-1.965v-5.126c0,0-1.053-7.977,9.75-7.977s9.75,7.977,9.75,7.977v5.126c0.54,0.488,0.886,1.185,0.886,1.965v3.546c0,1.192-0.8,2.195-1.886,2.53c-0.605,1.881-1.478,3.674-2.632,5.304c-0.291,0.411-0.563,0.759-0.801,1.03V38.8c0,1.223,0.691,2.342,1.785,2.888l8.467,4.233c0.508,0.254,0.967,0.575,1.39,0.932c5.71-4.762,9.399-11.882,9.536-19.9C53.246,12.32,41.587,0.254,26.953,0.004z"></path></g></svg>
                  ) : (
                    <Link to="/auth"><svg style={{ width: 40 }} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 53 53" ><path style={{ fill: '#E7ECED' }} d="M18.613,41.552l-7.907,4.313c-0.464,0.253-0.881,0.564-1.269,0.903C14.047,50.655,19.998,53,26.5,53c6.454,0,12.367-2.31,16.964-6.144c-0.424-0.358-0.884-0.68-1.394-0.934l-8.467-4.233c-1.094-0.547-1.785-1.665-1.785-2.888v-3.322c0.238-0.271,0.51-0.619,0.801-1.03c1.154-1.63,2.027-3.423,2.632-5.304c1.086-0.335,1.886-1.338,1.886-2.53v-3.546c0-0.78-0.347-1.477-0.886-1.965v-5.126c0,0,1.053-7.977-9.75-7.977s-9.75,7.977-9.75,7.977v5.126c-0.54,0.488-0.886,1.185-0.886,1.965v3.546c0,0.934,0.491,1.756,1.226,2.231c0.886,3.857,3.206,6.633,3.206,6.633v3.24C20.296,39.899,19.65,40.986,18.613,41.552z"></path><g><path style={{ fill: space.color || '#476396' }} d="M26.953,0.004C12.32-0.246,0.254,11.414,0.004,26.047C-0.138,34.344,3.56,41.801,9.448,46.76c0.385-0.336,0.798-0.644,1.257-0.894l7.907-4.313c1.037-0.566,1.683-1.653,1.683-2.835v-3.24c0,0-2.321-2.776-3.206-6.633c-0.734-0.475-1.226-1.296-1.226-2.231v-3.546c0-0.78,0.347-1.477,0.886-1.965v-5.126c0,0-1.053-7.977,9.75-7.977s9.75,7.977,9.75,7.977v5.126c0.54,0.488,0.886,1.185,0.886,1.965v3.546c0,1.192-0.8,2.195-1.886,2.53c-0.605,1.881-1.478,3.674-2.632,5.304c-0.291,0.411-0.563,0.759-0.801,1.03V38.8c0,1.223,0.691,2.342,1.785,2.888l8.467,4.233c0.508,0.254,0.967,0.575,1.39,0.932c5.71-4.762,9.399-11.882,9.536-19.9C53.246,12.32,41.587,0.254,26.953,0.004z"></path></g></svg></Link>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="layout1-main-nav-container" style={{ background: space.color }}>
            <nav className="layout1-main-nav container">
              <ul className="navlinks navlinks-left">
                {menuList ? menuList.filter(menuItem => menuItem.data?.align !== 'right').map(menuItem => <WMenuItem item={menuItem} user={user} key={menuItem.id} />) : null}
              </ul>
              {user ? (
                <ul className="navlinks navlinks-right">
                  {menuList ? menuList.filter(menuItem => menuItem.data?.align === 'right').map(menuItem => <WMenuItem item={menuItem} user={user} key={menuItem.id} />) : null}
                  <li>
                    <PopoverMenu ref={refs.ref('menu')} button={<span className="material-icons">settings</span>}>
                      {user.role?.name === 'admin' ? (
                        <Link className='menulink' to="/config" onClick={() => refs.menu.close()}>
                          <MenuItem>
                            <ListItemIcon>
                              <span className="material-icons">settings</span>
                            </ListItemIcon>
                            Configuration
                          </MenuItem>
                        </Link>
                      ) : null}
                      <Divider />
                      <MenuItem onClick={logout}>
                        <ListItemIcon>
                          <span className="material-icons">logout</span>
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                    </PopoverMenu>
                  </li>
                </ul>
              ) : null}
            </nav>
          </div>
        </header>
        <div className="layout1-main">
          <div className="container">
            {children}
          </div>
        </div>
        <div className="layout1-footer">
          © 2022 All rights reserved by sim-software.tn
        </div>
      </div>
    </ThemeProvider>
  )
}


const MenuRoute = (props) => {
  return <li className={window.location.pathname === props.to ? 'active' : ''}><Link to={props.to}>{props.children}</Link></li>
}


function WMenuItem({ item, user }) {
  return <MenuRoute to={item?.data?.path.replace(/\/@me(\b|$)/ig, '/@' + ((user && user.username) || 'me')) || '/' + item.id}>{item?.data?.titre || item.text}</MenuRoute>
}

function ConfigLayout({ item }) {
  return (
    <div className='row'>
      <div className='col-6'>
        <Input.Select label="Configuration Active" emptyOption="" {...item.bind('activeconfig', { emptyValue: '' })} options={(item.menu || []).map(elm => elm.text)} />
      </div>
      <div className='col-6'>
        <Input.Color label="Couleur menu" {...item.bind('color', { emptyValue: '' })} />
      </div>
      <div className='col-12'>
        <MenuConfig {...item.bind('menu', { emptyValue: [] })} />
      </div>
    </div>
  )
}

function getUniqId() {
  return ("K" + Math.random().toString(16).slice(2)).toUpperCase();
}

const PopoverMenu = React.forwardRef(({ children, button }, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [id] = useState(getUniqId());
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  ref = ref || {};
  ref.close = handleClose;

  return (
    <span>
      <span
        id={id}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>{button}</span>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': id }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {children}
      </Menu>
    </span>
  )
});

defineAtomLayout({
  name: 'DefaultLayout',
  title: 'DefaultLayout',
  Component: DefaultLayout,
  Config: ConfigLayout
});

export default DefaultLayout;

